import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import {
  getPurchaseOrderByID,
  getPurchaseOrders,
} from "../../../../../redux/actions/purchaseOrderAction";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Column } = Table;

const PurchaseOrderGenerateBill = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [current] = useState(1);
  const progressBarStatus = useSelector(
    (state) => state.progressBarReducer.showProgressBar
  );
  const purchaseOrderBills = useSelector(
    (state) => state.purchaseOrderData.purchaseOrders
  );

  useEffect(() => {
    dispatch(getPurchaseOrders());
  }, []);

  const updatePurchaseBill = (id) => {
    const dataToSend = {
      id: id,
    };

    history.push({
      pathname: "/dagaImpex/purchaseOrderGenerateBillUpdate",
      state: dataToSend,
    });
  };

  return (
    <div className="card final-purchases-list">
      {progressBarStatus && <TopBarProgress />}
      <Row>
        <Col sm={24} md={16} lg={18}>
          <h3 className="m-4">Purchase Order Generate Bill</h3>
        </Col>
      </Row>
      <div className="card-body">
        <Table
          dataSource={purchaseOrderBills}
          bordered
          size="middle"
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Date"
            dataIndex="billDate"
            key="billDate"
            width={120}
            render={(date) => moment(date).format("YYYY-MM-DD")}
          />
          <Column title="PO ID" dataIndex="poId" key="poId" width={100} />
          <Column
            title="Net Amount "
            dataIndex="netAmount"
            key="netAmount"
            width={150}
          />
          <Column
            title="Total Amount"
            dataIndex="totalAmount"
            key="totalAmount"
            width={120}
          />
          <Column
            title="Quantity"
            dataIndex="quantity"
            key="quantity"
            width={120}
          />
          <Column title="Tax" dataIndex="tax" key="tax" width={140} />
          <Column
            title="Discount"
            dataIndex="discount"
            key="discount"
            width={80}
          />
          <Column
            title="Action"
            key="action"
            width={150}
            render={(text, record) => {
              const isExpired = moment(record.expiryDate).isBefore(
                moment().startOf("day")
              );

              return (
                <Row gutter={[8, 0]}>
                  <Col sm={24} md={24} lg={24}>
                    {isExpired ? (
                      <Button
                        // type="primary"
                        disabled={true}
                        // onClick={() => updatePurchaseBill(record.poId)}
                      >
                        PO Expired
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        disabled={record?.isPurchaseCompleted}
                        onClick={() => updatePurchaseBill(record.poId)}
                      >
                        Generate Bill
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            }}
          />
        </Table>
      </div>
    </div>
  );
};

export default PurchaseOrderGenerateBill;
