import React, { useState, useEffect } from "react";
import { Drawer, Input, List, Checkbox, Button } from "antd";
import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchProductsByFiltereing } from "../../redux/actions/productActions";
import filter from "../../assets/images/filter.svg";
import ShopSales from "./ShopSales";

const Filters = ({ selectData }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Categories");
    const [checkedFilters, setCheckedFilters] = useState({
        Categories: [],
        Styles: [],
        Brands: [],
        Colors: [],
        Sizes: [],
    });
    const [searchText, setSearchText] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const [styleData, setStyleData] = useState([]);
    const [brandData, setBrandData] = useState([]);
    const [colorData, setColorData] = useState([]);
    const [sizeData, setSizeData] = useState([]);

    useEffect(() => {
        if (selectData?.data?.categoryDetails) {
            setCategoryData(selectData?.data?.categoryDetails);
        }
        if (selectData?.data?.styleDetails) {
            setStyleData(selectData?.data?.styleDetails);
        }
        if (selectData?.data?.brandDetails) {
            setBrandData(selectData?.data?.brandDetails);
        }
        if (selectData?.data?.colorDetails) {
            setColorData(selectData?.data?.colorDetails);
        }
        if (selectData?.data?.sizeDetails) {
            setSizeData(selectData?.data?.sizeDetails);
        }
    }, [selectData]);

    const showDrawer = () => setDrawerOpen(true);
    const closeDrawer = () => setDrawerOpen(false);

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
        setSearchText("");
    };

    const handleCheckboxChange = (value, type) => {
        const updatedCheckedFilters = { ...checkedFilters };

        if (!updatedCheckedFilters[type]) updatedCheckedFilters[type] = [];

        if (updatedCheckedFilters[type].includes(value)) {
            updatedCheckedFilters[type] = updatedCheckedFilters[type].filter((item) => item !== value);
        } else {
            updatedCheckedFilters[type].push(value);
        }

        setCheckedFilters(updatedCheckedFilters);
    };

    const handleClearAll = () => {
        setCheckedFilters({
            Categories: [],
            Styles: [],
            Brands: [],
            Colors: [],
            Sizes: [],
        });
        history.push("/shop-grid-standard?All=true&limit=1");
        window.location.reload();
    };

    const handleApply = () => {
        closeDrawer();

        const params = new URLSearchParams(location.search);
        const selectedCategoryIds = checkedFilters["Categories"] || [];
        const selectedStyleIds = checkedFilters["Styles"] || [];
        const selectedBrandIds = checkedFilters["Brands"] || [];
        const selectedColorIds = checkedFilters["Colors"] || [];
        const selectedSizeIds = checkedFilters["Sizes"] || [];

        const obj = {
            brand: selectedBrandIds,
            category: selectedCategoryIds,
            color: selectedColorIds,
            sales: false,
            size: selectedSizeIds,
            style: selectedStyleIds,
        };

        console.log("Final Obj---------------->", obj);
        dispatch(fetchProductsByFiltereing(obj));

        if (selectedCategoryIds.length > 0) {
            params.set("category", selectedCategoryIds.join(","));
        } else {
            params.delete("category");
        }

        if (selectedStyleIds.length > 0) {
            params.set("style", selectedStyleIds.join(","));
        } else {
            params.delete("style");
        }

        if (selectedBrandIds.length > 0) {
            params.set("brand", selectedBrandIds.join(","));
        } else {
            params.delete("brand");
        }

        if (selectedColorIds.length > 0) {
            params.set("color", selectedColorIds.join(","));
        } else {
            params.delete("color");
        }

        if (selectedSizeIds.length > 0) {
            params.set("size", selectedSizeIds.join(","));
        } else {
            params.delete("size");
        }

        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    const filteredCategories = categoryData.filter((category) =>
        category?.category?.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredStyles = styleData.filter((style) =>
        style?.style?.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredBrands = brandData.filter((brand) =>
        brand?.brand?.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredColors = colorData.filter((color) =>
        color?.color?.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredSizes = sizeData.filter((size) =>
        size?.size?.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <Button onClick={showDrawer} className="border-0">
                <span>
                    <img src={filter} width="20px" alt="" />
                </span>
                <span
                    className="ml-2"
                    style={{
                        fontSize: "16px",
                    }}
                >
                    Filters
                </span>
            </Button>
            <Drawer
                title={
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span className="">Filters</span>
                        <Button className="mx-3 " type="text" danger onClick={handleClearAll}>
                            CLEAR ALL
                        </Button>
                    </div>
                }
                placement="right"
                size="100dvw"
                onClose={closeDrawer}
                open={drawerOpen}
                footer={
                    <div className="d-flex justify-content-between align-items-center mx-2">
                        <Button type="" className="" onClick={closeDrawer}
                        style={{
                            backgroundColor: '#0060aa',
                            color: 'white'
                        }}
                        >  
                            Clear
                        </Button>
                        <Button type="" className="" onClick={handleApply}
                        style={{
                            backgroundColor: '#0060aa',
                            color: 'white'
                        }}
                        >
                            APPLY
                        </Button>
                    </div>
                }
            >
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ width: "30%", borderRight: "1px solid #f0f0f0" }}>
                        <List
                            dataSource={["Categories", "Styles", "Brands", "Colors", "Sizes", "Shop Sales"]}
                            renderItem={(tab) => (
                                <List.Item
                                    style={{
                                        cursor: "pointer",
                                        padding: "10px",
                                        backgroundColor: selectedTab === tab ? "#0060aa" : "inherit",
                                        color: selectedTab === tab ? "#fff" : "#000",
                                    }}
                                    onClick={() => handleTabSelect(tab)}
                                >
                                    {tab}
                                    {checkedFilters[tab]?.length > 0 && (
                                        <span style={{ color: "#1890ff" }}>
                                            ({checkedFilters[tab].length})
                                        </span>
                                    )}
                                </List.Item>
                            )}
                        />
                    </div>
                    <div style={{ flex: 1, padding: "16px" }}>
                        {selectedTab === "Shop Sales" ? (
                            <ShopSales
                                getSortParams={() => {}}
                                allClear={handleClearAll}
                                handleUncheckAll={() => {}}
                                getFilteredData={() => {}}
                            />
                        ) : (
                            <>
                                <Input
                                    placeholder={`Search by ${selectedTab}`}
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    style={{ marginBottom: "16px" }}
                                />
                                <List
                                    dataSource={
                                        selectedTab === "Categories"
                                            ? filteredCategories
                                            : selectedTab === "Styles"
                                            ? filteredStyles
                                            : selectedTab === "Brands"
                                            ? filteredBrands
                                            : selectedTab === "Colors"
                                            ? filteredColors
                                            : filteredSizes
                                    }
                                    renderItem={(item) => (
                                        <List.Item>
                                            <Checkbox
                                                checked={checkedFilters[selectedTab]?.includes(
                                                    selectedTab === "Categories"
                                                        ? item?.categoryId
                                                        : selectedTab === "Styles"
                                                        ? item?.style
                                                        : selectedTab === "Brands"
                                                        ? item?.brand
                                                        : selectedTab === "Colors"
                                                        ? item?.color
                                                        : item?.size
                                                )}
                                                onChange={() =>
                                                    handleCheckboxChange(
                                                        selectedTab === "Categories"
                                                            ? item?.categoryId
                                                            : selectedTab === "Styles"
                                                            ? item?.style
                                                            : selectedTab === "Brands"
                                                            ? item?.brand
                                                            : selectedTab === "Colors"
                                                            ? item?.color
                                                            : item?.size,
                                                        selectedTab
                                                    )
                                                }
                                            >
                                                {selectedTab === "Categories"
                                                    ? item?.category
                                                    : selectedTab === "Styles"
                                                    ? item?.style
                                                    : selectedTab === "Brands"
                                                    ? item?.brand
                                                    : selectedTab === "Colors"
                                                    ? item?.color
                                                    : item?.size}
                                            </Checkbox>
                                        </List.Item>
                                    )}
                                />
                            </>
                        )}
                    </div>
                </div>
            </Drawer>
        </>
    );
};

Filters.propTypes = {
    selectData: PropTypes.object.isRequired,
};

export default Filters;
