import React, { Component } from "react";
import { Table, Popconfirm, Tooltip, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Column } = Table;

export default class ShowPurchaseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseData: [],
      expandedRowKeys: [],
    };
  }
  shouldComponentUpdate(nextProps, prevState) {
    if (
      nextProps.myPurchaseData != undefined &&
      nextProps.myPurchaseData.length != prevState.purchaseData.length
    ) {
      this.setState({
        purchaseData: nextProps.myPurchaseData,
      });
    }

    return true;
  }

  componentDidMount() {
    this.setDefaultExpandedRows(this.props?.myPurchaseData);
  }

  componentDidUpdate(prevProps) {
    // Check if the data has changed
    if (prevProps.myPurchaseData !== this.props?.myPurchaseData) {
      this.setDefaultExpandedRows(this.props?.myPurchaseData);
    }
  }

  onDeleteClick = (id) => {
    this.props.deletePurchase(id);
  };
  updateRecord = (record) => {
    this.props.updatePurchase(record);
  };

  expandedRowRender = (record) => {
    if (!record.packagingType || !record.packagingValue) {
      return (
        <div style={{ color: "orange" }}>
          Please Update Packaging Type & Value of Product!!
        </div>
      );
    }
  };

  setDefaultExpandedRows(data) {
    const keysToExpand = data
      .filter((record) => !record.packagingType || !record.packagingValue)
      .map((record) => record.key); // Assuming each record has a unique 'key' property

    this.setState({ expandedRowKeys: keysToExpand });
  }

  render() {
    const { myPurchaseData } = this.props;
    const { expandedRowKeys } = this.state;
    return (
      <div className="purchase-list">
        <Table
          dataSource={myPurchaseData || []}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 440 }}
          expandable={{
            expandedRowRender: (record) => this.expandedRowRender(record),
            // defaultExpandAllRows: true, // Allow all rows to be expandable
            rowExpandable: (record) => {
              return !record.packagingType || !record.packagingValue;
            },
            expandedRowKeys: expandedRowKeys,
          }}
          rowClassName={(record, index) => {
            if (record.quantity % record.MOQ != 0) {
              return "table-row-dark";
            }
          }}
        >
          {" "}
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Design No."
            dataIndex="designNumber"
            key="designNumber"
            width={120}
          />
          <Column
            title="Brand"
            dataIndex="brandName"
            key="brandName"
            width={110}
          />
          <Column title="Size" dataIndex="size" key="size" width={80} />
          <Column title="Color" dataIndex="color" key="color" width={80} />
          <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
          <Column title="Price" dataIndex="price" key="price" width={80} />
          <Column title="Qty" dataIndex="quantity" key="quantity" width={80} />
          <Column
            title="Disc(in %)"
            dataIndex="discount"
            key="discount"
            width={100}
          />
          <Column
            title="Disc(in Rs)"
            dataIndex="discount"
            key="discount"
            render={(value, item, index) =>
              (Number(item.price) *
                Number(item.quantity) *
                Number(item.discount)) /
              100
            }
            width={100}
          />
          <Column title="Amt" dataIndex="amount" key="amount" width={100} />
          <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
          <Column
            title="Boxes"
            dataIndex="noOfBoxes"
            key="noOfBoxes"
            width={80}
          />
          <Column
            title="Packets"
            dataIndex="noOfPackets"
            key="noOfPackets"
            width={80}
          />
          <Column
            title="Action"
            key="action"
            width={80}
            render={(text, record) => (
              <Row gutter={[8, 0]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Popconfirm
                    title="Are you sure to update this Record?"
                    onConfirm={() => this.updateRecord(text)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Update Record">
                      <EditOutlined />
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Popconfirm
                    title="Are you sure to delete this Record?"
                    onConfirm={() => this.onDeleteClick(text.uniqueId)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Delete Record">
                      <DeleteOutlined />
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            )}
          />
        </Table>
      </div>
    );
  }
}
