import { Button, Modal, Form, Input, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFloors } from '../../../../redux/actions/floorAction';
import { getLocations } from '../../../../redux/actions/locationAction';
import { createRack, getRacks } from '../../../../redux/actions/rackManagementAction';
import { getSections } from '../../../../redux/actions/sectionAction';

function AddRack() {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [columns, setColumns] = useState([{ id: Date.now(), column: '', capacity: '' }]);

    const locationList = useSelector((state) => state.locationData?.locations);
    const floorList = useSelector((state) => state.floorData?.floors);
    const sectionList = useSelector((state) => state.sectionData?.sections);

    useEffect(() => {
        if (!locationList?.length) dispatch(getLocations());
        if (!floorList?.length) dispatch(getFloors());
        if (!sectionList?.length) dispatch(getSections());
    }, [dispatch, locationList, floorList, sectionList]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                const columnsCount = columns.length;
                const updatedColumns = columns.map(({ id, name, ...rest }) => rest);

                // Retrieve names for location, floor, and section based on selected IDs
                const locationName = locationList.find((loc) => loc._id === values.location)?.name || '';
                const floorName = floorList.find((floor) => floor._id === values.floor)?.name || '';
                const sectionName = sectionList.find((section) => section._id === values.section)?.name || '';

                // Construct the final request body
                const finalValues = {
                    ...values,
                    locationId: values.location, // Location ID
                    floorId: values.floor,       // Floor ID
                    sectionId: values.section,   // Section ID
                    location: locationName,      // Location Name
                    floor: floorName,            // Floor Name
                    section: sectionName,        // Section Name
                    columns: updatedColumns,
                    columnsCount,
                };

                console.log('Form Values ------------------>', finalValues);

                dispatch(createRack(finalValues))
                    .finally(() => {
                        setTimeout(() => {
                            dispatch(getRacks());
                            form.resetFields();
                            setColumns([{ id: Date.now(), name: '', capacity: '' }]);
                            setIsModalOpen(false);
                        }, 1000);
                    });
            })
            .catch((info) => {
                console.log('Validation Failed:', info);
            });
    };



    const handleCancel = () => {
        form.resetFields();
        setColumns([{ id: Date.now(), name: '', capacity: '' }]);
        setIsModalOpen(false);
    };

    const addColumn = () => {
        setColumns([...columns, { id: Date.now(), name: '', capacity: '' }]);
    };

    const removeColumn = (id) => {
        if (columns.length > 1) {
            setColumns(columns.filter((column) => column.id !== id));
        }
    };

    const updateColumn = (id, field, value) => {
        setColumns(
            columns.map((column) =>
                column.id === id
                    ? { ...column, [field]: field === "capacity" ? Number(value) : value }
                    : column
            )
        );
    };


    return (
        <>
            <Button type="primary" onClick={showModal}>
                Add Rack
            </Button>
            <Modal
                title="Add Rack"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div className='d-flex justify-content-center mb-4' key="footer">
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <Form form={form} layout="vertical" name="addRackForm">
                    <Form.Item
                        name="location"
                        label="Location"
                        rules={[{ required: true, message: 'Please select a location!' }]}
                    >
                        <Select placeholder="Select a location" allowClear>
                            {locationList?.map((location) => (
                                <Select.Option key={location._id} value={location._id}>
                                    {location.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="floor"
                        label="Floor"
                        rules={[{ required: true, message: 'Please select a floor!' }]}
                    >
                        <Select placeholder="Select a floor" allowClear>
                            {floorList?.map((floor) => (
                                <Select.Option key={floor._id} value={floor._id}>
                                    {floor.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="section"
                        label="Section"
                        rules={[{ required: true, message: 'Please select a section!' }]}
                    >
                        <Select placeholder="Select a section" allowClear>
                            {sectionList?.map((section) => (
                                <Select.Option key={section._id} value={section._id}>
                                    {section.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        name="rack"
                        label="Rack Name"
                        rules={[{ required: true, message: 'Please enter a rack name!' }]}
                    >
                        <Input placeholder="Enter rack name" />
                    </Form.Item>

                    <Form.Item label="Column Details">
                        {columns.map((column, index) => (
                            <div key={column.id} style={{ display: 'flex', gap: '16px', marginBottom: '8px', alignItems: 'center' }}>
                                <Input
                                    placeholder="Enter column name"
                                    value={column.column}
                                    onChange={(e) => updateColumn(column.id, 'column', e.target.value)}
                                    style={{ flex: 2 }}
                                />
                                <Input
                                    placeholder="Capacity"
                                    type="number"
                                    value={column.capacity}
                                    onChange={(e) => updateColumn(column.id, 'capacity', e.target.value)}
                                    style={{ flex: 1 }}
                                />
                                <Button type="link" danger onClick={() => removeColumn(column.id)} disabled={columns.length === 1}>
                                    Delete
                                </Button>
                            </div>
                        ))}
                        <Button type="dashed" onClick={addColumn} style={{ width: '100%' }}>
                            Add Column
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default AddRack;
