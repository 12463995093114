import { FileDoneOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { Modal, Table, Button, Typography, Col, Row, Tooltip } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import {
  createSales,
  getSaleUsingInvoiceId,
} from "../../../redux/actions/saleAction";
import { warehouseSaleListbybillId } from "../../../redux/actions/warehouseActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Spin } from "antd";

// import "../../../assets/css/generateBill.css";

class ViewWarehouseGenerateBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      // billDate: "",
      // saleDate: moment(new Date()),
    };
  }

  handleModalCancel = () => {
    // window.location.reload()
    this.props.getSaleUsingInvoiceId("reset");
    this.setState({ visible: false });
  };

  showModal = () => {
    this.getDetails();
    this.setState({
      visible: true,
    });
  };

  getDetails = async () => {
    let invoiceId = this.props.invoiceId
      ? this.props.invoiceId
      : this.props.text?.invoiceId;
    await this.props.warehouseSaleListbybillId({ warehousesaleId: invoiceId });
  };

  handlePdfGeneration = () => {
    const element = document.getElementById("pdf-content");
    if (!element) {
      return;
    }

    element.style.width = "400px";
    element.style.margin = "0 auto";

    const momentDate = moment(this.props?.record?.date);
    const formattedDate = momentDate.format("DD/MM/YYYY");
    const formattedTime = momentDate.format("HH:mm:ss");

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `${this.props?.salesOrders?.[0]?.invoiceId}-${this.props?.text?.userId?.name}-${formattedDate}-${formattedTime}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // css: customPdfStyles
    };

    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        element.style.width = null;
        element.style.margin = null;
      });
  };

  render() {
    const { visible } = this.state;
    const { salesOrders, text } = this.props;

    //Function to group bill item data according to the brand respectivly-
    let groupedData = {};
    if (salesOrders && salesOrders.details) {
      for (let i = 0; i < salesOrders.details.length; i++) {
        let purchase = salesOrders.details[i];
        let purchaseName = purchase.brand;
        if (!groupedData[purchaseName]) {
          groupedData[purchaseName] = [];
        }
        groupedData[purchaseName].push(purchase);
      }
    }


    let data = Object.keys(groupedData).map((purchaseName, index) => {
      let insideData = groupedData[purchaseName].map((purchase, innerIndex) => (
        <li key={innerIndex}>
          <Row className="mt-n2">
            <Col className="mr-1" style={{ width: "108px" }}>
              <p style={{ fontWeight: 800 }}>
                {innerIndex + 1} || {purchase.designNumber}
              </p>
            </Col>
            <Col className="mr-1" style={{ width: "80px" }}>
              <p style={{ fontWeight: 800 }}>{purchase.size}</p>
            </Col>
            <Col className="mr-2" style={{ width: "50px" }}>
              <p className="mb-n2" style={{ fontWeight: 800 }}>
                {purchase.quantity}
              </p>
            </Col>
            <Col className="mr-1" style={{ width: "50px" }}>
              <p className="mb-n2" style={{ fontWeight: 800 }}>
                {purchase.wsp}
              </p>
            </Col>
            <Col className="mr-1" style={{ width: "66px" }}>
              <p className="mb-n2" style={{ fontWeight: 800 }}>
                {Math.round(purchase.quantity * purchase.wsp)}
              </p>
            </Col>
          </Row>
          <hr />
        </li>
      ));

      return (
        <li key={index}>
          <h5>
            <u>
              <strong style={{ fontSize: "18px" }}>{purchaseName}</strong>
            </u>
          </h5>
          <ul>{insideData}</ul>
        </li>
      );
    });

    return (
      <>
        {console.log("checkkk obj", salesOrders)}
        <div className="generate-bill">
          <Tooltip title="View Bill">
            <FileDoneOutlined onClick={this.showModal} />
          </Tooltip>

          <ReactToPrint content={() => this.componentRef}>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Generate Bill"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
              width={1500}
            >
              {console.log("ssssssss", this.props.invoiceId)}
              <div className="card generate-bill-card" id="pdf-content">
                {console.log("whSalebilldata", salesOrders)}
                <div
                  className="card-body generate-bill-font"
                  style={{ padding: "0.6rem" }}
                  ref={(el) => (this.componentRef = el)}
                >
                  {salesOrders?.whSalebilldata ? (
                    <>
                      <div className="generate-bill-font-test7">
                        <Row>
                          <Col span={8} style={{ textAlign: "center" }}>
                            <strong>
                              <p
                                className="mb-n2 font-weight-bold"
                                style={{ width: "380px" }}
                              >
                                || Jai Shree Krishna ||
                              </p>
                            </strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            span={8}
                            className="mr-2"
                            style={{ textAlign: "center" }}
                          >
                            <h4
                              className="mb-n1 mt-2"
                              style={{ width: "380px" }}
                            >
                              Estimate
                            </h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <p className="mb-n1" style={{ width: "350px" }}>
                              <strong>Warehouse Id : </strong>
                              {this.props.invoiceId}
                            </p>
                            <p style={{ width: "350px" }}>
                              <strong>
                                Store Name:
                                {salesOrders?.whSalebilldata?.storename}
                              </strong>
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mr-5">
                            <p className="mb-n2" style={{ width: "350px" }}>
                              <strong>
                                Date :
                                {(() => {
                                  const momentDate = moment(
                                    salesOrders[0]?.salesBilldetails?.createdAt
                                  );
                                  const formattedDate =
                                    momentDate.format("DD/MM/YYYY");
                                  const formattedTime =
                                    momentDate.format("HH:mm:ss");
                                  return (
                                    <span>
                                      {formattedDate} &nbsp;&nbsp;{" "}
                                      {formattedTime}
                                    </span>
                                  );
                                })()}
                              </strong>
                            </p>
                          </Col>
                        </Row>
                        <div style={{ width: "380px", display: "block" }}>
                          <Row className="mt-3">
                            <Col className="mr-1" style={{ width: "114px" }}>
                              <strong>
                                {" "}
                                <p className="font-weight-bold">Particulars</p>
                              </strong>
                            </Col>
                            <Col className="mr-1" style={{ width: "80px" }}>
                              <strong>
                                <p className="font-weight-bold">Size</p>
                              </strong>
                            </Col>
                            <Col className="mr-1" style={{ width: "50px" }}>
                              <strong>
                                {" "}
                                <p className="font-weight-bold">Qty</p>
                              </strong>
                            </Col>
                            <Col className="mr-1" style={{ width: "50px" }}>
                              <strong>
                                {" "}
                                <p className="font-weight-bold">Wsp</p>
                              </strong>
                            </Col>
                            <Col className="mr-1" style={{ width: "60px" }}>
                              <p className="font-weight-bold">Amt</p>
                            </Col>
                          </Row>
                          <hr />
                          <ul>{data}</ul>
                          <hr />
                          {console.log("check mohit hello data", salesOrders)}
                          <Row className="mt-2">
                            <Col
                              className="mr-2"
                              style={{ width: "120px", textAlign: "left" }}
                            >
                              <p>Total Qty</p>
                            </Col>
                            <Col
                              className="mr-2"
                              style={{ textAlign: "center", width: "120px" }}
                            >
                              <p style={{ fontWeight: 800 }}>
                                {salesOrders?.whSalebilldata?.quantity}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col
                              className="mr-2"
                              style={{ width: "120px", textAlign: "left" }}
                            >
                              <p>Total Boxes</p>
                            </Col>
                            <Col
                              className="mr-2"
                              style={{ textAlign: "center", width: "120px" }}
                            >
                              <p style={{ fontWeight: 800 }}>
                                {salesOrders?.whSalebilldata?.totalBoxes}
                              </p>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Col
                              className="mr-2"
                              style={{ width: "120px", textAlign: "left" }}
                            >
                              <p>Total Amt</p>
                            </Col>
                            <Col
                              className="mr-2"
                              style={{ textAlign: "center", width: "120px" }}
                            ></Col>
                            <Col
                              className="generate-bill"
                              style={{ textAlign: "end", width: "110px" }}
                            >
                              <p style={{ fontWeight: 800 }}>
                                {
                                  (function () {
                                    let totalAmt = 0
                                    for (let index = 0; index < salesOrders.details.length; index++) {
                                      totalAmt = (salesOrders.details[index].wsp * salesOrders.details[index].quantity) + totalAmt
                                    }
                                    return Number(totalAmt).toFixed()
                                  })()
                                }
                                {/* {Number(
                                  salesOrders?.whSalebilldata?.totalAmount
                                ).toFixed()} */}
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="mx-2">
                          <Row>
                            <Col style={{ width: "175px", textAlign: "left" }}>
                              <p className="mb-n2">E.D.E.</p>
                            </Col>
                            <Col style={{ textAlign: "end", width: "175px" }}>
                              <p className="mb-n2">Authorised Signatory</p>
                            </Col>
                          </Row>
                          <p className="mb-n2">Terms & Cond.</p>
                          <p className="mb-n2">
                            1. Goods once sold will not be taken back
                          </p>
                          <p>2. No Claim No Guarantee</p>
                        </div>
                        <Row>
                          <Col span={8} style={{ textAlign: "center" }}>
                            <p
                              className="font-weight-bold"
                              style={{ width: "350px" }}
                            >
                              || Thank You Visit Again ||
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="spin-ViewBill">
                        {console.log("pp", this.props)}
                        <Spin />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <Row className="generate-pdf-download-row">
                <Col>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        style={{ marginLeft: "45%" }}
                        type="primary"
                        className="close-modal mt-4"
                        onClick={() => {
                          handlePrint();
                        }}
                      >
                        Generate Bill
                      </Button>
                    )}
                  </PrintContextConsumer>
                </Col>
                <Col style={{ marginLeft: "1rem" }}>
                  <Button
                    style={{ marginLeft: "45%" }}
                    type="primary"
                    className="save-modal mt-4"
                    // block
                    onClick={() => {
                      this.handlePdfGeneration();
                    }}
                  >
                    Download PDF
                  </Button>
                </Col>
              </Row>
            </Modal>
          </ReactToPrint>
        </div>
      </>
    );
  }
}

ViewWarehouseGenerateBill.propTypes = {
  createSales: PropTypes.func.isRequired,
  getSaleUsingInvoiceId: PropTypes.func.isRequired,
  warehouseSaleListbybillId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  salesOrders: state.warehouseData.warehouseSaleListbybillId,
});

export default connect(mapStateToProps, {
  createSales,
  getSaleUsingInvoiceId,
  warehouseSaleListbybillId,
})(ViewWarehouseGenerateBill);
