import LeftOutlined from '@ant-design/icons/LeftOutlined'
import { Button, Card, Col, Progress, Row } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

function ColumnCard() {
    const history = useHistory()

    const handleBack = () => {
        history.push('/dagaImpex/rack-management')
    }

    return (
        <>
            <Card>
                <Row gutter={24} className="mb-2">
                    <Col sm={24} md={8} lg={8}>
                        <div className='rackManagementHeading'>
                            Rack Column
                        </div>
                    </Col>
                    <Col sm={24} md={16} lg={16} align="end">
                        <Button className='d-flex align-items-center justify-content-end float-right'
                            type='primary'
                            onClick={handleBack}
                        >
                            <LeftOutlined />
                            Back
                        </Button>
                    </Col>
                </Row>

                <div className='my-5'>
                    <Row gutter={24} className="mb-2">
                        <Col sm={24} md={24} lg={24} className="border">
                            <Row gutter={24} className="">
                                <Col sm={24} md={8} lg={6} className="p-2 rackColumnSection border">
                                    <div className='rackManagementColumnHeading'>
                                        Column Name
                                        <Progress percent={30} />
                                    </div>
                                </Col>
                                <Col sm={24} md={16} lg={18} className="p-2">
                                    <div className='rackManagementColumnHeading'>
                                        Column Item Details
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Card>
        </>
    )
}

export default ColumnCard