import React from 'react'
import "./style.css"

function RackCard() {
    return (
        <>
            <div className="outer">
                <div className="dot"></div>
                <div className="card-rack">
                    <div className="ray"></div>
                    <div className="text">Rack Name</div>
                    <div>No of Columns</div>
                    <div className="line topl"></div>
                    <div className="line leftl"></div>
                    <div className="line bottoml"></div>
                    <div className="line rightl"></div>
                </div>
            </div>

        </>
    )
}

export default RackCard