import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Radio,
  InputNumber,
  Button,
  Card,
  message,
  Tooltip,
  DatePicker
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgents,
  getDataByPincode,
  getRoles,
  registerUserInternal,
} from "../../../redux/actions/userAction";

const { Option } = Select;
const AddUserForm = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [data, setData] = useState({
    loading: false,
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    transport: "",
    agentId: undefined,
    roleId: "",
    confirmPassword: "",
    gst: "",
    address: "",
    pincode: "",
    state: "",
    errors: {},
    isCreditUser: false,
    isCashUser: true,
    creditLimit: 0,
    city: "",
    pincode: "",
    GSTPANADH: 1,
    aadhar: "",
    pannumber: "",
    firmnames: [],
    cityId: "",
    stateId: "",
    region: "",
    regionDisable: true,
    discount: 0,
    dob: "",
    note: "",
  });

  const roles = useSelector((state) => state.userData.roles);
  const agents = useSelector((state) => state.userData.agents);
  const pincodeData = useSelector((state) => state.userData.pincodeData);

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getAgents());
  }, []);

  const handleFirmsChange = (values) => {
    setData((prevData) => ({
      ...prevData,
      firmnames: values,
    }));
  };

  const roleName = localStorage.getItem("roleName");

  let roleOptions = roles.map((role) => {
    return (
      <Option name={role.roleName} value={role._id}>
        {role.roleName}
      </Option>
    );
  });

  let regionOptions = pincodeData?.PostOffice?.map((name, index) => {
    return (
      <Option key={index} value={name?.Name}>
        {name?.Name}
      </Option>
    );
  });

  let roleOptionsSalesAdmin = roles
    ?.filter(
      (role) => role.roleName !== "ADMIN" && role.roleName !== "PURCHASE"
    )
    ?.map((role) => {
      return (
        <Option name={role.roleName} value={role._id}>
          {role.roleName}
        </Option>
      );
    });
  let roleOptionsPurchaseAdmin = roles
    ?.filter((role) => role.roleName === "PURCHASER")
    ?.map((role) => {
      return (
        <Option name={role.roleName} value={role._id}>
          {role.roleName}
        </Option>
      );
    });

  let roleOptionReception = roles
    ?.filter((role) => role.roleName === "STORE_USER")
    ?.map((role) => {
      return (
        <Option name={role.roleName} value={role._id}>
          {role.roleName}
        </Option>
      );
    });

  let agentOptions = agents.map((agent) => {
    return (
      <Option name={agent.name} value={agent._id}>
        {agent.name}
      </Option>
    );
  });

  const onChange = (event) => {
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const onCreditUserChange = (event) => {
    const value = event.target.value;
    const cashValue = !value
    if (value) {
      setData((prevData) => ({
        ...prevData,
        isCreditUser: value,
        isCashUser: cashValue,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        isCreditUser: value,
        isCashUser: cashValue,
        creditLimit: 0,
      }));
      formRef.current.resetFields(["creditLimit"]);
    }
  };

  const onPincodeChange = (event) => {
    const value = event.target.value;
    if (value.length == 6) {
      setData((prevData) => ({
        ...prevData,
        region: "",
        city: "",
        state: "",
      }));

      formRef.current.resetFields(["region"]);
      const res = dispatch(getDataByPincode(value)).then((res) => {
        if (res?.Message == "No records found") {
          message.error("Enter Correct Pincode");
          setData((prevData) => ({
            ...prevData,
            pincode: "",
            city: "",
            state: "",
            regionDisable: true,
          }));
        } else {
          let city = res?.PostOffice[0]?.District;
          let state = res?.PostOffice[0]?.State;
          setData((prevData) => ({
            ...prevData,
            pincode: value,
            city: city,
            state: state,
            regionDisable: false,
          }));
        }
      });
    } else {
      setData((prevData) => ({ ...prevData, pincode: value }));
    }
  };

  const handleChangeRole = (key, value) => {
    console.log("value1", value);
    setData((prevData) => ({ ...prevData, roleId: value.value }));
  };

  const handleChangeAgent = (key, value) => {
    console.log("value2", value);
    setData((prevData) => ({ ...prevData, agentId: value.value }));
  };

  const onSelectRegion = (value) => {
    const selectedOption = pincodeData?.PostOffice.find(
      (option) => option.Name === value
    );
    if (selectedOption) {
      setData((prevData) => ({
        ...prevData,
        region: selectedOption?.Name,
        city: selectedOption?.District,
        state: selectedOption?.State,
      }));
    }
  };
  const onSubmit = () => {
    setData((prevData) => ({ ...prevData, loading: true }));
    setTimeout(() => {
      setData((prevData) => ({ ...prevData, loading: false }));
    }, 1000);

    const userObj = {
      name: data.name,
      email: data.email,
      mobileNumber: data.mobileNumber,
      roleId: data.roleId,
      agentId: data.agentId,
      transport: data.transport,
      gst: data.gst,
      discount: data.discount,
      address: {
        address: data.address,
        pincode: data.pincode,
        state: data.state,
        city: data.city,
        mobileNumber: data.mobileNumber,
        transport: data.transport,
        region: data.region,
      },
      pin: data.pin,
      GSTPANADH: data.GSTPANADH,
      firmnames: data.firmnames,
      aadharnumber: data.aadhar,
      pincode: data.pincode,
      state: data.state,
      city: data.city,
      region: data.region,
      transport: data.transport,
      dob: data.dob,
      note: data.note,
      isCreditUser: data.isCreditUser ? data.isCreditUser : false,
      isCashUser: data?.isCreditUser ? false : true,
      creditLimit: data.creditLimit,
      password:
        data.roleId === "622a47e017844801d2c839a4"
          ? data.mobileNumber
          : data.password,
    };
    console.log("userObj", userObj);
    dispatch(registerUserInternal(userObj));
  };

  return (
    <div className="add-purchase ">
      <div className="container-fluid">
        <Row className="title-row">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="page-header">Add User</div>
          </Col>
        </Row>
        <div className="card mt-4">
          <div className="card-body">
            <Form
              name="add-user"
              className="add-user"
              onFinish={onSubmit}
              initialValues={{
                remember: true,
              }}
              ref={formRef}
            >
              <Row gutter={16}>
                {console.log("data", data)}
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input
                      name="name"
                      value={data.name}
                      onChange={onChange}
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input
                      name="email"
                      type="email"
                      value={data.email}
                      placeholder="Enter Email"
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobilenumber"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your  Mobile Number!",
                      },
                      {
                        min: 10,
                        message: "Mobile must be 10-digits!",
                      },
                      {
                        max: 10,
                        message: "Mobile must be 10-digits!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var hasAlphabets = /^[0-9]+$/;
                          if (
                            parseInt(getFieldValue("mobilenumber")) &&
                            hasAlphabets.test(getFieldValue("mobilenumber"))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Should contain numbers!");
                        },
                      }),
                    ]}
                  >
                    <Input
                      name="mobileNumber"
                      type="number"
                      placeholder="Enter Mobile Number"
                      value={data.mobileNumber}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Role"
                    name="role"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    rules={[
                      { required: true, message: "Please select a role" },
                    ]}
                  >
                    <Select
                      name="roleId"
                      value={data.roleId}
                      onChange={handleChangeRole}
                      placeholder="Select Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {roleName === "ADMIN" && roleOptions}
                      {roleName === "SALES_ADMIN" && roleOptionsSalesAdmin}
                      {roleName === "SALES" && roleOptionsSalesAdmin}
                      {roleName === "PURCHASE_ADMIN" &&
                        roleOptionsPurchaseAdmin}
                      {roleName === "RECEPTION" && roleOptionReception}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              {(() => {
                if (data.roleId == "622a47e017844801d2c839a4") {
                  return (
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Agent"
                          name="agent"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a agent",
                            },
                          ]}
                        >
                          <Select
                            name="agentId"
                            value={data.agentId}
                            onChange={handleChangeAgent}
                            placeholder="Select Agent"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            showSearch
                          >
                            {agentOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="firmName"
                          rules={[
                            {
                              required: true,
                              message: "Please Add Firm Name",
                            },
                          ]}
                          label="Firm Name"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Select
                            mode="tags"
                            placeholder="Enter Firm Names"
                            value={data.firmnames}
                            onChange={(values) => handleFirmsChange(values)}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="GST/PAN/URD"
                          name="GSTPANADH"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a option",
                            },
                          ]}
                        >
                          <Card>
                            <Radio.Group
                              name="GSTPANADH"
                              id="GSTPANADH"
                              onChange={onChange}
                            >
                              <Radio value={1}>GST</Radio>
                              <Radio value={3}>PAN</Radio>
                              <Radio value={4}>URD</Radio>
                            </Radio.Group>
                          </Card>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="GST/PAN Number"
                          name="gst"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: data?.GSTPANADH === 4 ? false : true,
                              message: "Please enter GST/PAN No.",
                            },

                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                const verificationStatus =
                                  getFieldValue("GSTPANADH");
                                if (verificationStatus == 1) {
                                  const gstRegex =
                                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
                                  if (
                                    value?.length !== 15 ||
                                    !gstRegex.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "GST number must be like 22AAAAA0000A1ZR"
                                      )
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                } else if (verificationStatus == 3) {
                                  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                  if (
                                    value?.length !== 10 ||
                                    !panRegex.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "PAN number must be like ABCDE1234F"
                                      )
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            name="gst"
                            placeholder="Enter GST/PAN Number"
                            value={data.gst}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Aadhar Number"
                          name="aadhar"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: data?.GSTPANADH === 4 ? true : false,
                              message: "Aadhar No is required!",
                            },
                            {
                              min: 12,
                              message: "Aadhar No. must be 12-digits!",
                            },
                            {
                              max: 12,
                              message: "Aadhar No. must be 12-digits!",
                            },
                          ]}
                        >
                          <Input
                            name="aadhar"
                            placeholder="Enter Aadhar Number"
                            value={data.aadhar}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <Form.Item
                          label="PAN Number"
                          name="pan"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter PAN number",
                            },
                          ]}
                        >
                          <Input
                            name="pannumber"
                            placeholder="Enter PAN Number"
                            value={data.pannumber}
                            onChange={onChange}
                          />
                        </Form.Item> */}
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Select DOB">
                          <Form.Item
                            name="DOB"
                            label="DOB"
                            labelAlign="left"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Select DOB !",
                              },
                            ]}
                          >
                            <DatePicker
                              // style={{ width: "100%" }}
                              onChange={(date, dateString) =>
                                setData((prevData) => ({
                                  ...prevData,
                                  dob:dateString
                                }))
                              }
                              placeholder="Select DOB"
                              format={"YYYY-MM-DD"}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Select DOB">
                          <Form.Item
                            name="note"
                            label="Note"
                            labelAlign="left"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 18 }}
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Note !",
                              },
                            ]}
                          >
                            <Input.TextArea
                              name="note"
                              autoSize={{
                                minRows: 5,
                              }}
                              onChange={onChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="USER Type"
                          labelAlign="left" 
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <div className="border p-2 rounded-sm">
                            <Radio.Group
                              name="isCreditUser"
                              onChange={onCreditUserChange}
                              value={data.isCreditUser}
                            >
                              <Radio className="font-weight-bold " value={true}>Credit User</Radio>
                              <Radio className="font-weight-bold " value={false}>Cash User</Radio>
                            </Radio.Group>
                          </div>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Credit Limit"
                          name="creditLimit"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: data.isCreditUser ? true : false,
                              message: "Please Enter Credit Limit For User!",
                            },
                          ]}
                        >
                          <Input
                            disabled={!data.isCreditUser ? true : false}
                            type="number"
                            name="creditLimit"
                            placeholder="Enter Credit Limit"
                            value={data.creditLimit}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Discount (%)"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Input
                            type="number"
                            name="discount"
                            placeholder="Enter Discount"
                            value={data.discount}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Address"
                          name="address"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            { required: true, message: "Please enter address" },
                          ]}
                        >
                          <Input
                            name="address"
                            placeholder="Enter Address"
                            value={data.address}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Pincode"
                          labelAlign="left"
                          name="pincode"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                            },
                            {
                              min: 6,
                              message: "Pincode must be 6-digits!",
                            },
                            {
                              max: 6,
                              message: "Pincode must be 6-digits!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                var hasAlphabets = /^[0-9]+$/;
                                if (
                                  parseInt(getFieldValue("pincode")) &&
                                  hasAlphabets.test(getFieldValue("pincode"))
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "Should contain numbers!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            name="pincode"
                            placeholder="Enter Pincode"
                            value={data.pincode}
                            onChange={onPincodeChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Region"
                          name="region"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a region",
                            },
                          ]}
                        >
                          <Select
                            disabled={data.regionDisable}
                            name=" region"
                            value={data.region}
                            placeholder="Select Region"
                            onChange={onSelectRegion}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            showSearch
                          >
                            {regionOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="City"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Input
                            disabled
                            type="string"
                            name="city"
                            id="string"
                            value={data.city}
                            placeholder="City"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="State"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Input
                            disabled
                            type="string"
                            name="state"
                            id="string"
                            value={data.state}
                            placeholder="State"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Transport"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Input
                            placeholder="Enter Transport"
                            name="transport"
                            id="transport"
                            value={data.transport}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                } else if (data.roleId == "622a47d2a0c03827d4f07294") {
                  return (
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Transport"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Input
                            placeholder="Enter Transport"
                            name="transport"
                            id="transport"
                            value={data.transport}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Pincode"
                          name="pincode"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: false,
                            },
                            {
                              min: 6,
                              message: "Pincode must be 6-digits!",
                            },
                            {
                              max: 6,
                              message: "Pincode must be 6-digits!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                var hasAlphabets = /^[0-9]+$/;
                                if (
                                  parseInt(getFieldValue("pincode")) &&
                                  hasAlphabets.test(getFieldValue("pincode"))
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  "Should contain numbers!"
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            name="pincode"
                            placeholder="Enter Pincode"
                            value={data.pincode}
                            onChange={onPincodeChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Region"
                          name="region"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "Please select a region",
                            },
                          ]}
                        >
                          <Select
                            disabled={data.regionDisable}
                            name="region"
                            value={data.region}
                            onChange={onSelectRegion}
                            placeholder="Select Region"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              optionA.children
                                .toLowerCase()
                                .localeCompare(optionB.children.toLowerCase())
                            }
                            showSearch
                          >
                            {regionOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="City"
                          name="city"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "City cannot be empty",
                            },
                          ]}
                        >
                          <Input
                            disabled
                            type="string"
                            name="city"
                            id="string"
                            value={data.city}
                            placeholder="City"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="State"
                          name="state"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "State cannot be empty",
                            },
                          ]}
                        >
                          <Input
                            disabled
                            type="string"
                            name="state"
                            id="string"
                            value={data.state}
                            placeholder="State"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="USER IS CREDIT"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                        >
                          <Card>
                            <Radio.Group
                              name="isCreditUser"
                              onChange={onCreditUserChange}
                              value={data.isCreditUser}
                            >
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Card>
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Credit Limit"
                          name="creditLimit"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: data.isCreditUser ? true : false,
                              message: "Please Enter Credit Limit For User!",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            disabled={!data.isCreditUser ? true : false}
                            placeholder="Enter Credit Limit"
                            name="creditLimit"
                            value={data.creditLimit}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
              })()}

              {(() => {
                if (data.roleId != "622a47e017844801d2c839a4") {
                  return (
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="password"
                          label="Password"
                          labelAlign="left"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                            {
                              min: 8,
                              message: "Min 8 characters required!",
                            },
                            {
                              max: 20,
                              message: "Max 20 characters required!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            placeholder="Password"
                            name="password"
                            id="password"
                            value={data.password}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item
                          name="confirmPassword"
                          labelAlign="left"
                          label="Confirm Password"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error("Passwords do not match!")
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            placeholder="Confirm Password"
                            labelAlign="left"
                            name="confirmPassword"
                            id="confirmPassword"
                            value={data.confirmPassword}
                            onChange={onChange}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }
              })()}
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      loading={data.loading}
                      block
                    >
                      Create
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
