import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  Radio,
  InputNumber,
  Button,
  Card,
  message,
  Modal,
  Tooltip,
  DatePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgents,
  getAllUsers,
  getDataByPincode,
  getRoles,
  updateUser,
  registerUserInternal,
} from "../../../redux/actions/userAction";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";
const { Option } = Select;
const UpdateUserForm = ({ userData, pageNo }) => {
  const dispatch = useDispatch();
  // const formRef1 = useRef();
  // const formRef2 = useRef();
  const [data, setData] = useState({
    loading: "",
    visible: "",
    agentId: "",
    name: "",
    email: "",
    errors: {},
    _id: null,
    roleId: "",
    role: "",
    isCreditUser: false,
    isCashUser: true,
    creditLimit: 0,
    creditAmount: 0,
    mobileNumber: "",
    gst: "",
    state: "",
    city: "",
    pincode: "",
    aadharnumber: "",
    pannumber: "",
    firmnames: [],
    address: "",
    username: "",
    cityId: "",
    stateId: "",
    role: "",
    isCreditUser: "",
    isCashUser: "",
    agent: null,
    GSTPANADH: "",
    regionDisable: "",
    region: "",
    discount: 0,
    dob: "",
    note: "",
    arefirmnamesavailable: "",
  });

  const roles = useSelector((state) => state.userData.roles);
  const agents = useSelector((state) => state.userData.agents);
  const pincodeData = useSelector((state) => state.userData.pincodeData);

  const showModal = async () => {
    console.log("userData", userData);
    if (userData?.role?.roleName == "STORE_USER") {
      const res = dispatch(getDataByPincode(userData?.pincode)).then((res) => {
        if (res?.Message == "No records found") {
          message.error("Enter Correct Pincode");
          setData((prevData) => ({
            ...prevData,
            regionDisable: true,
          }));
        } else {
          setData((prevData) => ({
            ...prevData,
            regionDisable: false,
          }));
        }
      });
    }

    setData((prevData) => ({
      ...prevData,
      loading: false,
      agentId: userData?.agentId,
      name: userData?.name || "",
      email: userData?.email || "",
      errors: {},
      _id: userData?._id || null,
      roleId: userData?.role?._id || "",
      role: userData?.role?.roleName || "",
      isCreditUser: userData?.isCreditUser || false,
      creditLimit: userData?.creditLimit || 0,
      creditAmount: userData?.creditAmount || 0,
      mobileNumber: userData?.mobileNumber || "",
      gst: userData?.gst || "",
      state: userData?.state || "",
      city: userData?.city || "",
      pincode: userData?.pincode || "",
      aadharnumber: userData?.aadharnumber || "",
      pannumber: userData?.pannumber || "",
      firmnames: userData?.firmnames ? userData?.firmnames : [],
      address: userData?.address[0]?.address || "",
      username: userData?.username || "",
      cityId: "",
      stateId: "",
      role: userData?.role?.roleName,
      isCreditUser: userData?.isCreditUser,
      agent: userData && userData.agentId ? userData.agentId.name : null,
      GSTPANADH: userData?.GSTPANADH ? userData?.GSTPANADH : 0,
      region: userData?.region,
      visible: true,
      dob: moment(userData?.dob),
      note: userData?.note,
      discount: userData?.userDiscount ? userData?.userDiscount : 0,
    }));
  };

  const handleModalCancel = () => {
    setData((prevData) => ({
      ...prevData,
      loading: false,
      visible: false,
      agentId: "",
      name: "",
      email: "",
      errors: {},
      _id: null,
      roleId: "",
      role: "",
      isCreditUser: false,
      creditLimit: 0,
      creditAmount: 0,
      mobileNumber: "",
      gst: "",
      state: "",
      city: "",
      pincode: "",
      aadharnumber: "",
      pannumber: "",
      firmname: "",
      address: "",
      username: "",
      cityId: "",
      stateId: "",
      role: "",
      isCreditUser: "",
      agent: "",
      GSTPANADH: 0,
      region: "",
      dob: "",
      note: "",
    }));
    // formRef1.current.resetFields(["Region", "City", "State"]);
  };

  useEffect(() => {
    dispatch(getRoles());
    dispatch(getAgents());
  }, []);

  const roleName = localStorage.getItem("roleName");

  let roleOptions = roles.map((role) => {
    return (
      <Option name={role.roleName} value={role._id}>
        {role.roleName}
      </Option>
    );
  });

  const handleFirmsChange = (values) => {
    console.log(values);
    setData((prevData) => ({
      ...prevData,
      firmnames: values,
    }));
  };

  let regionOptions = pincodeData?.PostOffice?.map((name, index) => {
    return (
      <Option key={index} value={name?.Name}>
        {name?.Name}
      </Option>
    );
  });

  let roleOptionsSalesAdmin = roles
    ?.filter(
      (role) => role.roleName !== "ADMIN" && role.roleName !== "PURCHASE"
    )
    ?.map((role) => {
      return (
        <Option name={role.roleName} value={role._id}>
          {role.roleName}
        </Option>
      );
    });
  let roleOptionsPurchaseAdmin = roles
    ?.filter((role) => role.roleName === "PURCHASER")
    ?.map((role) => {
      return (
        <Option name={role.roleName} value={role._id}>
          {role.roleName}
        </Option>
      );
    });

  let roleOptionReception = roles
    ?.filter((role) => role.roleName === "STORE_USER")
    ?.map((role) => {
      return (
        <Option name={role.roleName} value={role._id}>
          {role.roleName}
        </Option>
      );
    });

  let agentOptions = agents.map((agent) => {
    return (
      <Option name={agent.name} value={agent._id}>
        {agent.name}
      </Option>
    );
  });

  const onChange = (event) => {
    setData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const onPincodeChange = (event) => {
    const value = event.target.value;
    if (value.length == 6) {
      setData((prevData) => ({
        ...prevData,
        region: null,
        city: "",
        state: "",
      }));

      // formRef1.current.resetFields(["region"]);
      const res = dispatch(getDataByPincode(value)).then((res) => {
        if (res?.Message == "No records found") {
          message.error("Enter Correct Pincode");
          setData((prevData) => ({
            ...prevData,
            pincode: "",
            city: "",
            state: "",
            regionDisable: true,
          }));
        } else {
          let city = res?.PostOffice[0]?.District;
          let state = res?.PostOffice[0]?.State;
          setData((prevData) => ({
            ...prevData,
            pincode: value,
            city: city,
            state: state,
            regionDisable: false,
          }));
        }
      });
    } else {
      setData((prevData) => ({ ...prevData, pincode: value }));
    }
  };

  const handleChangeRole = (key, value) => {
    console.log("value1", value);
    setData((prevData) => ({
      ...prevData,
      roleId: value.value,
      role: value.name,
    }));
  };

  const handleChangeAgent = (key, value) => {
    console.log("value2", value);
    setData((prevData) => ({
      ...prevData,
      agentId: value.value,
      agent: value.name,
    }));
  };
  const onCreditUserChange = (event) => {
    const value = event.target.value;
    const isCredit = value === "true";

    setData((prevData) => ({
      ...prevData,
      isCreditUser: isCredit,
      isCashUser: !isCredit,
      creditLimit: isCredit ? prevData.creditLimit : 0,
    }));
  };

  const onSelectRegion = (value) => {
    const selectedOption = pincodeData?.PostOffice.find(
      (option) => option.Name === value
    );
    if (selectedOption) {
      setData((prevData) => ({
        ...prevData,
        region: selectedOption?.Name,
        city: selectedOption?.District,
        state: selectedOption?.State,
      }));
    }
  };

  const onSubmit = (event) => {
    if (userData?.role?.roleName == "STORE_USER" && data.pincode == "") {
      message.error("Please Enter Correct Pincode");
      return;
    }
    if (userData?.role?.roleName == "STORE_USER" && data.region == "") {
      message.error("Please Select Region");
      return;
    }
    if (data.firmnames.length === 0) {
      setData((prevData) => ({
        ...prevData,
        visible: true,
        arefirmnamesavailable: false,
      }));
      return;
    } else {
      setData((prevData) => ({ ...prevData, arefirmnamesavailable: true }));
    }
    let userObj;

    if (data.role == "STORE_USER") {
      userObj = {
        name: data.name,
        email: data.email,
        roleId: data.roleId,
        isCreditUser: data.isCreditUser,
        isCashUser: data.creditLimit ? false : true,
        creditLimit: data.creditLimit,
        _id: userData?._id,
        agentId: data.agentId,
        mobileNumber: data.mobileNumber,
        gst: data.gst,
        pincode: data.pincode,
        aadharnumber: data.aadharnumber,
        pannumber: data.pannumber,
        firmnames: data.firmnames.length > 0 ? data.firmnames : [],
        discount: data.discount,
        address: {
          _id: userData?.address[0]?._id,
          address: data.address,
          pincode: data.pincode,
          region: data.region,
          state: data.state,
          city: data.city,
          mobileNumber: data.mobileNumber,
          transport: data.transport,
        },
        state: data.state,
        city: data.city,
        region: data.region,
        username: data.mobileNumber,
        GSTPANADH: data.GSTPANADH,
        dob: data.dob,
        note: data.note,
      };
    } else {
      userObj = {
        name: data.name,
        email: data.email,
        roleId: data.roleId,
        isCreditUser: false,
        creditLimit: 0,
        _id: userData?._id,
        agentId: "",
        mobileNumber: data.mobileNumber,
        gst: "",
        pincode: "",
        aadharnumber: "",
        pannumber: "",
        firmnames: [],
        discount: "",
        address: {
          _id: userData?.address[0]?._id,
          address: "",
          pincode: "",
          region: "",
          state: "",
          city: "",
          mobileNumber: data.mobileNumber,
          transport: "",
        },
        state: "",
        city: "",
        region: "",
        username: data.mobileNumber,
        GSTPANADH: "",
      };
    }

    console.log("updateUser", userObj);
    dispatch(updateUser(userObj))
      .then((response) => {
        console.log("taran", pageNo);
        dispatch(getAllUsers(pageNo));
      })
      .catch((error) => {
        console.error("Failed to update user:", error);
      });

    setData((prevData) => ({ ...prevData, loading: true }));

    setTimeout(() => {
      setData((prevData) => ({ ...prevData, loading: false, visible: false }));
    }, 2000);
  };

  return (
    <>
      <button
        className="btn"
        type="button"
        data-original-title=""
        onClick={showModal}
      >
        <Tooltip title="Update User">
          <EditOutlined />
        </Tooltip>
      </button>
      <Modal
        open={data.visible}
        onCancel={handleModalCancel}
        title="Update User"
        style={{ top: 20 }}
        width={1200}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        {data?.role == "STORE_USER" ? (
          <>
            <Form
              // ref={formRef1}
              name="update-user"
              className="update-user"
              onFinish={onSubmit}
              initialValues={data}
            >
              <Row gutter={[8, 0]}>
                {console.log("data", data)}
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input
                      name="name"
                      value={data.name}
                      onChange={onChange}
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input
                      name="email"
                      type="email"
                      value={data.email}
                      placeholder="Enter Email"
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your  Mobile Number!",
                      },
                      {
                        min: 10,
                        message: "Mobile must be 10-digits!",
                      },
                      {
                        max: 10,
                        message: "Mobile must be 10-digits!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var hasAlphabets = /^[0-9]+$/;
                          if (
                            parseInt(getFieldValue("mobileNumber")) &&
                            hasAlphabets.test(getFieldValue("mobileNumber"))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Should contain numbers!");
                        },
                      }),
                    ]}
                  >
                    <Input
                      name="mobileNumber"
                      type="number"
                      placeholder="Enter Mobile Number"
                      value={data.mobileNumber}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Firm Name"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    validateStatus={
                      data.arefirmnamesavailable === false ? "error" : ""
                    }
                    help={
                      data.arefirmnamesavailable === false
                        ? "At least one firm name is required!"
                        : ""
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please Add Firm Name",
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      placeholder="Enter Firm Names"
                      value={data.firmnames}
                      onChange={(values) => handleFirmsChange(values)}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Role"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                  >
                    <Select
                      name="roleId"
                      disabled={userData?.role?.roleName == "STORE_USER"}
                      value={data.roleId}
                      // defaultValue={data.role}
                      onChange={handleChangeRole}
                      placeholder="Select Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {roleName === "ADMIN" && roleOptions}
                      {roleName === "SALES_ADMIN" && roleOptionsSalesAdmin}
                      {roleName === "SALES" && roleOptionsSalesAdmin}
                      {roleName === "PURCHASE_ADMIN" &&
                        roleOptionsPurchaseAdmin}
                      {roleName === "RECEPTION" && roleOptionReception}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Agent"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a agent",
                      },
                    ]}
                  >
                    <Select
                      name="agentId"
                      value={data?.agent}
                      onChange={handleChangeAgent}
                      placeholder="Select Agent"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {agentOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Aadhar Number"
                    name="aadharnumber"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        required: data?.GSTPANADH === 4 ? true : false,
                        message: "Aadhar No is required!",
                      },
                      {
                        min: 12,
                        message: "Aadhar No. must be 12-digits!",
                      },
                      {
                        max: 12,
                        message: "Aadhar No. must be 12-digits!",
                      },
                    ]}
                  >
                    <Input
                      name="aadharnumber"
                      placeholder="Enter Aadhar Number"
                      value={data.aadharnumber}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="GST/PAN/URD">
                    <Form.Item
                      label="GST/PAN/URD"
                      name="GSTPANADH"
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 15 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Card>
                        <Radio.Group
                          name="GSTPANADH"
                          id="GSTPANADH"
                          onChange={onChange}
                          value={data.GSTPANADH}
                        >
                          <Radio value={1}>GST</Radio>
                          <Radio value={3}>PAN</Radio>
                          <Radio value={4}>URD</Radio>
                        </Radio.Group>
                      </Card>
                    </Form.Item>
                  </Tooltip>
                </Col>

                {data.GSTPANADH === 1 && (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Tooltip title="GST No.">
                      <Form.Item
                        label="GST Number"
                        name="gst"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 15 }}
                        rules={[
                          {
                            required: data.GSTPANADH !== 4,
                            message: "Please enter GST/PAN No.",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const verificationStatus =
                                getFieldValue("GSTPANADH");
                              if (verificationStatus == 1) {
                                const gstRegex =
                                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
                                if (
                                  value?.length !== 15 ||
                                  !gstRegex.test(value)
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      "GST number must be like 22AAAAA0000A1ZR"
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              } else if (verificationStatus == 3) {
                                const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                if (
                                  value?.length !== 10 ||
                                  !panRegex.test(value)
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      "PAN number must be like ABCDE1234F"
                                    )
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input
                          name="gst"
                          placeholder="Enter GST/PAN Number"
                          value={data.gst}
                          onChange={onChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                )}

                {data.GSTPANADH === 3 && (
                  <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Tooltip title="PAN No.">
                      <Form.Item
                        label="PAN Number"
                        name="pannumber"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 15 }}
                        rules={[
                          {
                            required: data.GSTPANADH !== 4,
                            message: "Please enter PAN No.",
                          },
                          {
                            pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                            message: "PAN number must be like ABCDE1234F",
                          },
                        ]}
                      >
                        <Input
                          name="pannumber"
                          placeholder="Enter PAN Number"
                          value={data.pannumber}
                          onChange={onChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                )}
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Select DOB">
                    <Form.Item
                      name="dob"
                      label="DOB"
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 15 }}
                      rules={[
                        {
                          required: true,
                          message: "Please Select DOB !",
                        },
                      ]}
                    >
                      <DatePicker
                        // style={{ width: "100%" }}
                        onChange={(date, dateString) =>
                          setData((prevData) => ({
                            ...prevData,
                            dob: dateString,
                          }))
                        }
                        placeholder="Select DOB"
                        format={"YYYY-MM-DD"}
                        value={data.dob}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Tooltip title="Select DOB">
                    <Form.Item
                      name="note"
                      label="Note"
                      labelAlign="left"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 15 }}
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Note !",
                        },
                      ]}
                    >
                      <Input.TextArea
                        name="note"
                        autoSize={{
                          minRows: 4,
                          maxRows: 7,
                        }}
                        onChange={onChange}
                        value={data.note}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Discount (%)"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                  >
                    <Input
                      type="number"
                      name="discount"
                      placeholder="Enter Discount"
                      value={data.discount}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Address"
                    name="address"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      { required: true, message: "Please enter address" },
                    ]}
                  >
                    <Input
                      name="address"
                      placeholder="Enter Address"
                      value={data.address}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Pincode"
                    name="pincode"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your pincode!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const pincode = String(getFieldValue("pincode")); // Convert to string
                          var hasOnlyNumbers = /^[0-9]+$/; // Regular expression to check if it contains only numbers

                          // Check if pincode is 6 digits and numeric
                          if (
                            pincode.length === 6 &&
                            hasOnlyNumbers.test(pincode)
                          ) {
                            return Promise.resolve();
                          }
                          if (pincode.length !== 6) {
                            return Promise.reject(
                              "Pincode must be exactly 6 digits!"
                            );
                          }
                          return Promise.reject(
                            "Pincode should contain only numbers!"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Pincode"
                      onChange={onPincodeChange}
                      maxLength={6} // Limit input to 6 characters
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Region"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        required: true,
                        message: "Please select a region",
                      },
                    ]}
                  >
                    <Select
                      disabled={data.regionDisable}
                      name=" region"
                      value={data?.region}
                      placeholder="Select Region"
                      onChange={onSelectRegion}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {regionOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="City"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                  >
                    <Input
                      disabled
                      type="string"
                      name="city"
                      id="string"
                      value={data.city}
                      placeholder="City"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="State"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                  >
                    <Input
                      disabled
                      type="string"
                      name="state"
                      id="string"
                      value={data.state}
                      placeholder="State"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Transport"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                  >
                    <Input
                      placeholder="Enter Transport"
                      name="transport"
                      id="transport"
                      value={data.transport}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="USER Type"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                  >
                    <div className="border p-2 rounded-sm">
                      <Radio.Group
                        onChange={onCreditUserChange}
                        value={data.isCreditUser ? "true" : "false"}
                      >
                        <Radio className="font-weight-bold" value="true">
                          Credit User
                        </Radio>
                        <Radio className="font-weight-bold" value="false">
                          Cash User
                        </Radio>
                      </Radio.Group>
                    </div>
                  </Form.Item>
                </Col>

                {data?.isCreditUser && (
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        label="Credit Limit"
                        name="creditLimit"
                        labelAlign="left"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 15 }}
                      >
                        <Input
                          disabled={!data.isCreditUser ? true : false}
                          type="number"
                          name="creditLimit"
                          placeholder="Enter Credit Limit"
                          value={data.creditLimit}
                          onChange={onChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Credit Amount Used">
                        <Form.Item>
                          <Input
                            type="number"
                            name="creditAmount"
                            disabled
                            value={data.creditAmount.toFixed(2)}
                            placeholder="Credit Amount"
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </>
                )}
              </Row>

              <Form.Item className="float-right">
                <Button
                  className="close-modal mr-3"
                  onClick={handleModalCancel}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={data.loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <>
            <Form
              // ref={formRef2}
              name="update-user"
              className="update-user"
              onFinish={onSubmit}
              initialValues={data}
            >
              <Row gutter={16}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Name"
                    name="name"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      { required: true, message: "Please enter your name" },
                    ]}
                  >
                    <Input
                      name="name"
                      value={data.name}
                      onChange={onChange}
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      { required: true, message: "Please enter your email" },
                    ]}
                  >
                    <Input
                      name="email"
                      type="email"
                      value={data.email}
                      placeholder="Enter Email"
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        required: true,
                        message: "Please enter your  Mobile Number!",
                      },
                      {
                        min: 10,
                        message: "Mobile must be 10-digits!",
                      },
                      {
                        max: 10,
                        message: "Mobile must be 10-digits!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          var hasAlphabets = /^[0-9]+$/;
                          if (
                            parseInt(getFieldValue("mobileNumber")) &&
                            hasAlphabets.test(getFieldValue("mobileNumber"))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Should contain numbers!");
                        },
                      }),
                    ]}
                  >
                    <Input
                      name="mobileNumber"
                      type="number"
                      placeholder="Enter Mobile Number"
                      value={data.mobileNumber}
                      onChange={onChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Role"
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 15 }}
                  >
                    <Select
                      name="roleId"
                      value={data.roleId}
                      // defaultValue={data.role}
                      onChange={handleChangeRole}
                      placeholder="Select Role"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      showSearch
                    >
                      {roleName === "ADMIN" && roleOptions}
                      {roleName === "SALES_ADMIN" && roleOptionsSalesAdmin}
                      {roleName === "SALES" && roleOptionsSalesAdmin}
                      {roleName === "PURCHASE_ADMIN" &&
                        roleOptionsPurchaseAdmin}
                      {roleName === "RECEPTION" && roleOptionReception}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="float-right">
                <Button
                  className="close-modal mr-3"
                  onClick={handleModalCancel}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={data.loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
};

export default UpdateUserForm;
