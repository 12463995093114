import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { fetchAllProductsSidebarList, fetchProducts } from "../../redux/actions/productActions";
import { useLocation, useHistory } from "react-router-dom";

const ShopSales = ({ getSortParams, allClear, handleUncheckAll,getFilteredData }) => {
    const [salesFilter, setSalesFilter] = useState({
      on: localStorage.getItem("ecomSales") === "true", 
      off: localStorage.getItem("ecomSales") !== "true",
    });
    const dispatch = useDispatch();
    const location = useLocation();
    useEffect(() => {
      const storedSalesFilter = localStorage.getItem("ecomSales");
      if (storedSalesFilter) {
        setSalesFilter({
          on: storedSalesFilter === "true",
          off: storedSalesFilter !== "true",
        });
      }
    }, []); 
    const handleSales = async () => {
      const salesValue = localStorage.getItem("ecomSales") === "true" ? true : false;
      // setLoader(true);
      const params = new URLSearchParams(location.search);
      params.set("All", true); 
      await dispatch(fetchAllProductsSidebarList()); 
      await getFilteredData(params.toString(), 1,salesValue)
      // setLoader(false)
        // history.push({
        //   pathname: "/shop-grid-standard",
        //   search: params.toString(),

        // });
    };

    const handleChange = (key) => {
      const updatedFilter = {
        on: key === "on",
        off: key === "off",
      };
      setSalesFilter(updatedFilter);

      if (key === "on") {
        localStorage.setItem("ecomSales", "true");
      } else if (key === "off") {
        localStorage.removeItem("ecomSales"); 
      }

      handleSales(); 

      const selectedSales = Object.keys(updatedFilter)
        .filter((key) => updatedFilter[key])
        .map((key) => key.toUpperCase());
      
      getSortParams("sales", selectedSales);
    };

    useEffect(() => {
      if (allClear) {
        // Clear sales filter and local storage
        console.log("Clearing sales filter");
        localStorage.removeItem("ecomSales");
        setSalesFilter({ on: false, off: true }); 
        handleUncheckAll();
      }
    }, [allClear, handleUncheckAll]);
    
    return (
      <div className=" px-2 salesToggle d-flex justify-content-between">
        <h4 className="font-bold filtersHeading">SALES</h4>
        <div className="flex space-x-4 items-center salesToggle-inputs ">
          <Checkbox
            onChange={() => handleChange("on")}
            checked={salesFilter.on}
          >
            On
          </Checkbox>
          <Checkbox
            onChange={() => handleChange("off")}
            checked={salesFilter.off}
          >
            Off
          </Checkbox>
        </div>
      </div>
    );
};

ShopSales.propTypes = {
  getSortParams: PropTypes.func,
  allClear: PropTypes.bool,
  handleUncheckAll: PropTypes.func,
};

export default ShopSales;