import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Upload,
} from "antd";
import {
  ArrowLeftOutlined,
  BlockOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  StopOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getDealers } from "../../../../redux/actions/dealerAction";
import {
  getBrandBySearch,
  getBrands,
} from "../../../../redux/actions/brandAction";
import { getCategories } from "../../../../redux/actions/categoryAction";
import { getStyles } from "../../../../redux/actions/styleAction";
import { getGroups } from "../../../../redux/actions/groupAction";
import { getSections } from "../../../../redux/actions/sectionAction";
import { getLocations } from "../../../../redux/actions/locationAction";
import { getSizes } from "../../../../redux/actions/sizeAction";
import { getColors } from "../../../../redux/actions/colorAction";
import { getAgeGroups } from "../../../../redux/actions/ageGroupAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import { getLookupData } from "../../../../redux/actions/purchaseAction";
import { getTexture } from "../../../../redux/actions/textureAction";
import { getCustomers } from "../../../../redux/actions/customerAction";
import { getSeasons } from "../../../../redux/actions/seasonAction";
import moment from "moment";
import Column from "antd/lib/table/Column";
import { v4 as uuidv4 } from "uuid";
import { addPurchaseOrder } from "../../../../redux/actions/purchaseOrderAction";
import { addSingleGlobalEcomImage, deleteSingleGlobalImage } from "../../../../redux/actions/purchaseAction";
import { getRackById, getRacks } from "../../../../redux/actions/rackManagementAction";
const { Option } = Select;

function AddPurchaseOrder() {
  const dispatch = useDispatch();

  const [index, setIndex] = useState(0);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState();
  const [netAmount, setNetAmount] = useState();
  const [totalDiscount, setTotalDiscount] = useState();
  const [totalTax, setTotalTax] = useState(5);
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState();
  const [totalTaxedAmount, setTotalTaxedAmount] = useState();
  const [moqValidate, setMoqValidate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [quantityValidate, setQuantityValidate] = useState(false);
  const [imageLoading, setImageLoading] = useState(null);
  const [form, setForm] = useState({
    billNo: "",
    date: moment(),
    poDate: moment().add(1, "days"),
    expiryDate : moment().add(1, "days"),
    dealerId: "",
    dealerName: "",
    gst: "",
    discount: 0,
    netAmount: 0,
    purchasePersonId: "",
    purchasePersonName: "",
    totalQuantity: 0,
    tax: 0,
    totalAmount: 0,
    userId: "",
    totalBoxes: "",
    HSNCode: "",
    ageGroupId: "",
    ageGroupName: "",
    barcode: "",
    brandId: "",
    brandName: "",
    categoryId: "",
    categoryName: "",
    designNumber: "",
    floorId: "",
    floorName: "",
    groupId: "",
    groupName: "",
    locationName: "",
    locationId: "",
    markup: 0,
    others: 1,
    seasonId: "",
    seasonName: "",
    sectionId: "",
    styleId: "",
    styleName: "",
    textureId: [],
    textureName: [],
  });

  const [purchaseItem, setPurchaseItem] = useState([
    {
      id: uuidv4(),
      size: "",
      purchasePrice: "",
      MOQ: "",
      wsp: 0,
      salesTax: 5,
      imagePath: "",
      rack: '',
      column: '',
      colors: [{ colorId: uuidv4(), color: "", quantity: 0, boxes: 0 }],
    },
  ]);

  const dealerList = useSelector((state) => state.dealersData.dealers);
  const brandsList = useSelector((state) => state.brandsData.brands);
  const categoryList = useSelector((state) => state.categoryData.categories);
  const styleList = useSelector((state) => state.styleData.styles);
  const groupsList = useSelector((state) => state.groupData.groups);
  const seasonsList = useSelector((state) => state.seasonData.seasons);
  const sectionsList = useSelector((state) => state.sectionData.sections);
  const locationList = useSelector((state) => state.locationData.locations);
  const sizesList = useSelector((state) => state.sizeData.sizes);
  const colorsList = useSelector((state) => state.colorData.colors);
  const ageGroupList = useSelector((state) => state.ageGroupData.ageGroups);
  const floorsList = useSelector((state) => state.floorData.floors);
  const textureList = useSelector((state) => state.textureData.textures?.data);
  const customerList = useSelector((state) => state.customerData.salesPerson);
  const rackList = useSelector((e) => e.rackData?.rackList?.data)
  const rackByIdList = useSelector((e) => e.rackData?.racksByID?.data)


  useEffect(() => {
    dispatch(getDealers());
    dispatch(getCategories());
    dispatch(getBrands());
    dispatch(getGroups());
    dispatch(getSeasons());
    dispatch(getSections());
    dispatch(getLocations());
    dispatch(getSizes());
    dispatch(getColors());
    dispatch(getAgeGroups());
    dispatch(getFloors());
    dispatch(getTexture());
    dispatch(getRacks());
    dispatch(getCustomers("PURCHASER"));
  }, []);

  const calculateWSP = (componentId) => {
    setPurchaseItem((prevPurchaseItems) =>
      prevPurchaseItems?.map((item) => {
        if (item.id === componentId) {
          const Other = Number(form.others);
          const MarkUp = Number(form.markup || 0);
          const Price = Number(item.purchasePrice);

          const priceAfterMarkupCal = (Price * MarkUp) / 100;
          const calculateWSP = Price + priceAfterMarkupCal + Other;

          return {
            ...item,
            wsp: calculateWSP,
            salesTax: calculateWSP > 1000 ? 12 : 5,
          };
        }
        return item;
      })
    );
  };

  const calculateBoxes = (componentId) => {
    setPurchaseItem((prevPurchaseItems) =>
      prevPurchaseItems?.map((item) => {
        if (item.id === componentId) {
          const updatedColors = item.colors?.map((color) => {
            const quantity = Number(color.quantity);
            const MOQ = Number(item.MOQ);

            const boxes = MOQ > 0 ? Math.floor(quantity / MOQ) : 0;
            return {
              ...color,
              boxes,
            };
          });

          return {
            ...item,
            colors: updatedColors,
          };
        }
        return item;
      })
    );
  };

  // ------------------------------|| FORM METHODS ||----------------------------
  const handleDealerName = (value) => {
    const selectedDealer = dealerList.find((item) => item._id === value);
    if (selectedDealer) {
      setForm((prevState) => ({
        ...prevState,
        dealerId: selectedDealer._id,
        dealerName: selectedDealer.name,
        gst: selectedDealer.gst,
      }));
    }
  };

  const handleBillNumber = (e) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      billNo: value,
    }));
  };

  const handleDateChange = (date, dateString) => {
    if (date) {
      setForm((prevState) => ({
        ...prevState,
        date: moment(date),
      }));
    }
  };

  const handlePODateChange = (date, dateString) => {
    if (date) {
      setForm((prevState) => ({
        ...prevState,
        poDate: moment(date),
      }));
    }
  };
  const handleExpireyDateChange = (date, dateString) => {
    if (date) {
      setForm((prevState) => ({
        ...prevState,
        expiryDate: moment(date),
      }));
    }
  };

  const handleDesignNumber = (e) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      designNumber: value,
    }));
  };

  const handleBrand = (value) => {
    const selectedBrand = brandsList.find((item) => item._id === value);
    if (selectedBrand) {
      setForm((prevState) => ({
        ...prevState,
        brandId: selectedBrand._id,
        brandName: selectedBrand.name,
        markup: selectedBrand.markup,
      }));
    }
  };

  const handleMarkUpValue = (e) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      markup: value,
    }));
  };

  const handleCategory = async (value) => {
    await dispatch(getStyles(value));
    const selectedCategory = categoryList.find((item) => item._id === value);
    if (selectedCategory) {
      setForm((prevState) => ({
        ...prevState,
        categoryId: selectedCategory._id,
        categoryName: selectedCategory.name,
        styleId: "",
        styleName: "",
      }));
    }
  };

  const handleStyle = (value) => {
    const selectedStyle = styleList.find((item) => item._id === value);
    if (selectedStyle) {
      setForm((prevState) => ({
        ...prevState,
        styleId: selectedStyle._id,
        styleName: selectedStyle.name,
      }));
    }
  };

  const handleGroup = (value) => {
    const selectedGroup = groupsList.find((item) => item._id === value);
    if (selectedGroup) {
      setForm((prevState) => ({
        ...prevState,
        groupId: selectedGroup._id,
        groupName: selectedGroup.name,
      }));
    }
  };

  const handleSeason = (value) => {
    const selectedSeason = seasonsList.find((item) => item._id === value);
    if (selectedSeason) {
      setForm((prevState) => ({
        ...prevState,
        seasonId: selectedSeason._id,
        seasonName: selectedSeason.name,
      }));
    }
  };

  const handleSection = (value) => {
    const selectedSection = sectionsList.find((item) => item._id === value);
    if (selectedSection) {
      setForm((prevState) => ({
        ...prevState,
        sectionId: selectedSection._id,
        sectionName: selectedSection.name,
      }));
    }
  };

  const handleHSN = (e) => {
    const value = e.target.value;
    setForm((prevState) => ({
      ...prevState,
      HSNCode: value,
    }));
  };

  const handleOthers = (e) => {
    const value = e.target.value;

    setForm((prevState) => ({
      ...prevState,
      others: Number(value),
    }));
  };

  const handleLocation = (value) => {
    const selectedLocation = locationList.find((item) => item._id === value);
    if (selectedLocation) {
      setForm((prevState) => ({
        ...prevState,
        locationName: selectedLocation.name,
        locationId: selectedLocation._id,
      }));
    }
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    if (value === "" || value <= 0) {
      setForm((prevState) => ({
        ...prevState,
        discount: Number(0),
      }));
    } else {
      setForm((prevState) => ({
        ...prevState,
        discount: Math.max(Number(value)),
      }));
    }
  };

  const handleFloor = (value) => {
    const selectedFloor = floorsList.find((item) => item._id === value);
    if (selectedFloor) {
      setForm((prevState) => ({
        ...prevState,
        floorId: selectedFloor._id,
        floorName: selectedFloor.name,
      }));
    }
  };

  const handleTexture = (selectedValues) => {
    setForm((prevState) => ({
      ...prevState,
      textureId: selectedValues,
      textureName: selectedValues
        ?.map((value) => {
          const texture = textureList.find((item) => item._id === value);
          return texture ? texture.name : null;
        })
        .filter((name) => name !== null),
    }));
  };

  const handleAgeGroup = (value) => {
    const selectedAgeGroup = ageGroupList.find((item) => item._id === value);
    if (selectedAgeGroup) {
      setForm((prevState) => ({
        ...prevState,
        ageGroupId: selectedAgeGroup._id,
        ageGroupName: selectedAgeGroup.name,
      }));
    }
  };

  const handlePurchaser = (value) => {
    const selectedPurchaser = customerList.find((item) => item._id === value);
    if (selectedPurchaser) {
      setForm((prevState) => ({
        ...prevState,
        purchasePersonId: selectedPurchaser._id,
        purchasePersonName: selectedPurchaser.name,
      }));
    }
  };

  const handleSize = (value, componentId) => {
    const selectedSize = sizesList.find((item) => item._id === value);
    if (selectedSize) {
      setPurchaseItem((prevState) => {
        return prevState?.map((component) => {
          if (component.id === componentId) {
            return { ...component, size: selectedSize.name };
          }

          return component;
        });
      });
    }
  };

  const handleRack = (value, componentId) => {
    const selectedRack = rackList.find((item) => item._id === value);
    dispatch(getRackById({id: value}))
    if (selectedRack) {
      setPurchaseItem((prevState) => {
        return prevState?.map((component) => {
          if (component.id === componentId) {
            return { ...component, rack: selectedRack.rack };
          }

          return component;
        });
      });
    }
  };

  const handleColumn = (value, componentId) => {
    const selectedColumn = rackByIdList?.[0]?.columns?.find((item) => item._id === value);
    if (selectedColumn) {
      setPurchaseItem((prevState) => {
        return prevState?.map((component) => {
          if (component.id === componentId) {
            return { ...component, column: selectedColumn.column };
          }

          return component;
        });
      });
    }
  };

  const handleUploadChange = async (info, componentId) => {
    setImageLoading(true);
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      await dispatch(addSingleGlobalEcomImage(file)).then((response) => {
        if (response.status == 200) {
          let imageUrl = response?.data?.filePath || "";
          setPurchaseItem((prevState) => {
            return prevState?.map((component) => {
              if (component.id === componentId) {
                return { ...component, imagePath: imageUrl };
              }
              return component;
            });
          });
        }
      });
      setImageLoading(false);
    } else {
      setImageLoading(false);
    }
  };


  const handleDeleteImage = async (file) => {
    //
    // setImageLoading(false);
  };

  const handlePrice = (e, componentId) => {
    const value = e.target.value;

    setPurchaseItem((prevState) =>
      prevState?.map((component) => {
        if (component.id === componentId) {
          return { ...component, purchasePrice: value };
        }
        return component;
      })
    );

    calculateWSP(componentId);
  };

  const handleMOQ = (e, componentId) => {
    const value = e.target.value;
    setPurchaseItem((prevState) =>
      prevState?.map((component) =>
        component.id === componentId ? { ...component, MOQ: value } : component
      )
    );
    setTimeout(() => {
      calculateBoxes(componentId);
    }, 200);
  };

  const handleColor = (value, componentId, colorId) => {
    const selectedColor = colorsList.find((item) => item._id === value);
    if (selectedColor) {
      setPurchaseItem((prevState) =>
        prevState?.map((component) =>
          component.id === componentId
            ? {
              ...component,
              colors: component.colors?.map((color) =>
                color.colorId === colorId
                  ? { ...color, color: selectedColor.name }
                  : color
              ),
            }
            : component
        )
      );
    }
  };

  const handleQuantity = (e, componentId, colorId) => {
    const value = Number(e.target.value) || 0;

    if (value < 0) {
      // message.warning("Quantity should be greater than 0")
      setQuantityValidate(true);
    } else {
      setQuantityValidate(false);
    }

    setPurchaseItem((prevState) => {
      const updatedComponents = prevState?.map((component) =>
        component.id === componentId
          ? {
            ...component,
            colors: component.colors?.map((color) =>
              color.colorId === colorId
                ? { ...color, quantity: value }
                : color
            ),
          }
          : component
      );

      updatedComponents.forEach((updatedComponent) => {
        const componentMOQ = updatedComponent.MOQ || 1;
        const color = updatedComponent.colors.find(
          (color) => color.colorId === colorId
        );

        if (color) {
          const { quantity } = color;
          if (componentMOQ > 0 && quantity % componentMOQ !== 0) {
            setMoqValidate(true);
            // message.warning(`Quantity should be a multiple of MOQ (${componentMOQ}).`);
          } else {
            setMoqValidate(false);
          }
        }

        setTimeout(() => {
          calculateBoxes(componentId);
        }, 200);
      });

      return updatedComponents;
    });
  };

  // --------------------|| SIZE & COLOR COMPONENT LOGIC ||-------------------------
  const handleAddSize = () => {
    setPurchaseItem((prevState) => [
      ...prevState,
      {
        id: uuidv4(),
        size: "",
        purchasePrice: "",
        MOQ: "",
        imagePath: "",
        colors: [{ colorId: uuidv4(), color: "", quantity: "" }],
      },
    ]);
  };

  const handleAddColor = (componentId) => {
    setPurchaseItem((prevState) =>
      prevState?.map((component) =>
        component.id === componentId
          ? {
            ...component,
            colors: [
              ...component.colors,
              { colorId: uuidv4(), color: "", quantity: "" },
            ],
          }
          : component
      )
    );
  };

  const handleRemoveSize = (componentId) => {
    setPurchaseItem((prevState) =>
      prevState.filter((component) => component.id !== componentId)
    );
  };

  const handleRemoveColor = (componentId, colorId) => {
    setPurchaseItem((prevState) =>
      prevState?.map((component) =>
        component.id === componentId
          ? {
            ...component,
            colors: component.colors.filter(
              (color) => color.colorId !== colorId
            ), // Remove color by its unique id
          }
          : component
      )
    );
  };

  // -----------------------------|| CALCULATIONS OF PRODUCTS ||------------------------- ------
  const handleTotalDiscountChange = (e) => {
    let value = e.target.value;
    if (value > 100) {
      value = 100;
    } else if (value < 0 || value === "") {
      value = 0;
    }

    setTotalDiscount(value);
  };

  const handleTotalTaxChange = (e) => {
    let value = e.target.value;
    if (value > 100) {
      value = 100;
    } else if (value === "" || value < 0) {
      value = 0;
    }
    setTotalTax(value);
  };

  useEffect(() => {
    const calValue = (totalAmount * totalDiscount) / 100;
    const finalValue = totalAmount - calValue;
    setTotalDiscountedAmount(calValue);
    setFinalAmount(finalValue || totalAmount);

    const calValue2 = (finalAmount * totalTax) / 100;
    const finalValue2 = finalAmount + calValue2;
    setTotalTaxedAmount(calValue2);
    setNetAmount(finalValue2 || finalAmount || totalAmount);
  }, [finalAmount, totalTax, totalDiscount, totalQuantity, totalAmount]);

  //-----------------------------|| DELETE FUNCTION ||------------------------------------------
  const onDeleteClick = (id) => {
    const updatedItems = purchaseOrders.filter((item) => item.id !== id);
    setPurchaseOrders(updatedItems);

    if (updatedItems.length === 0) {
      setDisabled(false);
    }
  };

  //-----------------------------|| UPDATE FUNCTION ||------------------------------------------
  const onEditClick = (record) => {
    setPurchaseOrders((prevOrders) => {
      return prevOrders.filter((order) => {
        return !(
          order.dealerId === record.dealerId &&
          order.color === record.color &&
          order.MOQ === record.MOQ &&
          order.size === record.size &&
          order.purchasePrice === record.purchasePrice &&
          order.quantity === record.quantity &&
          order.rack === record.rack &&
          order.column === record.column
        );
      });
    });
    setForm((prevForm) => ({
      ...prevForm,
      dealerId: record.dealerId,
      billNo: record.billNo,
      designNumber: record.designNumber,
      date: moment(record.billDate, "DD/MM/YYYY"),
      poDate: moment(record.poDate, "DD/MM/YYYY"),
      expiryDate : moment(record.expiryDate, "DD/MM/YYYY"),
      brandId: record.brandId,
      categoryId: record.categoryId,
      floorId: record.floorId,
      ageGroupId: record.ageGroupId,
      groupId: record.groupId,
      styleId: record.styleId,
      seasonId: record.seasonId,
      sectionId: record.sectionId,
      locationName: record.locationName,
      locationId: record.locationId,
      HSNCode: record.HSNCode,
      gst: record.gst,
      dealerName: record.dealerName,
      brandName: record.brandName,
      markup: record.markup,
      categoryName: record.categoryName,
      groupName: record.groupName,
      styleName: record.styleName,
      floorName: record.floorName,
      ageGroupName: record.ageGroupName,
      seasonName: record.seasonName,
      sectionName: record.sectionName,
      purchasePersonId: record.purchasePersonId,
      purchasePersonName: record.purchasePersonName,
      textureId: record.textureId,
      textureName: record.textureName,
      discount: record.discount,
      others: record.others,
    }));

    setPurchaseItem([
      {
        id: uuidv4(),
        size: record.size,
        purchasePrice: record.purchasePrice,
        MOQ: record.MOQ,
        wsp: record.wsp,
        rack: record.rack,
        column: record.column,
        colors: [
          {
            colorId: uuidv4(),
            color: record.color,
            quantity: record.quantity,
            boxes: 0,
          },
        ],
      },
    ]);
  };

  // ----------------------------|| Form Submit Button ||---------------------------------------
  const handleFormSubmit = (event) => {
    if (form.others === 0 || form.others === "") {
      message.warning("Others should be greater than 0");
    } else if (moqValidate) {
      message.warning("Quantity should be a multiple of MOQ");
    } else if (form.dealerName === "") {
      message.warning("Enter Dealer's Name");
    } else if (form.locationName === "") {
      message.warning("Enter Location Name");
    } else if (form.purchasePersonName === "") {
      message.warning("Enter Purchaser Name");
    } else if (form.billNo === "") {
      message.warning("Enter Bill Number");
    } else if (form.categoryName && form.styleName === "") {
      message.warning("Style is Required");
    } else if (quantityValidate === true) {
      message.warning("Quantity should be greater than 0");
    } else {
      if (purchaseItem[0].wsp && form.dealerName) {
        const purchaseOrders = purchaseItem.flatMap((item) =>
          item.colors?.map((color) => ({
            id: uuidv4(),
            barcode: "",
            dealerId: form.dealerId,
            billNo: form.billNo,
            designNumber: form.designNumber,
            billDate: moment(form.date).format("DD/MM/YYYY"),
            poDate: moment(form.poDate).format("DD/MM/YYYY"),
            expiryDate : moment(form.expiryDate).format("DD/MM/YYYY"),
            brandId: form.brandId,
            categoryId: form.categoryId,
            floorId: form.floorId,
            ageGroupId: form.ageGroupId,
            groupId: form.groupId,
            styleId: form.styleId,
            seasonId: form.seasonId,
            sectionId: form.sectionId,
            locationName: form.locationName,
            locationId: form.locationId,
            HSNCode: form.HSNCode,
            MOQ: item.MOQ,
            size: item.size,
            purchasePrice: item.purchasePrice,
            color: color.color,
            rack: item.rack,
            column: item.column,
            quantity: color.quantity,
            others: form.others,
            discount: form.discount,
            wsp: item.wsp,
            salesTax: item.wsp > 1000 ? 12 : 5,
            gst: form.gst,
            dealerName: form.dealerName,
            brandName: form.brandName,
            markup: form.markup,
            categoryName: form.categoryName,
            groupName: form.groupName,
            styleName: form.styleName,
            floorName: form.floorName,
            ageGroupName: form.ageGroupName,
            seasonName: form.seasonName,
            sectionName: form.sectionName,
            purchasePersonId: form.purchasePersonId,
            purchasePersonName: form.purchasePersonName,
            textureId: form.textureId,
            textureName: form.textureName,
            discount: form.discount,
            imagePath: item.imagePath,
            amount:
              item.purchasePrice * color.quantity -
              (item.purchasePrice * color.quantity * Number(form.discount)) /
              100,
          }))
        );
        setDisabled(true);
        setPurchaseOrders((prevOrders) => [...prevOrders, ...purchaseOrders]);
        const Quantity = purchaseOrders.reduce((total, item) => {
          const itemQuantity = Number(item.quantity) || 0;
          return total + itemQuantity;
        }, 0);

        const Amount = purchaseOrders.reduce((total, item) => {
          const itemAmount = Number(item.amount) || 0;
          return total + itemAmount;
        }, 0);
        setTotalQuantity((prevQuantity) => Number(prevQuantity) + Quantity);
        setTotalAmount((prevAmount) => Number(prevAmount) + Amount);
        setPurchaseItem([
          {
            id: uuidv4(),
            discount: 0,
            size: "",
            purchasePrice: "",
            MOQ: "",
            wsp: 0,
            salesTax: 5,
            imagePath: "",
            rack: '',
            column: '',
            colors: [{ colorId: uuidv4(), color: "", quantity: "", boxes: 0 }],
          },
        ]);
      } else {
        message.warning("Please fill all the fields");
      }
    }
  };

  //-----------------------------------|| DISPATCH PRODUCTS ||------------------------------------------
  const submitAddPurchaseOrder = () => {
    let userId = localStorage.getItem("userId");

    if (purchaseOrders && purchaseOrders.length > 0) {
      const reqObj = {
        billNo: form.billNo,
        date: moment(form.date),
        poDate: moment(form.poDate),
        expiryDate : moment(form.expiryDate),
        dealerId: form.dealerId,
        dealerName: form.dealerName,
        discount: totalDiscount === undefined ? 0 : totalDiscount,
        isWarehouse: form.locationName === "WAREHOUSE D2" ? true : false,
        netAmount: netAmount,
        purchasePersonId: form.purchasePersonId,
        purchasePersonName: form.purchasePersonName,
        quantity: totalQuantity,
        tax: totalTax,
        totalAmount: totalAmount,
        userId: userId,
        purchaseOrders,
      };
      dispatch(addPurchaseOrder(reqObj))
        .then((respo) => {
          if (respo.status === 200) {
            message.success("PO Created Successfully");
          } else {
            message.error("Something Went Wrong");
          }
        })
        .catch((respo) => {
          message.success("Something Went Wrong");
        })
        .finally(() => {
          setTimeout(() => {
            // window.location.reload();
          }, 300);
        });
    } else {
      message.warning("Add the Product");
    }
  };

  return (
    <>
      {/* --------------------------------------|| PO Heading ||------------------------------- */}
      <Row gutter={24}>
        <Col sm={18} md={16} lg={12}>
          <h3>Add Purchase Order</h3>
        </Col>
        <Col sm={6} md={8} lg={12}>
          <Button
            type="primary"
            className="d-flex align-items-center float-right"
            icon={<ArrowLeftOutlined />}
          >
            {" "}
            Back
          </Button>
        </Col>
      </Row>

      {/* ----------------------------------|| Purchase Order Body ||-------------------------- */}
      <Card className="mt-3 rounded-md">
        <Form onFinish={handleFormSubmit}>
          <Row gutter={24}>
            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Dealer's Name">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Dealer!",
                    },
                  ]}
                  name="Dealer Name"
                >
                  <Select
                    showSearch
                    value={form.dealerName || undefined}
                    placeholder="Select Dealer"
                    onChange={handleDealerName}
                    disabled={disabled}
                    options={dealerList?.map((dealer) => ({
                      value: dealer._id,
                      label: dealer.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Bill Number">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Bill Number!",
                    },
                  ]}
                  name="Bill Number"
                >
                  <Input
                    value={form.billNo || undefined}
                    onChange={handleBillNumber}
                    placeholder="Enter Bill Number"
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Bill Date">
                <Form.Item>
                  <DatePicker value={form.date} onChange={handleDateChange} />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="PO Date">
                <Form.Item>
                  <DatePicker
                    value={form.poDate}
                    onChange={handlePODateChange}
                    disabledDate={(current) => {
                      return (
                        current <= moment(form.date, "DD/MM/YYYY") ||
                        current.isSame(moment(), "day")
                      );
                    }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter GST Number">
                <Form.Item
                // name="GstNumber"
                >
                  <Input
                    value={form.gst || undefined}
                    placeholder="Enter GST Number"
                    disabled
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Design Number">
                <Form.Item>
                  <Input
                    value={form.designNumber || undefined}
                    onChange={handleDesignNumber}
                    placeholder="Enter Design Number"
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Brand">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Brand!",
                    },
                  ]}
                  name="brandName"
                >
                  <Select
                    showSearch
                    placeholder="Select Brand"
                    value={form.brandName || undefined}
                    onChange={(value) => handleBrand(value)}
                    options={brandsList?.map((brands) => ({
                      value: brands._id,
                      label: brands.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Mark Up Value">
                <Form.Item>
                  <Input
                    value={form.markup || undefined}
                    onChange={handleMarkUpValue}
                    placeholder="Enter Mark Up Value"
                    type="number"
                    min={0}
                    max={100}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Category">
                <Form.Item>
                  <Select
                    showSearch
                    value={form.categoryName || undefined}
                    placeholder="Select Category"
                    onChange={(value) => handleCategory(value)}
                    options={categoryList?.map((category) => ({
                      value: category._id,
                      label: category.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Style">
                <Form.Item>
                  <Select
                    showSearch
                    value={form.styleName || undefined}
                    placeholder="Select Style"
                    onChange={(value) => handleStyle(value)}
                    onClick={() => {
                      if (styleList && styleList.length > 0) {
                        return;
                      } else {
                        message.warning("Select Category");
                      }
                    }}
                    options={styleList?.map((style) => ({
                      value: style._id,
                      label: style.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Group">
                <Form.Item>
                  <Select
                    showSearch
                    value={form.groupName || undefined}
                    placeholder="Select Group"
                    onChange={(value) => handleGroup(value)}
                    options={groupsList?.map((group) => ({
                      value: group._id,
                      label: group.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Season">
                <Form.Item>
                  <Select
                    showSearch
                    value={form.seasonName || undefined}
                    placeholder="Select Season"
                    onChange={(value) => handleSeason(value)}
                    options={seasonsList?.map((season) => ({
                      value: season._id,
                      label: season.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Section">
                <Form.Item>
                  <Select
                    showSearch
                    value={form.sectionName || undefined}
                    placeholder="Select Section"
                    onChange={(value) => handleSection(value)}
                    options={sectionsList?.map((section) => ({
                      value: section._id,
                      label: section.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter HSN Code">
                <Form.Item>
                  <Input
                    value={form.HSNCode || undefined}
                    onChange={handleHSN}
                    placeholder="Enter HSN Code"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Others">
                <Form.Item>
                  <Input
                    min={0}
                    value={form.others}
                    onChange={handleOthers}
                    placeholder="Enter Others"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Location">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Location!",
                    },
                  ]}
                  name="location"
                >
                  <Select
                    showSearch
                    value={form.locationName || undefined}
                    placeholder="Select Location"
                    onChange={(value) => handleLocation(value)}
                    options={locationList?.map((location) => ({
                      value: location._id,
                      label: location.name,
                    }))}
                    disabled={disabled}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Discount">
                <Form.Item>
                  <Input
                    min={0}
                    value={form.discount || undefined}
                    addonBefore="%"
                    type="number"
                    placeholder="Enter Discount (%)"
                    name="discount"
                    onChange={handleDiscountChange}
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Floor">
                <Form.Item>
                  <Select
                    value={form.floorName || undefined}
                    showSearch
                    placeholder="Select Floor"
                    onChange={(value) => handleFloor(value)}
                    options={floorsList?.map((floor) => ({
                      value: floor._id,
                      label: floor.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Texture">
                <Form.Item>
                  <Select
                    value={form.textureId || []}
                    showSearch
                    mode="multiple"
                    placeholder="Select Texture"
                    onChange={handleTexture}
                    options={textureList?.map((texture) => ({
                      value: texture._id,
                      label: texture.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Age Group">
                <Form.Item>
                  <Select
                    value={form.ageGroupName || undefined}
                    showSearch
                    placeholder="Select Age Group"
                    onChange={(value) => handleAgeGroup(value)}
                    options={ageGroupList?.map((ageGroup) => ({
                      value: ageGroup._id,
                      label: ageGroup.name,
                    }))}
                  />
                </Form.Item>
              </Tooltip>
            </Col>

            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Enter Purchaser">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Purchaser!",
                    },
                  ]}
                  name="purchaser name"
                >
                  <Select
                    showSearch
                    value={form.purchasePersonName || undefined}
                    placeholder="Select Purchaser"
                    onChange={(value) => handlePurchaser(value)}
                    options={customerList?.map((customer) => ({
                      value: customer._id,
                      label: customer.name,
                    }))}
                    disabled={disabled}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col className="w-100" sm={12} md={6} lg={6}>
              <Tooltip title="Expiry Date">
                <Form.Item>
                  <DatePicker
                    value={form.expiryDate}
                    onChange={handleExpireyDateChange}
                    disabledDate={(current) => {
                      return (
                        current <= moment(form.date, "DD/MM/YYYY") ||
                        current.isSame(moment(), "day")
                      );
                    }}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <div className="w-100" style={{ marginInline: "12px" }}>
              {purchaseItem.map((component, componentIndex) => (
                <div key={component.id}>
                  <Row gutter={24}>
                    <Col sm={2} md={2} lg={1}>
                      <Tooltip>
                        <Form.Item>
                          {componentIndex === 0 ? (
                            <CheckCircleOutlined style={{ fontSize: "20px" }} />
                          ) : (
                            <CloseCircleOutlined
                              style={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => handleRemoveSize(component.id)}
                            />
                          )}
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col sm={22} md={6} lg={5} className="w-100">
                      <Tooltip title="Enter Size">
                        <Form.Item
                          name={`size_${component.id}`}
                          rules={[
                            { required: true, message: "Please select a size" },
                          ]}
                        >
                          <Select
                            showSearch
                            value={component.size || undefined}
                            placeholder="Select Size"
                            onChange={(value) =>
                              handleSize(value, component.id)
                            }
                            options={sizesList.map((size) => ({
                              value: size._id,
                              label: size.name,
                            }))}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col className="w-100" sm={12} md={6} lg={5}>
                      <Tooltip title="Enter Price">
                        <Form.Item
                          name={`price_${component.id}`}
                          rules={[
                            { required: true, message: "Please enter a price" },
                          ]}
                        >
                          <Input
                            min={0}
                            value={component.purchasePrice || undefined}
                            onChange={(e) => handlePrice(e, component.id)}
                            placeholder="Enter Price"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col className="w-100" sm={12} md={6} lg={5}>
                      <Tooltip title="Enter MOQ">
                        <Form.Item
                          name={`moq_${component.id}`}
                          rules={[
                            { required: true, message: "Please enter MOQ" },
                          ]}
                        >
                          <Input
                            min={0}
                            value={component.MOQ || undefined}
                            onChange={(e) => handleMOQ(e, component.id)}
                            placeholder="Enter MOQ"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col className="w-100" sm={12} md={6} lg={4}>
                      <Tooltip title="Enter WSP">
                        <Form.Item name={`wsp_${component.id}`}>
                          <Input
                            value={component.wsp || undefined}
                            placeholder="Enter WSP"
                            type="number"
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col className="w-100" sm={12} md={6} lg={4}>
                      <Tooltip title="Upload Image">
                        <Form.Item
                          name={`image_${component.id}`}
                          valuePropName="fileList"
                          getValueFromEvent={(e) =>
                            Array.isArray(e) ? e : e?.fileList
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <Upload
                            loading={imageLoading}
                            maxCount={1}
                            accept="image/*"
                            beforeUpload={() => false}
                            listType="picture"
                            onChange={(file) =>
                              handleUploadChange(file, component.id)
                            }
                            onRemove={(file) => handleDeleteImage(file)}
                          >
                            <Button icon={<UploadOutlined />}>
                              Upload Image
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col className="w-100" sm={12} md={6} lg={2}>
                    </Col>
                    <Col className="w-100" sm={12} md={6} lg={10}>
                      <Tooltip title="Select Rack">
                        <Form.Item
                          name={`rack${component.id}`}
                          rules={[
                            { required: true, message: "Please enter Rack" },
                          ]}
                        >
                          <Select
                            showSearch
                            value={component?.rack || undefined}
                            placeholder="Select Rack"
                            onChange={(value) =>
                              handleRack(value, component.id)
                            }
                            options={rackList?.map((rack) => ({
                              value: rack._id,
                              label: rack.rack,
                            }))}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col className="w-100" sm={12} md={6} lg={10}>
                      <Tooltip title="Select Rack Column">
                        <Form.Item
                          name={`rack_column_${component.id}`}
                          rules={[
                            { required: true, message: "Please enter Column" },
                          ]}
                        >
                          <Select
                            showSearch
                            value={component?.column || undefined}
                            placeholder="Select Rack Column"
                            onChange={(value) =>
                              handleColumn(value, component.id)
                            }
                            options={rackByIdList?.[0]?.columns?.map((column) => ({
                              value: column._id,
                              label: column.column,
                            }))}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col className="w-100" sm={12} md={6} lg={2}>
                    </Col>
                  </Row>

                  {component.colors?.map((color, colorIndex) => (
                    <Row gutter={24} key={color.colorId}>
                      <Col
                        sm={2}
                        md={2}
                        lg={3}
                        className="d-flex justify-content-center"
                      >
                        <Tooltip title="">
                          <Form.Item>
                            {colorIndex === 0 ? (
                              <CheckCircleOutlined
                                style={{ fontSize: "20px" }}
                              />
                            ) : (
                              <CloseCircleOutlined
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                onClick={() =>
                                  handleRemoveColor(component.id, color.colorId)
                                }
                              />
                            )}
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col sm={22} md={6} lg={7} className="w-100">
                        <Tooltip title="Enter Color">
                          <Form.Item
                            name={`color_${component.id}_${color.colorId}`}
                            rules={[
                              {
                                required: true,
                                message: "Please select a color",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Color"
                              value={color.color || undefined}
                              onChange={(value) =>
                                handleColor(value, component.id, color.colorId)
                              }
                              options={colorsList?.map((color) => ({
                                value: color._id,
                                label: color.name,
                              }))}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col className="w-100" sm={12} md={6} lg={6}>
                        <Tooltip title="Enter Quantity">
                          <Form.Item
                            name={`quantity_${component.id}_${color.colorId}`}
                            rules={[
                              {
                                required: true,
                                message: "Please enter quantity",
                              },
                            ]}
                          >
                            <Input
                              min={0}
                              type="number"
                              value={color.quantity || undefined}
                              onChange={(e) =>
                                handleQuantity(e, component.id, color.colorId)
                              }
                              placeholder="Quantity"
                              onWheel={(e) => e.target.blur()}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col className="w-100" sm={12} md={6} lg={6}>
                        <Tooltip title="Enter Boxes">
                          <Form.Item
                            name={`boxes_${component.id}_${color.colorId}`}
                          >
                            <Input
                              value={color.boxes || undefined}
                              disabled
                              placeholder="Boxes"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  ))}

                  <Col sm={12} md={12} lg={6} className="m-auto">
                    <Form.Item>
                      <Button
                        type="primary"
                        className="w-100"
                        onClick={() => handleAddColor(component.id)}
                      >
                        ADD COLOR
                      </Button>
                    </Form.Item>
                  </Col>
                </div>
              ))}

              {/* SIZE BUTTON */}
              <Col sm={12} md={12} lg={6} className="m-auto">
                <Form.Item>
                  <Button
                    type="primary"
                    className="w-100"
                    onClick={handleAddSize}
                  >
                    ADD SIZE
                  </Button>
                </Form.Item>
              </Col>
            </div>

            {/* -------------------------|| ADD ITEM TO THE LIST BUTTON ||------------------------------- */}
            <Col sm={12} md={24} lg={24}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="w-100 btn btn-warning text-white d-flex align-items-center justify-content-center"
                >
                  Add Items to List
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* ------------------------------|| Table for Product ||---------------------------- */}
        <Table
          dataSource={purchaseOrders || []}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
          className="my-4"
        >
          {" "}
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Design No."
            dataIndex="designNumber"
            key="designNumber"
            width={120}
          />
          <Column
            title="Brand"
            dataIndex="brandName"
            key="brandName"
            width={110}
          />
          <Column title="Size" dataIndex="size" key="size" width={80} />
          <Column title="Color" dataIndex="color" key="color" width={80} />
          <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
          <Column
            title="Price"
            dataIndex="purchasePrice"
            key="price"
            width={80}
          />
          <Column title="Qty" dataIndex="quantity" key="quantity" width={80} />
          <Column
            title="Disc(in %)"
            dataIndex="discount"
            key="discount"
            width={100}
          />
          <Column
            title="Disc(in Rs)"
            dataIndex="discount"
            key="discount"
            render={(value, item, index) =>
              (Number(item.purchasePrice) *
                Number(item.quantity) *
                Number(item.discount)) /
              100
            }
            width={100}
          />
          <Column title="Amt" dataIndex="amount" key="amount" width={100} />
          <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
          <Column
            title="Action"
            key="action"
            width={80}
            render={(text, record) => (
              <Row gutter={[8, 0]}>
                {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Popconfirm
                                        title="Are you sure to update this Record?"
                                        onConfirm={() => onEditClick(record)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Tooltip title="Update Record">
                                            <EditOutlined />
                                        </Tooltip>
                                    </Popconfirm>
                                </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Popconfirm
                    title="Are you sure to delete this Record?"
                    onConfirm={() => onDeleteClick(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Delete Record">
                      <DeleteOutlined />
                    </Tooltip>
                  </Popconfirm>
                </Col>
              </Row>
            )}
          />
        </Table>

        {/* <Form className='mt-4'> */}
        <Row gutter={24}>
          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Total Item Quantity">
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Total Item Quantity"
                  name="totalQuantity"
                  value={totalQuantity}
                  disabled
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Total Amount">
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Total Amount"
                  name="totalAmount"
                  value={totalAmount}
                  disabled
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Total Discount">
              <Form.Item>
                <Input
                  min={0}
                  max={100}
                  addonBefore="%"
                  type="number"
                  placeholder="Total Discount (%)"
                  name="totalDiscount"
                  onChange={handleTotalDiscountChange}
                  value={totalDiscount}
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Discounted Amount">
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Discounted Amount"
                  name="discountedAmount"
                  value={totalDiscountedAmount}
                  disabled
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Final Amount">
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Final Amount"
                  name="finalAmount"
                  value={finalAmount}
                  disabled
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Enter Tax">
              <Form.Item>
                <Input
                  min={0}
                  max={100}
                  value={totalTax}
                  addonBefore="%"
                  type="number"
                  placeholder="Enter Tax %"
                  name="enterTax"
                  onChange={handleTotalTaxChange}
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Enter Taxed Amount">
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Enter Taxed Amount"
                  name="taxedAmount"
                  value={totalTaxedAmount}
                  disabled
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          <Col className="w-100" sm={12} md={6} lg={6}>
            <Tooltip title="Net Amount">
              <Form.Item>
                <Input
                  type="number"
                  placeholder="Net Amount"
                  name="netAmount"
                  value={netAmount}
                  disabled
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
            </Tooltip>
          </Col>

          {/* -------------------------|| SAVE BUTTON ||------------------------------- */}
          <Col sm={12} md={24} lg={24}>
            <Form.Item>
              <Button
                style={{
                  backgroundColor: "#0060aa",
                }}
                onClick={submitAddPurchaseOrder}
                type="submit"
                className="w-100 text-white d-flex align-items-center justify-content-center"
                disabled={
                  purchaseOrders && purchaseOrders.length > 0 ? false : true
                }
              >
                {purchaseOrders && purchaseOrders.length > 0 ? (
                  <SaveOutlined />
                ) : (
                  <StopOutlined />
                )}
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {/* </Form> */}
      </Card>
    </>
  );
}

export default AddPurchaseOrder;
