import React, { useState } from "react";
import { Row, Col, Modal, Button, Form, Input, Table } from "antd";
const { Column } = Table;
const ShowInvoiceLR = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const showModal = () => {
    setVisible(!visible);
  };
  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={() => showModal()}
      >
        Invoices
      </Button>
      <Row>
        <Col span={24}>
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={showModal}
              title="View Invoices"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Table
                dataSource={data?.invoiceId || []}
                bordered="true"
                size="middle"
                pagination={false}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Invoice Id"
                  dataIndex="invoiceId"
                  key="invoiceId"
                  render={(value, item, index) => item}
                />
              </Table>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ShowInvoiceLR;
