import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import HeaderOne from "../../../wrappers/header/HeaderOne";
import BrandImage from "../../../assets/images/EMPIRE.png";
import ArrowLeft from "../../../assets/images/Group 116.png";
import "./TopBrands.css";
import { useDispatch, useSelector } from "react-redux";
import { getOnBrandEcom } from "../../../redux/actions/brandAction";
import { ArrowLeftOutlined } from "@ant-design/icons";

// const brands = [
//   { name: "Empire", logo: "Logo_Empire" },
//   { name: "Empire", logo: "Logo_Empire" },
//   { name: "Empire", logo: "Logo_Empire" },
// ];

const TopBrands = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brandsData?.ecomBrands);
  console.log("getBrands-------------------->", brands)

  useEffect(() => {
    const fetchBrands = async () => {
      const resp = await dispatch(getOnBrandEcom());
      console.log("Brands Response:", resp);
    };
    fetchBrands();
  }, [dispatch]);

  return (
    <>
        <div className="breadcrumb-area shadow-sm mb-4">
          <div className="p-0">
            <div className="breadcrumb-content mx-2 d-flex align-items-center justify-content-start">
              <Link to="/">
                <ArrowLeftOutlined className="text-white"/>
              </Link>
              <div className="w-100 breadCrumb-Text ml-3 text-center">
                Top Brands
              </div>
            </div>
          </div>
        </div>

      <div className="brandmobile-page">
        <div className="brandmobile-grid">
          {brands?.map((brand, index) => (
            <div key={index} className="mx-2">
              <div className="brandmobile-card">
                <img
                  style={{ width: "90px", height: "35px" }}
                  src={brand.image}
                  alt={brand.name}
                />
              </div>
              <div className="brandmobile-name">{brand.name}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TopBrands;
