import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Upload,
    Button,
    Form,
    Input,
    Select,
    Modal,
    Tooltip,
    message,
    Card
} from "antd";
import moment from "moment";
import { EditOutlined , UploadOutlined} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getDealers } from '../../../../../redux/actions/dealerAction';
import { getBrandBySearch, getBrands } from '../../../../../redux/actions/brandAction';
import { getCategories } from '../../../../../redux/actions/categoryAction';
import { getStyles } from '../../../../../redux/actions/styleAction';
import { getGroups } from '../../../../../redux/actions/groupAction';
import { getSections } from '../../../../../redux/actions/sectionAction';
import { getLocations } from '../../../../../redux/actions/locationAction';
import { getSizes } from '../../../../../redux/actions/sizeAction';
import { getColors } from '../../../../../redux/actions/colorAction';
import { getAgeGroups } from '../../../../../redux/actions/ageGroupAction';
import { getFloors } from '../../../../../redux/actions/floorAction';
import { getLookupData } from '../../../../../redux/actions/purchaseAction';
import { getTexture } from '../../../../../redux/actions/textureAction';
import { getCustomers } from '../../../../../redux/actions/customerAction';
import { getSeasons } from '../../../../../redux/actions/seasonAction';
import { addSingleGlobalEcomImage } from "../../../../../redux/actions/purchaseAction";
const dateFormat = "DD/MM/YYYY";
const { Option } = Select;


function UpdatePurchaseOrder({ data, updatedData }) {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [boxes, setBoxes] = useState(data.quantity / data.MOQ);
    const [moqValidate, setMoqValidate] = useState(false);
    const [form, setForm] = useState({
        _id: data._id,
        dealerName: data.dealerName,
        dealerId: data.dealerId,
        billNo: data.billNo,
        billDate: data.billDate,
        gst: data.gst,
        designNumber: data.designNumber,
        brand: data.brand,
        brandId: data.brandId,
        markup: data.markup,
        category: data.category,
        categoryId: data.categoryId,
        group: data.group,
        groupId: data.groupId,
        purchasePersonId: data.purchasePersonId,
        purchasePersonName: data.purchasePersonName,
        style: data.style,
        styleId: data.styleId,
        season: data.season,
        seasonId: data.seasonId,
        section: data.section,
        sectionId: data.sectionId,
        location: data.location || "",
        locationId: data.locationId,
        floorId: data.floorId,
        floorName: data.floorName,
        ageGroupId: data.ageGroupId,
        ageGroupName: data.ageGroupName,
        HSNCode: data.HSNCode,
        MOQ: data.MOQ,
        size: data.size,
        purchasePrice: data.purchasePrice,
        color: data.color,
        quantity: data.quantity,
        wsp: data.wsp,
        others: data.others,
        discount: data.discount,
        totalBox: data.totalBox,
        barcode: data.barcode,
        poId: data.poId,
        textureId: data.textureId,
        textureName: data.textureName,
        isPurchaseCompleted: data.isPurchaseCompleted,
        created_at: data.created_at,
        __v: data.__v,
        imagePath : data?.imagePath
    });

    useEffect(() => {
        setForm({
            _id: data._id,
            dealerName: data.dealerName,
            dealerId: data.dealerId,
            billNo: data.billNo,
            billDate: data.billDate,
            gst: data.gst,
            designNumber: data.designNumber,
            brand: data.brand,
            brandId: data.brandId,
            markup: data.markup,
            category: data.category,
            categoryId: data.categoryId,
            group: data.group,
            groupId: data.groupId,
            purchasePersonId: data.purchasePersonId,
            purchasePersonName: data.purchasePersonName,
            style: data.style,
            styleId: data.styleId,
            season: data.season,
            seasonId: data.seasonId,
            section: data.section,
            sectionId: data.sectionId,
            location: data.location || "",
            locationId: data.locationId,
            floorId: data.floorId,
            floorName: data.floorName,
            ageGroupId: data.ageGroupId,
            ageGroupName: data.ageGroupName,
            HSNCode: data.HSNCode,
            MOQ: data.MOQ,
            size: data.size,
            purchasePrice: data.purchasePrice,
            color: data.color,
            quantity: data.quantity,
            wsp: data.wsp,
            others: data.others,
            discount: data.discount,
            totalBox: data.totalBox,
            barcode: data.barcode,
            poId: data.poId,
            textureId: data.textureId,
            textureName: data.textureName,
            isPurchaseCompleted: data.isPurchaseCompleted,
            created_at: data.created_at,
            __v: data.__v,
            imagePath : data?.imagePath
        });
        setBoxes(data.quantity / data.MOQ);
    }, [data]);


    useEffect(() => {
        dispatch(getDealers())
        dispatch(getCategories())
        dispatch(getBrands())
        dispatch(getGroups())
        dispatch(getSeasons())
        dispatch(getSections())
        dispatch(getLocations())
        dispatch(getSizes())
        dispatch(getColors())
        dispatch(getAgeGroups())
        dispatch(getFloors())
        dispatch(getTexture())
        dispatch(getCustomers("PURCHASER"))
    }, []);

    useEffect(() => {
        const Quantity = form.quantity
        const MOQ = form.MOQ

        const value = Quantity / MOQ;
        setBoxes(value)
    }, [data, form.quantity, form.MOQ])


    useEffect(() => {
        const Other = form.others
        const MarkUp = form.markup
        // const Quantity = form.quantity
        const Price = form.purchasePrice


        const priceAfterMarkupCal = (Price * MarkUp) / 100;
        const calculateWSP = Price + priceAfterMarkupCal + Other;
        setForm((prevState) => ({
            ...prevState,
            wsp: calculateWSP
        }))
    }, [form.others, form.markup, form.purchasePrice])

    const brandsList = useSelector((state) => state.brandsData.brands)
    const categoryList = useSelector((state) => state.categoryData.categories)
    const styleList = useSelector((state) => state.styleData.styles)
    const groupsList = useSelector((state) => state.groupData.groups)
    const seasonsList = useSelector((state) => state.seasonData.seasons)
    const sectionsList = useSelector((state) => state.sectionData.sections)
    const locationList = useSelector((state) => state.locationData.locations)
    const sizesList = useSelector((state) => state.sizeData.sizes)
    const colorsList = useSelector((state) => state.colorData.colors)
    const ageGroupList = useSelector((state) => state.ageGroupData.ageGroups)
    const floorsList = useSelector((state) => state.floorData.floors)
    const textureList = useSelector((state) => state.textureData.textures?.data)


    const showModal = () => {
        setVisible(true)
    }

    const handleModalCancel = () => {
        setForm({
            _id: data._id,
            dealerName: data.dealerName,
            dealerId: data.dealerId,
            billNo: data.billNo,
            billDate: data.billDate,
            gst: data.gst,
            designNumber: data.designNumber,
            brand: data.brand,
            brandId: data.brandId,
            markup: data.markup,
            category: data.category,
            categoryId: data.categoryId,
            group: data.group,
            groupId: data.groupId,
            purchasePersonId: data.purchasePersonId,
            purchasePersonName: data.purchasePersonName,
            style: data.style,
            styleId: data.styleId,
            season: data.season,
            seasonId: data.seasonId,
            section: data.section,
            sectionId: data.sectionId,
            location: data.location || "",
            locationId: data.locationId,
            floorId: data.floorId,
            floorName: data.floorName,
            ageGroupId: data.ageGroupId,
            ageGroupName: data.ageGroupName,
            HSNCode: data.HSNCode,
            MOQ: data.MOQ,
            size: data.size,
            purchasePrice: data.purchasePrice,
            color: data.color,
            quantity: data.quantity,
            wsp: data.wsp,
            others: data.others,
            discount: data.discount,
            totalBox: data.totalBox,
            barcode: data.barcode,
            poId: data.poId,
            textureId: data.textureId.map((value) => value),
            textureName: data.textureName.map((value) => value),
            isPurchaseCompleted: data.isPurchaseCompleted,
            created_at: data.created_at,
            __v: data.__v
        });
        setBoxes(data.quantity / data.MOQ);
        setVisible(false)
    }

    const handleDesignNumber = (e) => {
        const value = e.target.value;
        setForm((prevState) => ({
            ...prevState,
            designNumber: value
        }));
    }

    const handleBrand = (value) => {
        const selectedBrand = brandsList.find((item) => item._id === value);
        if (selectedBrand) {
            setForm((prevState) => ({
                ...prevState,
                brandId: selectedBrand._id,
                brand: selectedBrand.name,
                markup: selectedBrand.markup
            }));
        }
    }

    const handleMarkUpValue = (e) => {
        const value = e.target.value
        setForm((prevState) => ({
            ...prevState,
            markup: value
        }))
    }
    const handleCategory = async (value) => {
        await dispatch(getStyles(value))
        const selectedCategory = categoryList.find((item) => item._id === value);
        if (selectedCategory) {
            setForm((prevState) => ({
                ...prevState,
                categoryId: selectedCategory._id,
                category: selectedCategory.name,
                styleId: "",
                style: "",
            }));
        }
    }

    const handleStyle = (value) => {
        const selectedStyle = styleList.find((item) => item._id === value);
        if (selectedStyle) {
            setForm((prevState) => ({
                ...prevState,
                styleId: selectedStyle._id,
                style: selectedStyle.name,
            }));
        }
    }

    const handleGroup = (value) => {
        const selectedGroup = groupsList.find((item) => item._id === value);
        if (selectedGroup) {
            setForm((prevState) => ({
                ...prevState,
                groupId: selectedGroup._id,
                group: selectedGroup.name,
            }));
        }
    }

    const handleSeason = (value) => {
        const selectedSeason = seasonsList.find((item) => item._id === value);
        if (selectedSeason) {
            setForm((prevState) => ({
                ...prevState,
                seasonId: selectedSeason._id,
                season: selectedSeason.name,
            }));
        }
    }

    const handleSection = (value) => {
        const selectedSection = sectionsList.find((item) => item._id === value);
        if (selectedSection) {
            setForm((prevState) => ({
                ...prevState,
                sectionId: selectedSection._id,
                section: selectedSection.name,
            }));
        }
    }

    const handleHSN = (e) => {
        const value = e.target.value
        setForm((prevState) => ({
            ...prevState,
            HSNCode: value
        }))
    }

    const handleOthers = (e) => {
        const value = e.target.value
        setForm((prevState) => ({
            ...prevState,
            others: Number(value)
        }))
    }

    const handleLocation = (value) => {
        const selectedLocation = locationList.find((item) => item._id === value);
        if (selectedLocation) {
            setForm((prevState) => ({
                ...prevState,
                location: selectedLocation.name,
                locationId: selectedLocation._id,
            }));
        }
    }

    const handleDiscountChange = (e) => {
        const value = e.target.value;
        if (value === "") {
            setForm((prevState) => ({
                ...prevState,
                discount: 0
            }));
        } else {
            setForm((prevState) => ({
                ...prevState,
                discount: Number(value)
            }));
        }
    };

    const handleFloor = (value) => {
        const selectedFloor = floorsList.find((item) => item._id === value);
        if (selectedFloor) {
            setForm((prevState) => ({
                ...prevState,
                floorId: selectedFloor._id,
                floorName: selectedFloor.name,
            }));
        }
    }

    const getTextureNameById = (id) => {
        const texture = textureList?.find((option) => option._id === id);
        return texture ? texture.name : null;
    };
    const handleTexture = (values) => {
        console.log(values)
        const finalTextureIds = [];
        const finalTextureNames = [];

        values.forEach((item) => {
            const texname = getTextureNameById(item);
            if (texname) {
                finalTextureIds.push(item);
                finalTextureNames.push(texname);
            }
        });

        setForm((prevState) => ({
            ...prevState,
            textureId: finalTextureIds,
            textureName: finalTextureNames,
        }));
    };


    const handleAgeGroup = (value) => {
        const selectedAgeGroup = ageGroupList.find((item) => item._id === value);
        if (selectedAgeGroup) {
            setForm((prevState) => ({
                ...prevState,
                ageGroupId: selectedAgeGroup._id,
                ageGroupName: selectedAgeGroup.name,
            }));
        }
    }

    const handleSize = (value) => {
        const selectedSize = sizesList.find((item) => item._id === value);
        if (selectedSize) {
            setForm((prevState) =>
            ({
                ...prevState,
                size: selectedSize.name,
            })
            );
        }
    };

    const handlePrice = (e) => {
        const value = Number(e.target.value);
        if (value !== undefined && value !== null) {
            setForm((prevState) =>
            ({
                ...prevState,
                purchasePrice: value,
            })
            );
        }
        // calculateWSP(componentId);
    };

    const handleColor = (value) => {
        const selectedColor = colorsList.find((item) => item._id === value);
        if (selectedColor) {
            setForm((prevState) =>
            ({
                ...prevState,
                color: selectedColor.name,
            })
            );
        }
    };

    const handleMOQ = (e, componentId) => {
        const value = Number(e.target.value);
        if (value !== undefined && value !== null) {
            setForm((prevState) =>
            ({
                ...prevState,
                MOQ: value,
            })
            );
        }
    };

    const handleQuantity = (e) => {
        const value = Number(e.target.value);

        if (value !== undefined && value !== null) {
            setForm((prevState) => ({
                ...prevState,
                quantity: value,
            }));

            if (value % form.MOQ === 0) {
                setMoqValidate(true);
            } else {
                setMoqValidate(false);
            }
        }
    };

    const handleSubmit = () => {

        const validateMOQ = Number(form.quantity) % Number(form.MOQ);

        if (form.category && form.style === "") {
            setVisible(true)
            message.warning("Style is Required");
        } else if (form.quantity < 0) {
            setVisible(true)
            message.warning("Quantity should be greater than 0");
        } else if (validateMOQ === 0) {
            updatedData(form)
            setVisible(false)
        } else {
            setVisible(true)
            message.warning("Quantity should be Multiple of MOQ")
        }
    }


// Image Upload 
const handleUploadChange = async (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      await dispatch(addSingleGlobalEcomImage(file)).then((response) => {
        if (response.status == 200) {
          let imageUrl = response?.data?.filePath || "";
          setForm((prevState) =>
            ({
                ...prevState,
                imagePath: imageUrl,
            })
            );
        }
      });
    }
  };
  


    return (
        <>
            <Button
                className="btn d-flex align-items-center"
                type="button"
                onClick={showModal}
            >
                <Tooltip title="Update Purchase Order">
                    <EditOutlined />
                </Tooltip>
            </Button>
            <Modal
                visible={visible}
                onCancel={handleModalCancel}
                title="Update Purchase Order"
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}  
                cancelButtonProps={{ hidden: true }} 
                width={1300}
            >
                <Card>
                    <Form
                        onFinish={handleSubmit}  
                        initialValues={form}      
                    >
                        <Row gutter={24}>
                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter GST Number">
                                    <Form.Item
                                        name="gstNumber" 
                                    >
                                        <Input
                                            value={form.gst || undefined}
                                            placeholder="Enter GST Number"
                                            disabled
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Design Number">
                                    <Form.Item
                                        name="designNumber" 
                                    >
                                        <Input
                                            value={form.designNumber || undefined}
                                            onChange={handleDesignNumber}
                                            placeholder="Enter Design Number"
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Brand">
                                    <Form.Item
                                        // name="brand" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.brand || undefined}
                                            placeholder="Select Brand"
                                            onChange={(value) => (handleBrand(value))}
                                            options={brandsList?.map(brands => ({
                                                value: brands._id,
                                                label: brands.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Mark Up Value">
                                    <Form.Item
                                        name="markup" 
                                    >
                                        <Input
                                            value={form.markup || undefined}
                                            onChange={handleMarkUpValue}
                                            placeholder="Enter Mark Up Value"
                                            type="number"
                                            min={0}
                                            max={100}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Category">
                                    <Form.Item
                                        // name="category" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.category || undefined}
                                            placeholder="Select Category"
                                            onChange={(value) => (handleCategory(value))}
                                            options={categoryList?.map(category => ({
                                                value: category._id,
                                                label: category.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Style">
                                    <Form.Item
                                        // name="style" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.style || undefined}
                                            placeholder="Select Style"
                                            onChange={(value) => (handleStyle(value))}
                                            onClick={() => {
                                                if (styleList && styleList.length > 0) { return; }
                                                else { message.warning("Select Category") }
                                            }}
                                            options={styleList?.map(style => ({
                                                value: style._id,
                                                label: style.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Group">
                                    <Form.Item
                                        // name="group" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.group || undefined}
                                            placeholder="Select Group"
                                            onChange={(value) => (handleGroup(value))}
                                            options={groupsList?.map(group => ({
                                                value: group._id,
                                                label: group.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Season">
                                    <Form.Item
                                        // name="season" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.season || undefined}
                                            placeholder="Select Season"
                                            onChange={(value) => (handleSeason(value))}
                                            options={seasonsList?.map(season => ({
                                                value: season._id,
                                                label: season.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Section">
                                    <Form.Item
                                        name="section" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.section || undefined}
                                            placeholder="Select Section"
                                            onChange={(value) => (handleSection(value))}
                                            options={sectionsList?.map(section => ({
                                                value: section._id,
                                                label: section.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter HSN Code">
                                    <Form.Item
                                        name="HSNCode" 
                                    >
                                        <Input
                                            value={form.HSNCode || undefined}
                                            onChange={handleHSN}
                                            placeholder="Enter HSN Code"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Others">
                                    <Form.Item
                                        name="others" 
                                    >
                                        <Input
                                            min={0}
                                            max={100}
                                            value={form.others || undefined}
                                            onChange={handleOthers}
                                            placeholder="Enter Others"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Location">
                                    <Form.Item
                                        name="location" 
                                    >
                                        <Select
                                            showSearch
                                            value={form.location || undefined}
                                            placeholder="Select Location"
                                            onChange={(value) => (handleLocation(value))}
                                            options={locationList?.map(location => ({
                                                value: location._id,
                                                label: location.name,
                                            }))}
                                            disabled
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Discount">
                                    <Form.Item
                                        name="discount" 
                                    >
                                        <Input
                                            min={0}
                                            max={100}
                                            value={form.discount || undefined}
                                            addonBefore="%"
                                            type="number"
                                            placeholder="Enter Discount (%)"
                                            onChange={(e) => handleDiscountChange(e)}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Floor">
                                    <Form.Item
                                        // name="floor" 
                                    >
                                        <Select
                                            value={form.floorName || undefined}
                                            showSearch
                                            placeholder="Select Floor"
                                            onChange={(value) => (handleFloor(value))}
                                            options={floorsList?.map(floor => ({
                                                value: floor._id,
                                                label: floor.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Texture">
                                    <Form.Item
                                        // name="texture" 
                                    >
                                        <Select
                                            value={form.textureId || []}
                                            showSearch
                                            mode="multiple"
                                            placeholder="Select Texture"
                                            onChange={handleTexture}
                                            options={textureList?.map(texture => ({
                                                value: texture._id,
                                                label: texture.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Age Group">
                                    <Form.Item
                                        // name="ageGroup" 
                                    >
                                        <Select
                                            value={form.ageGroupName || undefined}
                                            showSearch
                                            placeholder="Select Age Group"
                                            onChange={(value) => (handleAgeGroup(value))}
                                            options={ageGroupList?.map(ageGroup => ({
                                                value: ageGroup._id,
                                                label: ageGroup.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col sm={22} md={6} lg={6} className='w-100'>
                                <Tooltip title="Enter Size">
                                    <Form.Item
                                        name="size"
                                        rules={[{ required: true, message: 'Please select a size' }]} // Validation
                                    >
                                        <Select
                                            showSearch
                                            value={form.size || undefined}
                                            placeholder="Select Size"
                                            onChange={(value) => handleSize(value)}
                                            options={sizesList.map((size) => ({
                                                value: size._id,
                                                label: size.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Price">
                                    <Form.Item
                                        name="purchasePrice"
                                        rules={[{ required: true, message: 'Please enter a price' }]} // Validation
                                    >
                                        <Input
                                            min={0}
                                            value={form.purchasePrice || undefined}
                                            onChange={(e) => handlePrice(e)}
                                            placeholder="Enter Price"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter MOQ">
                                    <Form.Item
                                        name="MOQ"
                                        rules={[{ required: true, message: 'Please enter MOQ' }]} // Validation
                                    >
                                        <Input
                                            min={1}
                                            value={form.MOQ || undefined}
                                            onChange={(e) => handleMOQ(e, form.id)}
                                            placeholder="Enter MOQ"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter WSP">
                                    <Form.Item name="wsp">
                                        <Input
                                            value={form.wsp || undefined}
                                            placeholder="Enter WSP"
                                            type="number"
                                            disabled
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col sm={22} md={6} lg={6} className='w-100'>
                                <Tooltip title="Enter Color">
                                    <Form.Item
                                        name="color"
                                        rules={[{ required: true, message: 'Please select a color' }]} // Validation
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select Color"
                                            value={form.color || undefined}
                                            onChange={(value) => handleColor(value)}
                                            options={colorsList.map((color) => ({
                                                value: color._id,
                                                label: color.name,
                                            }))}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Quantity">
                                    <Form.Item
                                        name="quantity"
                                        rules={[{ required: true, message: 'Please enter a quantity' }]} // Validation
                                    >
                                        <Input
                                            min={form.MOQ}
                                            type="number"
                                            value={form.quantity || "" || undefined}
                                            onChange={(e) => handleQuantity(e)}
                                            placeholder="Quantity"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Boxes">
                                    <Form.Item >
                                        <Input
                                            value={boxes || undefined}
                                            disabled
                                            placeholder="Boxes"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                            <Tooltip title="Upload Image">
                        <Form.Item
                          valuePropName="fileList"
                          getValueFromEvent={(e) =>
                            Array.isArray(e) ? e : e?.fileList
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <Upload
                            // loading={imageLoading}
                            maxCount={1}
                            accept="image/*"
                            beforeUpload={() => false}
                            listType="picture"
                            defaultFileList={[
                                {
                                  uid: '-1',
                                  name: 'image.jpg',
                                  status: 'done',
                                  url: form.imagePath
                                },
                              ]}
                            onChange={(file) =>
                              handleUploadChange(file,)
                            }
                            // onRemove={(file)=>handleDeleteImage(file)}
                          >
                            <Button icon={<UploadOutlined />}>
                              Upload Image
                            </Button>
                          </Upload>
                          </Form.Item>
                                </Tooltip>
                            </Col>

                        </Row>
                        <Button className="w-100 bg-primary text-white" htmlType="submit">Save</Button>
                    </Form>
                </Card>
            </Modal>

        </>
    );
}
export default UpdatePurchaseOrder
