import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Space,
  Input,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  DatePicker,
  Form,
  message,
  Modal,
} from "antd";
import moment from "moment";
import { fetchLastBillForLR } from "../../../redux/actions/saleAction";
import { useDispatch, useSelector } from "react-redux";
import ShowBillProducts from "../sales/ShowBillProducts";
import UploadLR from "./UploadLR";

const { Column } = Table;
const dateFormat = "YYYY-MM-DD";
const SalesListLR = () => {
  const dispatch = useDispatch();
  const lastSalesforLR = useSelector((state) => state.saleData.lastSalesForLr);
//   const [startDate, setStartDate] = useState(
//     moment(new Date()).format("YYYY-MM-DD")
//   );
//   const [endDate, setEndDate] = useState(
//     moment(new Date()).format("YYYY-MM-DD")
//   );

  // End Date Change
//   const onEndDateChange = (date, dateString) => {
//     setEndDate(dateString);
//   };
  // Start Date Change
//   const onStartDateChange = (date, dateString) => {
//     setStartDate(dateString);
//   };
  // Get Data
  const showDataOnList = async () => {
    // let today = moment(new Date()).format("YYYY-MM-DD");
    // let reportObj;
    // if (today == endDate) {
    //   reportObj = {
    //     startDate: startDate,
    //     endDate: endDate + " " + moment().format("HH:mm:ss"),
    //   };
    // } else {
    //   const dateMoment = moment(endDate, "YYYY-MM-DD");
    //   const endTime = dateMoment.endOf("day").format("HH:mm:ss");
    //   const endDateWithEndTime = `${endDate} ${endTime}`;
    //   reportObj = {
    //     startDate: startDate,
    //     endDate: endDateWithEndTime,
    //   };
    // }
    await dispatch(fetchLastBillForLR());
  };

  const resetfuntion = async () => {
    // let startDate = moment(new Date()).format("YYYY-MM-DD");
    // let endDate = moment(new Date()).format("YYYY-MM-DD");
    // let reportObj = {
    //   startDate: startDate,
    //   endDate: endDate + " " + moment().format("HH:mm:ss"),
    // };
    // setStartDate(moment(new Date()).format("YYYY-MM-DD"));
    // endDate(moment(new Date()).format("YYYY-MM-DD"));
    await dispatch(fetchLastBillForLR());
  };

  useEffect(() => {
    showDataOnList();
  }, []);
  return (
    <>
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="start">
              <div className="page-header">LR Sales Bills</div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="end">
              {/* <Form
                name="add-Purchase"
                className="add-Purchase"
                onFinish={showDataOnList}
                initialValues={{ remember: true }}
              >
                <div className="datePicker-hideCross">
                  <Row gutter={[16, 0]}>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                      <Tooltip title="Select Start Date">
                        <Form.Item name="startDate">
                          {console.log("")}
                          <DatePicker
                            style={{ width: "100%" }}
                            onChange={onStartDateChange}
                            placeholder="Select Start Date"
                            value={moment(startDate, dateFormat)}
                            format={dateFormat}
                            showClose={false}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                      <Tooltip title="Select End Date">
                        <Form.Item name="endDate">
                          {console.log("")}
                          <DatePicker
                            style={{ width: "100%" }}
                            onChange={onEndDateChange}
                            placeholder="Select End Date"
                            value={moment(endDate, dateFormat)}
                            format={dateFormat}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="close-modal"
                          block
                        >
                          Search
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <Form.Item>
                        <Button
                          type="primary"
                          block
                          onClick={() => resetfuntion()}
                        >
                          Reset
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Form> */}
              <UploadLR
                  invoiceData={lastSalesforLR?.details || []}
                  showDataOnList={showDataOnList}
                />
            </Col>
          </Row>

          <div className="card mt-4">
            {/* <Row className="mt-4">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center">
                <UploadLR
                  invoiceData={lastSalesforLR?.details || []}
                  showDataOnList={showDataOnList}
                />
              </Col>
            </Row> */}
            <div className="card-body">
              <Table
                dataSource={(lastSalesforLR && lastSalesforLR?.details) || []}
                bordered="true"
                size="middle"
                // pagination={false}
                pagination={{
                  pageSize: 100,
                  total:
                    lastSalesforLR && lastSalesforLR.details
                      ? lastSalesforLR.details.length
                      : 0,
                }}
                scroll={{ y: 400 }}
                summary={() => (
                  <Table.Summary fixed={"bottom"}>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        className="grand-Total-summary"
                        index={1}
                        colSpan={2}
                      >
                        Grand Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {lastSalesforLR && lastSalesforLR.details
                          ? lastSalesforLR.details.length
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={2}>
                        {}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {" "}
                        {lastSalesforLR &&
                        lastSalesforLR.sum &&
                        lastSalesforLR.sum.totalBoxes
                          ? lastSalesforLR.sum.totalBoxes
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {" "}
                        {lastSalesforLR &&
                        lastSalesforLR.sum &&
                        lastSalesforLR.sum.quantity
                          ? lastSalesforLR.sum.quantity
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {lastSalesforLR &&
                        lastSalesforLR.sum &&
                        lastSalesforLR.sum.totalAmount
                          ? Number(lastSalesforLR.sum.totalAmount).toFixed()
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {lastSalesforLR &&
                        lastSalesforLR.sum &&
                        lastSalesforLR.sum.netAmount
                          ? Number(lastSalesforLR.sum.netAmount).toFixed()
                          : ""}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
                // antd site header height
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Bill Date"
                  dataIndex="date"
                  key="date"
                  width={210}
                  render={(value, item, index) =>
                    `${moment(item.date).format("YYYY-MM-DD")} (${moment(
                      item.date
                    ).format("HH:mm:ss")} )`
                  }
                />

                <Column
                  title="Invoice No."
                  dataIndex="invoiceId"
                  key="invoiceId"
                  width={150}
                  render={(value, item, index) => {
                    if (item && item.invoiceId) {
                      return `S-${item.invoiceId}`;
                    }
                  }}
                />
                <Column
                  title="Customer Name"
                  dataIndex="userId"
                  key="userId"
                  width={190}
                  render={(value, item, index) =>
                    `${
                      item?.userId && item?.userId.name
                        ? item?.userId?.name
                        : ""
                    }`
                  }
                />
                <Column
                  title="Agent Name"
                  dataIndex="userId"
                  key="userId"
                  render={(value, item, index) =>
                    `${
                      item?.userId?.agentId ? item?.userId?.agentId?.name : ""
                    }`
                  }
                  width={170}
                />
                <Column
                  title="Total Boxes"
                  dataIndex="totalBoxes"
                  key="totalBoxes"
                  width={120}
                />
                <Column
                  title="Total Qnty"
                  dataIndex="totalQuantity"
                  key="totalQuantity"
                  width={120}
                />
                <Column
                  title="Total Amt"
                  dataIndex="totalAmount"
                  key="totalAmount"
                  render={(value, item, index) =>
                    parseInt(item.totalAmount).toFixed()
                  }
                  width={100}
                />
                <Column
                  title="Gr Amt"
                  dataIndex="grAmountUsed"
                  key="grAmountUsed"
                  render={(value, item, index) =>
                    Number(item.grAmountUsed).toFixed()
                  }
                  width={100}
                />
                <Column
                  title="Net Amt"
                  dataIndex="netAmount"
                  key="netAmount"
                  width={120}
                  render={(value, item, index) =>
                    parseInt(item.netAmount).toFixed()
                  }
                />
                <Column
                  title="Remark"
                  dataIndex="remark"
                  key="remark"
                  width={120}
                />
                <Column
                  title="Verified"
                  dataIndex="isVerified"
                  key="isVerified"
                  width={120}
                  render={(value, item, index) => {
                    return `${item.isVerified}`;
                  }}
                />
                <Column
                  title="Updated Date"
                  dataIndex="updatedAt"
                  key="updatedAt"
                  width={210}
                  render={(value, item, index) => {
                    if (item && item.updatedAt) {
                      return `${moment(item.updatedAt).format(
                        "YYYY-MM-DD"
                      )} (${moment(item.updatedAt).format("HH:mm:ss")} )`;
                    }
                  }}
                />
                <Column
                  title="Action"
                  key="action"
                  width={80}
                  fixed="right"
                  render={(text, record) => (
                    <Row>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <a>
                          <ShowBillProducts
                            invoiceId={record?.invoiceId}
                            data={record}
                          />
                        </a>
                      </Col>
                    </Row>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesListLR;
