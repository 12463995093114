import React, { Component, useEffect, useState, useMemo } from "react"
import { Row, Col, Select, Typography, Card, Form, Button, Tooltip, DatePicker, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../../../../redux/actions/brandAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import { getSections } from "../../../../redux/actions/sectionAction";
import { getfloorSectionBrandSearch, getSalesandSalesAgeReports } from "../../../../redux/actions/reportsActions";
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts';
import moment from "moment";
import gifLoader from "../../../../assets/images/giphy.gif"
import debounce from "lodash.debounce";
import { getAllStyles } from "../../../../redux/actions/styleAction";
import TopBarProgress from "react-topbar-progress-indicator";

// ================
import { salesAgeData } from "../../../../util/constants";
const dateFormat = "YYYY-MM-DD";
const SalesandSalesAgeReport = () => {
    const dispatch = useDispatch()
    const barndsData = useSelector((state) => state.brandsData.brands)
    const styleData  = useSelector(state=>state.styleData.styles)
    const sectionData = useSelector((state) => state.sectionData.sections)
    const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar);
    const floorData = useSelector((state) => state.floorData.floors)
    const floorSectionBrandSearch = useSelector((state) => state.reportData.floorSectionBrandSearch)
    const salesAndSalesAgeData = useSelector((state) => state.reportData.salesAndSaleAgeData)
    const [loading, setLoading] = useState("")

    const [brand, setbrand] = useState("")
    const [floor, setfloor] = useState("")
    const [section, setsection] = useState("")
    const [startDate, setstertDat] = useState("")
    const [endDate, setendDate] = useState("")
    const [style, setStyle] = useState("")


    


    // ===============Calculating functions and states for graphs===========================
    const [mainData, setMainData] = useState([])
    const [averrageSalesAge, setAverageSalesAge] = useState(0)
    const [totalSalesAmt, setTotalSalesAmt] = useState(0)
    const [totalBoxes, setTotalBoxes] = useState(0)
    const [topBrands, setTopBrands] = useState([])
    const [bottomBrands, setBottomBrands] = useState([])
    const [stylesData, setStylesData] = useState([])
    const [floorAllData, setFloorData] = useState([])
    const [salesByDays, setSalesByDays] = useState([])

    const debouncedGetBrands = debounce(() => dispatch(getBrands()), 500);
    const debouncedGetSections = debounce(() => dispatch(getSections()), 500);
    const debouncedGetFloors = debounce(() => dispatch(getFloors()), 500);
    const debouncedGetStyle = debounce(() => dispatch(getAllStyles()), 500);

  

    // calculate Total sales amount, Average sales age, Top Brands, Bottom Brands, Floor wise Sales and section wise sales
    const calulateandCreateData = (data) => {
        const brandTotals = {};
        const styleTotals = {};
        const floorTotals = {}
        let allSalesAge = 0
        let totalBoxes = 0
        let toalAmtOfAll = 0

        // Iterate over the salesAgeData
        data.forEach((item, index) => {
            const { brand, totalAmount, boxes, style, floorName } = item;

            // Calculate Average sales age and total sales amount
            allSalesAge = allSalesAge + data[index].salesage
            totalBoxes = totalBoxes + data[index].boxes
            toalAmtOfAll = toalAmtOfAll + data[index].totalAmount

            // for brand
            if (!brandTotals[brand]) {
                brandTotals[brand] = {
                    brand: brand,
                    totalBoxes: 0,
                    totalAmount: 0
                };
            }

            // for sales
            if (!styleTotals[style]) {
                styleTotals[style] = {
                    style: style,
                    totalBoxes: 0,
                    totalAmount: 0
                };
            }

            // for floor
            if (!floorTotals[floorName]) {
                floorTotals[floorName] = {
                    floor: floorName,
                    totalBoxes: 0,
                    totalAmount: 0
                };
            }

            // Increment total boxes and total amount for the brand
            brandTotals[brand].totalBoxes += boxes;
            brandTotals[brand].totalAmount += totalAmount;

            // Increment total boxes and total amount for the styles
            styleTotals[style].totalBoxes += boxes;
            styleTotals[style].totalAmount += totalAmount;

            // Increment total boxes and total amount for the floor
            floorTotals[floorName].totalBoxes += boxes;
            floorTotals[floorName].totalAmount += totalAmount;
        });

        let finalSalesAge = allSalesAge / totalBoxes
        setAverageSalesAge(finalSalesAge)
        setTotalSalesAmt(toalAmtOfAll)
        setTotalBoxes(totalBoxes)

        // Convert brandTotals object into an array of objects for brand
        const resultBrand = Object.values(brandTotals);

        // Convert brandTotals object into an array of objects for styles
        const resultStyle = Object.values(styleTotals);

        // Convert brandTotals object into an array of objects for floor
        const resultFloor = Object.values(floorTotals);

        // get top Five Styles Based on TotalAmount
        let topStyles = resultStyle.sort((a, b) => b.totalAmount - a.totalAmount).slice(0, 5)
        setStylesData(topStyles)
        setFloorData(resultFloor)
        let topBrands = resultBrand.sort((a, b) => b.totalAmount - a.totalAmount).slice(0, 5)
        setBottomBrands(resultBrand.sort((a, b) => a.totalAmount - b.totalAmount).slice(0, 5))
        setTopBrands(topBrands)
        setLoading(false)
    }

    const getLastIndex = (arr) => {
        // Initialize the last index variable
        let lastIndex = 0;
        // Iterate over the array
        arr.forEach(obj => {
            // Extract the key (e.g., "0-20", "20-40", etc.)
            const key = Object.keys(obj)[0];
            // Split the key by hyphen and extract the upper limit
            const upperLimit = parseInt(key.split('-')[1]);
            // Update the last index if the current upper limit is greater
            if (upperLimit > lastIndex) {
                lastIndex = upperLimit;
            }
        });
        return lastIndex;
    }

    const salesBySalesDays = (tempAddBoxAmt, rangeOutput) => {
        let range = rangeOutput
        if (!tempAddBoxAmt) return []; // Check if obj is null or undefined
        let rangeArr = [];
        // Initialize range categories
        for (let i = 0; i <= 300; i += range) {
            rangeArr.push({ [`${i}-${i + range}`]: [] });
        }
        let newRange = rangeArr

        // Iterate over objects and categorize them
        tempAddBoxAmt.forEach(item => {
            let salesage = item.salesage;
            let found = false;
            for (let i = 0; i < newRange.length; i++) {
                let key = Object.keys(newRange[i])[0];
                let minMax = key.split('-');
                if (minMax.length === 1) {
                    if (salesage >= parseInt(minMax[0])) {
                        newRange[i][key].push(item);
                        found = true;
                        break;
                    }
                } else {
                    if (salesage >= parseInt(minMax[0]) && salesage <= parseInt(minMax[1])) {
                        newRange[i][key].push(item);
                        found = true;
                        break;
                    }
                }
            }
        });
        // let filteredArray = newRange?.filter(obj => Object.values(obj).some(arr => arr.length > 0));
        // let newArrKey = []
        let newArrValue = []

        let filteredArray = newRange?.filter(obj => {
            if (Object.values(obj).some(arr => arr.length > 0)) {
                let totalAmount = 0
                let totalBoxes = 0
                let string = Object.keys(obj)[0]
                Object.values(obj)[0].forEach(obj => {

                    totalAmount += obj.totalAmount
                    totalBoxes += obj.boxes
                    // Extracting the key and value from each object
                    // const key = Object.keys(obj)[0];
                    // newArrKey.push(key);
                    // newArrValue.push(obj);
                })
                newArrValue.push({
                    string: string,
                    totalBoxes: totalBoxes,
                    totalAmount: totalAmount
                });
            }
        });
        // setSalesByDays(newArrValue)
        return newArrValue;
    }


    // Submit Search Form
    const searchFilter = async () => {
        let newEndDate
        let today = moment(new Date()).format("YYYY-MM-DD")
        if (today == endDate) {
            newEndDate = endDate + " " + moment().format("HH:mm:ss")
        } else {
            const dateMoment = moment(endDate, 'YYYY-MM-DD');
            const endTime = dateMoment.endOf('day').format('HH:mm:ss');
            newEndDate = `${endDate} ${endTime}`;
        }
        setLoading(true)
        let obj = {
            section: section || [],
            startDate: startDate,
            endDate: newEndDate,
            floorName: floor || [],
            brand: brand || [],
            style : style || []
        }
        let response = await dispatch(getSalesandSalesAgeReports(obj))
        if (response && response.data.length == 0) {
            message.error("No Data Found...!")
            setLoading("")
        }
    }


    useEffect(() => {
        if (salesAndSalesAgeData && salesAndSalesAgeData?.data) {
            setMainData(salesAndSalesAgeData?.data)
            calulateandCreateData(salesAndSalesAgeData?.data)

            // calculate sales and sales by days
            let range = 1;
            let salesBysalesDaysAllData;
            do {
                salesBysalesDaysAllData = salesBySalesDays(salesAndSalesAgeData?.data, range);
                range++;
            } while (salesBysalesDaysAllData.length >= 12);

            if (salesBysalesDaysAllData.length < 12) {
                setSalesByDays(salesBysalesDaysAllData)
            }
        }
    }, [salesAndSalesAgeData])

    // ===============Creating Options and series for graphs===========================
    // Create Top Brands

    const topbrandsSeries = [
        {
            name: 'Total Amount',
            type: 'column',
            data: topBrands?.map(obj => Number(obj.totalAmount).toFixed())
        }, {
            name: 'Total Boxes',
            type: 'line',
            data: topBrands?.map(obj => Number(obj.totalBoxes).toFixed())

        }
    ]

    const topbrandsOptions = {
        chart: {
            height: 350,
            type: 'line',
        },
        stroke: {
            width: [0, 4]
        },
        title: {
            text: 'Top Brands',
            align: 'left'
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
        },
        labels: topBrands?.map(obj => obj.brand),
        xaxis: {
            // type: 'datetime'
        },
    }


    // Create Bottom Brands
    const bottomBrandsSeries = [
        {
            name: 'Total Amount',
            type: 'column',
            data: bottomBrands?.map(obj => Number(obj.totalAmount).toFixed())
        }, {
            name: 'Total Boxes',
            type: 'line',
            data: bottomBrands?.map(obj => Number(obj.totalBoxes).toFixed())

        }
    ]

    const bottomBrandsOptions = {
        chart: {
            height: 350,
            type: 'line',
        },
        stroke: {
            width: [0, 4]
        },
        title: {
            text: 'Bottom Brands',
            align: 'left'
        },
        dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
        },
        labels: bottomBrands?.map(obj => obj.brand),
        xaxis: {
            // type: 'datetime'
        },
    }

    // Create For Styles
    const salesByStyleSeries = [
        {
            name: 'Total Amount',
            data: stylesData.map(obj => Number(obj.totalAmount).toFixed()),
        },
        {
            name: 'Total Boxes',
            data: stylesData.map(obj => Number(obj.totalBoxes).toFixed()),
        },
    ]
    const salesByStyleOption = {
        chart: {
            type: 'bar',
            height: 350,
            stacked: true,
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
                columnWidth: '70%', // Adjust the width of the bars
                stacked: false, // Disable stacking
            }
        },
        title: {
            text: 'Total Sales Amount By Styles',
            align: 'left'
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: stylesData.map(obj => obj.style)
        },
        tooltip: {
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    // Format the tooltip to display both Total Amount and Total Boxes
                    return `${value},  Total Boxes: ${series[1][dataPointIndex]}`;
                }
            }
        }
    }

    // Create For Floors

    const pieChartSeries = floorAllData?.map(obj => obj.totalAmount)
    const pieChartOption = {
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '13px',
                    },
                    value: {
                        fontSize: '12px',
                        // padding: "0px 15px",
                        formatter: function (val) {
                            // Customize the formatter to hide the percentage symbol
                            return `Total Amt: ${val}`;
                        }
                    },
                    total: {
                        show: false,
                        label: 'Total',
                        // formatter: function (w) {
                        //     // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                        //     return 249
                        // }
                    }
                }
            }
        },
        title: {
            text: "Floor Wise Sales"
        },
        labels: floorAllData?.map(obj => obj.floor)
    }

    // Create For Sales By Days
    const seriesSalesByDays = [
        {
            name: 'Total Boxes',
            type: 'area',
            data: salesByDays?.map(obj => Number(obj.totalBoxes).toFixed())
        },
        {
            name: 'Total Amount',
            type: 'line',
            data: salesByDays?.map(obj => Number(obj.totalAmount).toFixed())
        },
    ]

    const optionSalesByDays = {
        chart: {
            height: 350,
            type: 'line',
        },
        stroke: {
            curve: 'smooth'
        },
        fill: {
            type: 'solid',
            opacity: [0.35, 1],
        },
        labels: salesByDays?.map(obj => obj.string),
        markers: {
            size: 0
        },
        title: {
            text: 'Total Sales By Days',
            align: 'left'
        },

        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: function (y) {
                    if (typeof y !== "undefined") {
                        return y.toFixed(0);
                    }
                    return y;
                }
            }
        }
    }

    const resetFun = () => {
        window.location.reload(true)
    }
    const handleChangeBrand = (key, value) => {
        console.log("git test")
        let brandName = value?.map(obj => obj.label);
        setbrand(brandName);
    
        const params = { Brand: brandName };
    
        if (floor) {
            params.floor = floor;
        }
        if (section) {
            params.section = section;
        }
        if (style) {
            params.style = style;
        }
    
        dispatch(getfloorSectionBrandSearch(params));
    };
    const handleChangeStyle = (key, value) => {
        let styleName = value?.map(obj => obj.label)
        setStyle(styleName)
        
        const params = { style: styleName };
    
        if (floor) {
            params.floor = floor;
        }
        if (section) {
            params.section = section;
        }
        if (brand) {
            params.Brand = brand;
        }
    
        dispatch(getfloorSectionBrandSearch(params));
    };


    const handleChangeSection = (key, value) => {
        setsection([value.value])
        const params = { section: [value.value] };
    
        if (floor) {
            params.floor = floor;
        }
        if (style) {
            params.style = style;
        }
        if (brand) {
            params.Brand = brand;
        }
    
        dispatch(getfloorSectionBrandSearch(params));
    };

    const handleChangeFloor = (key, value) => {
        setfloor([value.value])
        const params = { floor: [value.value] };
    
        if (style) {
            params.style = style;
        }
        if (section) {
            params.section = section;
        }
        if (brand) {
            params.Brand = brand;
        }
    
        dispatch(getfloorSectionBrandSearch(params));
    };

    const getData = (name) => {
        let obj = {
            Brand: brand,
            floor: floor,
            section: section,
        }
        for (let key in obj) {
            if (obj[key] === '') {
                delete obj[key];
            }
        }
        if (name === "brand") debouncedGetBrands();
        if (name === "section") debouncedGetSections();
        if (name === "floor") debouncedGetFloors();
        if (name === "style") debouncedGetStyle();
    };
    let brandOptions = barndsData?.map((brand) => {
        return (
            {
                value: brand.name,
                label: brand.name
            }
        );
    });

    let brandOptionsFilter = floorSectionBrandSearch?.data?.brand?.map((brand) => {
        return (
            {
                value: brand.brand,
                label: brand.brand
            }
        );
    });

    let sectionOptionsFilter = floorSectionBrandSearch?.data?.section?.map((section) => {
        return (
            {
                value: section.section,
                label: section.section
            }
        );
    });

    let sectionOptions = sectionData?.map((section) => {
        return (
            {
                value: section.name,
                label: section.name
            }
        );
    });
    let floorOptions = floorData?.map((floor) => {
        return (
            {
                value: floor.name,
                label: floor.name
            }
        );
    });
    let floorOptionsFilter = floorSectionBrandSearch?.data?.floor?.map((floor) => {
        return (
            {
                value: floor.floorName,
                label: floor.floorName
            }
        );
    });

    let styleOptions = styleData?.map((style) => {
        return (
            {
                value: style.name,
                label: style.name
            }
        );
    });
    let styleOptionsFilter = floorSectionBrandSearch?.data?.style?.map((style) => {
        return (
            {
                value: style.style,
                label: style.style
            }
        );
    });



    

    const onStartDateChange = (date, dateString) => {
        setstertDat(dateString);
    };
    const onEndDateChange = (date, dateString) => {
        setendDate(dateString)
    };


    return (<>
    {progressBarStatus && <TopBarProgress />}
        <div className="subCategory-dashboard">
            <div className="container-fluid category-list">
                <Row className="title-row" gutter={[8, 0]} >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <div className="page-header">Sales and Sales Age Report</div>
                    </Col>
                </Row>
                <Form
                    name="add-Purchase"
                    className="add-Purchase"
                    onFinish={searchFilter}
                >
                    <Row className="title-row mt-4" gutter={[8, 0]} >
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Start Date !",
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    onChange={onStartDateChange}
                                    placeholder="Select Start Date"
                                    showClose={false}
                                    format={dateFormat}
                                    value={moment(startDate, dateFormat)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item
                                name="endDate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter End Date !",
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    onChange={onEndDateChange}
                                    placeholder="Select End Date"
                                    format={dateFormat}
                                    showClose={false}
                                    value={moment(endDate, dateFormat)}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Select Floor">
                                <Form.Item
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Floor"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.value
                                                .toLowerCase()
                                                .localeCompare(optionB.value.toLowerCase())
                                        }
                                        onChange={handleChangeFloor}
                                        onClick={() => getData("floor")}
                                        onInputKeyDown={() => getData("floor")}
                                        options={floorOptionsFilter || floorOptions}
                                    />

                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Select Section">
                                <Form.Item
                                    name="section"
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select Section"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.value
                                                .toLowerCase()
                                                .localeCompare(optionB.value.toLowerCase())
                                        }
                                        onChange={handleChangeSection}
                                        onClick={() => getData("section")}
                                        onInputKeyDown={() => getData("section")}
                                        options={sectionOptionsFilter || sectionOptions}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                     
                    </Row>
                    <Row className="title-row mt-2" gutter={[8, 0]}>

                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Select Brand">
                                <Form.Item
                                    name="brand"
                                >
                                    <Select
                                        mode="multiple"
                                        name="brand"
                                        id="brand"
                                        showSearch
                                        placeholder="Select Brand"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.value
                                                .toLowerCase()
                                                .localeCompare(optionB.value.toLowerCase())
                                        }
                                        onChange={handleChangeBrand}
                                        onClick={() => getData("brand")}
                                        onInputKeyDown={() => getData("brand")}
                                        options={brandOptionsFilter || brandOptions}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Select Style">
                                <Form.Item
                                    name="style"
                                >
                                    <Select
                                        mode="multiple"
                                        name="style"
                                        id="style"
                                        showSearch
                                        placeholder="Select Style"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.value
                                                .toLowerCase()
                                                .indexOf(input?.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.value
                                                .toLowerCase()
                                                .localeCompare(optionB.value.toLowerCase())
                                        }
                                        onChange={handleChangeStyle}
                                        onClick={() => getData("style")}
                                        onInputKeyDown={() => getData("style")}
                                        options={styleOptionsFilter || styleOptions}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="close-modal"
                                    block
                                >
                                    Search
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    // htmlType="submit"
                                    className="btn-stock-report-search"
                                    block
                                    onClick={() => resetFun()}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {
                    loading === true ? <>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={gifLoader} width={"300px"} height={"300px"} />
                        </div>
                    </> : <>
                        <Row className="title-row mt-4" gutter={[16, 0]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16}>
                                <div className="apex-linechart-salesByDays">
                                    <ReactApexChart options={optionSalesByDays} series={seriesSalesByDays} type="line" height={500} />
                                </div>
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                                <div className="check-test">
                                    <Card bordered={false} className="criclebox" style={{ marginTop: "20px" }}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col xs={18}>
                                                    <span>Average Sales Age</span>
                                                    <Typography.Title level={5}>
                                                        <small className="bnb2" ></small> 
                                                       {isNaN(averrageSalesAge) ? "" : Number(averrageSalesAge).toFixed()}
                                                    </Typography.Title>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="icon-box"> <i className="fas fa-line-chart"></i></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                    <Card bordered={false} className="criclebox mt-4" style={{ marginTop: "20px" }}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col xs={18}>
                                                    <span>Total Sales Amount</span>
                                                    <Typography.Title level={5}>
                                                        <small className="bnb2" >₹</small> {totalSalesAmt}
                                                    </Typography.Title>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="icon-box"> <i className="fas fa-line-chart"></i></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                    <Card bordered={false} className="criclebox mt-4" style={{ marginTop: "20px" }}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col xs={18}>
                                                    <span>Total Boxes</span>
                                                    <Typography.Title level={5}>
                                                        <small className="bnb2" ></small> {Number(totalBoxes).toFixed()}
                                                    </Typography.Title>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="icon-box"> <i className="fas fa-line-chart"></i></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                    <Card bordered={false} className="criclebox mt-4" style={{ marginTop: "20px" }}>
                                        <div className="number">
                                            <Row align="middle" gutter={[24, 0]}>
                                                <Col xs={18}>
                                                    <span>Total Amount Sum (Sales Age More Than 120)</span>
                                                    <Typography.Title level={5}>
                                                        <small className="bnb2" ></small> ₹{salesAndSalesAgeData?.totalAmountAboveRange ? Number(salesAndSalesAgeData?.totalAmountAboveRange).toFixed() : 0}
                                                    </Typography.Title>
                                                </Col>
                                                <Col xs={6}>
                                                    <div className="icon-box"> <i className="fas fa-line-chart"></i></div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                        <Row className="title-row mt-4" gutter={[8, 0]} >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <div className="apex-linechart-salesByDays min-height-480 chart-styleWise">
                                    <ReactApexChart options={salesByStyleOption} series={salesByStyleSeries} type="bar" height={350} />
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12}  >
                                <div className="apex-linechart-salesByDays min-height-480 chart-floorwise">
                                    <ReactApexChart options={pieChartOption} series={pieChartSeries} type="radialBar" style={{ marginTop: "20px" }} height={300} />
                                    {
                                        <div>
                                            <ul>
                                                <Row gutter={40} align="center">
                                                    {
                                                        floorAllData?.map(obj => <li className="mt-4">
                                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                <div className="criclebox" style={{ border: "1px solid black", padding: "5px 10px" }}>
                                                                    <span> {obj?.floor}</span>
                                                                    <Typography.Title level={5}>
                                                                        <small className="bnb2" >Total Boxes : </small> {Number(obj?.totalBoxes).toFixed()}
                                                                    </Typography.Title>
                                                                    <Typography.Title level={5}>
                                                                        <small className="bnb2" >Total Amount : </small> {Number(obj?.totalAmount).toFixed()}
                                                                    </Typography.Title>
                                                                </div>
                                                            </Col>
                                                        </li>)
                                                    }
                                                </Row>
                                            </ul>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="title-row mt-4" gutter={[8, 0]}  >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                <div className="apex-linechart-salesByDays min-height-440">
                                    <ReactApexChart options={topbrandsOptions} series={topbrandsSeries} type="line" height={300} />
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="end">
                                <div className="apex-linechart-salesByDays min-height-440">
                                    <ReactApexChart options={bottomBrandsOptions} series={bottomBrandsSeries} type="line" height={300} />
                                </div>
                            </Col>
                        </Row>

                    </>
                }
            </div>
        </div >
    </>)
}

export default SalesandSalesAgeReport
