import React from "react";
import AddPackagingMethod from "./AddPackagingMethod";
import { Col, Row } from "antd";
import PackagingMethodList from "./PackagingMethodList";

const PackagingMethod = () => {
  return (
    <div className="subCategory-dashboard">
      <div className="container-fluid category-list">
        <Row className="title-row">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="page-header">Packaging Method</div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <AddPackagingMethod />
          </Col>
        </Row>

        <PackagingMethodList />
      </div>
    </div>
  );
};

export default PackagingMethod;
