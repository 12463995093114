import { Button, Col, Modal, Row, Form, Select, Radio } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createPackagingMethod,
  getPackagingMethods,
} from "../../../redux/actions/packageMethodAction";

const { Option } = Select;

const AddPackagingMethod = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    packagingType: "",
    packagingValue: "Collective",
  });

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("Form values:", values);
    const response = await dispatch(createPackagingMethod(values));
    if (response.status === 200) {
      await dispatch(getPackagingMethods());
    }
    setLoading(false);
    setOpen(false);
  };

  const handleModalCancel = () => {
    setOpen(false);
    setData({
      packagingType: "",
      packagingValue: "Collective",
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onSelectChange = (value) => {
    setData({ ...data, packagingType: value });
  };

  return (
    <Row>
      <Col span={24}>
        <div className="add-button">
          <Button
            className="btn-admin btn-subCategory"
            type="primary"
            onClick={handleModalOpen}
          >
            Add
          </Button>
        </div>
        <Modal
          open={open}
          onCancel={handleModalCancel}
          title="Add Packaging Method"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
        >
          <Form
            name="add-packaging-method"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              label="Packaging Type"
              name="packagingType"
              rules={[
                {
                  required: true,
                  message: "Please select packaging type!",
                },
              ]}
            >
              <Select
                placeholder="Select packaging type"
                value={data.packagingType}
                onChange={onSelectChange}
              >
                <Option value="Box">Box</Option>
                <Option value="Loose">Loose</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Packaging Value"
              name="packagingValue"
              rules={[
                {
                  required: true,
                  message: "Please select packaging value!",
                },
              ]}
            >
              <Radio.Group
                name="packagingValue"
                value={data.packagingValue}
                onChange={onChange}
              >
                <Radio value="Collective">Collective</Radio>
                <Radio value="Individual">Individual</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item className="float-right">
              <Button className="close-modal mr-3" onClick={handleModalCancel}>
                Close
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="ok-modal"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Col>
    </Row>
  );
};

export default AddPackagingMethod;