import React from 'react'
import { Button, Card, Col, Row } from 'antd'
import RackCard from './RackCard'
import "./style.css"
import { useHistory } from "react-router-dom";

function RackManagement() {
    const history = useHistory();

    const columnNavigate = () => {
        history.push('/dagaImpex/rack-management/rack-column')
    }

    return (
        <>
            <Card>
                <Row gutter={24} className="mb-2">
                    <Col sm={24} md={8} lg={8}>
                        <div className='rackManagementHeading'>
                            Rack Management
                        </div>
                    </Col>
                    <Col sm={24} md={16} lg={16}>

                    </Col>
                </Row>


                <Row gutter={24} className="">
                    <Col sm={12} md={6} lg={6} className="my-2">
                        <button onClick={columnNavigate} className='border-0 bg-transparent'>
                            <RackCard />
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="my-2">
                        <button onClick={columnNavigate} className='border-0 bg-transparent'>
                            <RackCard />
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="my-2">
                        <button onClick={columnNavigate} className='border-0 bg-transparent'>
                            <RackCard />
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="my-2">
                        <button onClick={columnNavigate} className='border-0 bg-transparent'>
                            <RackCard />
                        </button>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="my-2">
                        <button onClick={columnNavigate} className='border-0 bg-transparent'>
                            <RackCard />
                        </button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default RackManagement