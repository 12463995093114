import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  getsaleReturnBillBysaleReturnId,
  salesBillDetails,
  sendMessageOnWhatsapp,
  sendSRMessageOnWhatsapp,
  uploadImage,
} from "../../../../../redux/actions/saleAction";
import moment from "moment";
import logo from "../../../../../assets/images/Daga_Impex_Logo.png";
import QR from "../../../../../assets/images/QR.png";
import { Button, Popconfirm, Tooltip } from "antd";
import Swal from "sweetalert2";

const SalesReturnBillWB = ({ saleReturnId, record }) => {
  console.log("record", record);
  const dispatch = useDispatch();

  const handleDownload = async () => {
    const response = await dispatch(
      getsaleReturnBillBysaleReturnId(saleReturnId)
    );
    const data = response?.data?.data;

    const doc = new jsPDF({
      compress: true,
    });

    // Header and Company Info
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(14);
    doc.text("JAY SHREE KRISHNA", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });
    doc.setFont("helvetica", "bold");
    doc.text("Estimate", doc.internal.pageSize.getWidth() / 2, 33, {
      align: "center",
    });
    doc.addImage(logo, "PNG", 12, 10, 30, 15);
    doc.setLineWidth(0.5);
    doc.line(10, 38, doc.internal.pageSize.getWidth() - 10, 38);

    // Client Info
    doc.setFontSize(14);
    doc.text(`${record?.customerName || ""}`, 12, 45);
    doc.setFontSize(12);
    doc.text("Date:", 12, 51);
    doc.text(`${moment(data?.details?.billDate).format("DD/MM/YY")}`, 25, 51);
    doc.text("SaleReturn Id:", 12, 56);
    doc.text(`${saleReturnId || ""}`, 43, 56);
    doc.text("Remark:", 12, 61);
    doc.text(`${record?.remark || ""}`, 30, 61);

    // Sales Items Table
    let tQuant = 0;
    let tAmount = 0;

    // Generate rows for each item
    const tableData = data.map((item, index) => {
      const row = [
        index + 1,
        item?.invoiceId,
        item?.details?.brand,
        item?.details?.designNumber,
        item?.details?.color,
        item?.MOQ,
        item?.quantity / item?.MOQ,
        item?.details?.size,
        item?.quantity,
        item?.price,
        item?.quantity * item?.price,
      ];

      tQuant += item?.quantity;
      tAmount += item?.quantity * item?.price;

      return row;
    });
    console.log("checkdata", data);

    // Add the totals row
    const tableData2 = [
      ...tableData,
      ["", "Total:", "", "", "", "", "", "", tQuant, "", tAmount],
    ];

    // Render the table in PDF
    doc.autoTable({
      head: [
        [
          "Sl No.",
          "InvoiceId",
          "Brand",
          "D-No.",
          "Color",
          "MOQ",
          "Set",
          "Size",
          "Quantity",
          "Rate",
          "Amount",
        ],
      ],
      body: tableData2,
      theme: "grid",
      startY: 68,
      styles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 10,
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [0, 0, 0],
        fillColor: [200, 200, 200],
        halign: "center",
      },
      columnStyles: {
        0: { columnWidth: 10 },
        1: { columnWidth: 28 },
        2: { columnWidth: 17 },
        3: { columnWidth: 17 },
        4: { columnWidth: 17 },
        5: { columnWidth: 17 },
        6: { columnWidth: 17 },
        7: { columnWidth: 17 },
        8: { columnWidth: 17 },
        9: { columnWidth: 17 },
        10: { columnWidth: 17 },
      },
    });

    // Bill Summary Section
    const finalY = doc.autoTable.previous.finalY;

    // Footer Section with Terms and QR Code
    const pageWidth = doc.internal.pageSize.getWidth();
    const halfPageWidth = pageWidth / 2 - 5;

    doc.setFontSize(12);
    doc.setTextColor(90, 90, 90);
    doc.text("* Terms and conditions", 12, finalY + 8);
    doc.setTextColor(128, 128, 128);
    doc.setFontSize(9);
    doc.text("1. Goods once sold will not be taken back", 12, finalY + 13);
    doc.text("2. No Claim No Guarantee", 12, finalY + 18);
    doc.text(
      "3. Any disputes arising from this transaction will be resolved through Jurisdiction in Indore.",
      12,
      finalY + 23
    );
    doc.text(
      "4. A late payment fee of 10% will be applied to overdue balances after 30 days.",
      12,
      finalY + 28
    );

    const qrX = halfPageWidth + 70;

    // Add the QR image
    doc.addImage(QR, "PNG", qrX, finalY + 3, 33, 33);

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "|| Thank You Visit Again ||",
      doc.internal.pageSize.getWidth() / 2,
      finalY + 80,
      {
        align: "center",
      }
    );

    const pdfBlob = doc.output("blob");
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(pdfBlob);
    // link.download = `${"invoice"}.pdf`;

    // Append the link to the body (required for Firefox)
    // document.body.appendChild(link);
    // link.click();
    const formData = new FormData();
    formData.append("image", pdfBlob, saleReturnId);
    formData.append("saleReturnId", saleReturnId);
    await dispatch(uploadImage(formData))
      .then(async (response) => {
        console.log("response", response);
        const res = await dispatch(
          sendSRMessageOnWhatsapp({
            pdfUrl: response?.data?.filePath,
            srId: saleReturnId,
          })
        );
        console.log("res", res);
        if (res.status == 200) {
          Swal.fire(
            "Success!",
            "Bill sent successfully on WhatsApp!",
            "success"
          );
        } else {
          Swal.fire("Something went Wrong!", "error");
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error!",
          "An error occurred while sending the bill.",
          "error"
        );
      });
  };

  return (
    <Popconfirm
      title="Send Bill On Whatsapp?"
      onConfirm={() => handleDownload()}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary">
        <Tooltip title="Send Bill On Whatsapp">
          <WhatsAppOutlined /> Send Bill
        </Tooltip>
      </Button>
    </Popconfirm>
  );
};

export default SalesReturnBillWB;
