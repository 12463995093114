import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Daga_Impex_Logo.png";
const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
  return (
    <div
      className={`copyright ${spaceBottomClass ? spaceBottomClass : ""} ${
        colorClass ? colorClass : ""
      }`}
    >
      {/* <div className="footer-logo"> */}
        {/* <Link to={process?.env?.PUBLIC_URL + "/"}> */}
          {/**<img alt="" src={process.env.PUBLIC_URL + footerLogo} /> */}
          {/* <img src={logo} alt="Logo" width={125} height={75} /> */}
        {/* </Link> */}
      {/* </div> */}
      <div className=""
      style={{
        fontSize: '26px',
        fontWeight: 500,
      }}
      >
        Need Help ?
      </div>
      <p>© {new Date().getFullYear()} All Rights Reserved</p>
    </div>
  );
};

FooterCopyright.propTypes = {
  footerLogo: PropTypes.string,
  spaceBottomClass: PropTypes.string,
  colorClass: PropTypes.string,
};

export default FooterCopyright;
