import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import HeaderOne from "../../../wrappers/header/HeaderOne";
import BoyImage from "../../../assets/images/boy.png";
import Arrow from "../../../assets/images/Group 137.png";
import ArrowLeft from "../../../assets/images/Group 116.png";
import "./CategoriesPage.css";
import {
  fetchProductsByFiltereing,
  fetchFilterProductSidebarList,
} from "../../../redux/actions/productActions";
import { connect, useDispatch } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

const SubCategoriesCards = (props) => {
  const categories = props?.location?.state?.categories;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Function to get products by style
  const getProductsByStyle = async (catId, styleId) => {
    const obj = {
      category: [catId],
      style: [styleId],
      brand: [],
      color: [],
      size: [],
    };

    const response = await dispatch(
      fetchFilterProductSidebarList({ category: obj.category })
    );

    let styles = null;
    if (response && response?.data?.data?.styleDetails) {
      styles = response?.data;
    }

    // Prepare query params
    const params = new URLSearchParams(location.search);
    params.set("All", true);
    params.set("limit", 1);
    params.set("category", catId);
    params.set("style", styleId);
    params.delete("brand");
    params.delete("color");
    params.delete("size");

    // Navigate to the shop grid page
    history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Style",
        selectTypeData: styles,
        loading: true,
      },
    });

    await dispatch(fetchProductsByFiltereing(obj));
  };

  return (
    <>
      <HeaderOne />

      <div className="breadcrumb-area shadow-sm mb-4">
        <div className="container p-0">
          <div className="breadcrumb-content mx-2 d-flex align-items-center justify-content-start">
            <Link to="/">
              <ArrowLeftOutlined />
            </Link>
            <div className="w-100 breadCrumb-Text ml-3 text-center">
            {categories?.name}
            </div>
          </div>
        </div>
      </div>

      <div className="category-page">
        <div className="category-grid">
          {categories?.styles?.map((category) => (
            <div key={category._id}>
              <Link
                to={process.env.PUBLIC_URL + "/shop-grid-standard"}
                onClick={(e) => {
                  e.preventDefault();
                  getProductsByStyle(categories._id, category._id);
                }}
              >
                <div className="category-card">
                  <img
                    style={{ visibility: "hidden" }}
                    src={BoyImage}
                    alt={category.name}
                  />
                </div>

                <div className="category-name">
                  <span className="card-subCategory">
                    {category.name}{" "}
                  </span>
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Arrow}
                    alt="Arrow"
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  categStyles: state.styleData.categorystyles,
});

export default connect(mapStateToProps)(SubCategoriesCards);

