import { Button, Col, Modal, Row, Form, Select, Tooltip, message } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdatePackagingMethodForBarcode,
  getPackagingMethods,
} from "../../../redux/actions/packageMethodAction";

const { Option } = Select;

const UpdatePackagingModel = ({ record, handlePackaging }) => {
  const dispatch = useDispatch();
  const packagingMethods = useSelector(
    (state) => state.packageMethodReducer.packageMethodList
  );
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    packagingType: "",
    packagingValue: "Collective",
  });

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const obj = {
      ...data,
      barcode: record.barcode,
    };
    const response = await dispatch(UpdatePackagingMethodForBarcode(obj));
    if (response.status === 200) {
      message.success(response?.data?.message);
      handlePackaging({ ...data, barcode: record.barcode });
    }
    setLoading(false);
    setOpen(false);
  };

  const handleModalCancel = () => {
    setOpen(false);
    setData({
      packagingType: "",
      packagingValue: "Collective",
    });
  };

  const handleChangePacakagingType = (key, value) => {
    setData({
      packagingType: value.value,
      packagingValue: data.packagingValue,
    });
  };

  const handleChangePacakagingValue = (key, value) => {
    setData({
      packagingType: data.packagingType,
      packagingValue: value.value,
    });
  };

  // Select Options For Packaging
  const packagingTypeOptions = packagingMethods
    .filter(
      (type, index, array) =>
        array.findIndex((t) => t.packagingType === type.packagingType) === index
    )
    .map((type) => (
      <Option key={type._id} value={type.packagingType}>
        {type.packagingType}
      </Option>
    ));

  let packagingValueOptions = packagingMethods
    .filter(
      (type, index, array) =>
        array.findIndex((t) => t.packagingValue === type.packagingValue) ===
        index
    )
    .map((type) => (
      <Option key={type._id} value={type.packagingValue}>
        {type.packagingValue}
      </Option>
    ));

  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={handleModalOpen}
      >
        Update Packaging Method
      </Button>
      <Row>
        <Col span={24}>
          <div className="add-button"></div>
          <Modal
            open={open}
            onCancel={handleModalCancel}
            title="Update Packaging Method"
            style={{ top: 20 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <Form
              name="add-packaging-method"
              onFinish={handleSubmit}
              layout="vertical"
            >
              <Tooltip title="Select Packaging Type">
                <Form.Item
                  name="packagingType"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Packaging Type !",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Packaging Type"
                    onChange={handleChangePacakagingType}
                    onClick={() => dispatch(getPackagingMethods())}
                  >
                    {packagingTypeOptions}
                  </Select>
                </Form.Item>
              </Tooltip>

              <Tooltip title="Select Packaging Value">
                <Form.Item
                  name="packagingValue"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Packaging Value !",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Packaging Value"
                    onChange={handleChangePacakagingValue}
                    onClick={() => dispatch(getPackagingMethods())}
                  >
                    {packagingValueOptions}
                  </Select>
                </Form.Item>
              </Tooltip>
              <Form.Item className="float-right">
                <Button
                  className="close-modal mr-3"
                  onClick={handleModalCancel}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default UpdatePackagingModel;
