import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  message,
  Upload,
  Spin,
  Form,
  Input,
  DatePicker,
  Tooltip,
  Select,
} from "antd";
import { CloudUploadOutlined, UploadOutlined } from "@ant-design/icons";
import { getActiveandInactiveCustomer } from "../../../redux/actions/customerAction";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleGlobalEcomImage,
  deleteSingleGlobalImage,
  saveEcomBanner,
  getBannerList,
} from "../../../redux/actions/purchaseAction";

import {
  uploadLr,
  getInvoiceIdByCustomer,
} from "../../../redux/actions/saleAction";
import moment from "moment";
import "./style.css";
const { Dragger } = Upload;
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const billTypeLR = {
  typeSales: "storeSales",
  typeEcom: "ecomSales",
};

const UploadLR = ({ invoiceData, showDataOnList }) => {
  const [visible, setVisible] = useState(false);
  const [visibleLoading, setVisibleLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [finalloading, setfinalLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [bannerImage, setbannerImage] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [lrDate, setlrDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [invoiceId, setinvoiceId] = useState([]);
  const [salesBillId, setsalesBillId] = useState([]);
  const [form] = Form.useForm();
  const [lrNo, setlrNo] = useState("");
  const [biltyNo, setbiltyNo] = useState("");
  const [customer, setCustomer] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [InvoiceListCustomer, setInvoiceListCustomer] = useState([{}]);
  const [isTurnedOff, setIsTurnedOff] = useState(false);
  const dispatch = useDispatch();
  const activeAndInactiveCustomers = useSelector(
    (state) => state.customerData.activeandInactiveCustomer
  );
  const showModal = async () => {
    setVisibleLoading(true);
    await dispatch(getActiveandInactiveCustomer());
    setVisibleLoading(false);
    setVisible(true);
  };
  const handleStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const handleEndDate = (date, dateString) => {
    setEndDate(dateString);
  };
  const getMeInvoiceIds = async () => {
    const startDate1 = form.getFieldValue("startDate");
    const startD = startDate1.format("YYYY-MM-DD");
    const endDate = form.getFieldValue("endDate");
    const endD = endDate.format("YYYY-MM-DD");
    const endT = endDate.format("HH:mm:ss");
    const obj = {
      userId: form.getFieldValue("customers"),
      startDate: startD,
      endDate: endD + " " + endT,
    };
    console.log(obj);
    let InvoiceListCustomer = await dispatch(getInvoiceIdByCustomer(obj));
    if (InvoiceListCustomer.length > 0) {
      setInvoiceListCustomer(InvoiceListCustomer);
      setIsTurnedOff(true);
    } else {
      setIsTurnedOff(false);
    }
    console.log(InvoiceListCustomer);
  };
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const handleFormChange = () => {
    const values = form.getFieldsValue();
    setIsSearchEnabled(values.customers && values.startDate && values.endDate);
  };
  const handleReset = () => {
    form.resetFields();
    setIsSearchEnabled(false);
    setIsTurnedOff(false);
  };
  const closeModal = () => {
    form.resetFields();
    setVisible(false);
    setLoading(false);
    setImageUrl(false);
    setbannerImage([]);
    setErrorMsg(null);
  };

  const uploadButton = (
    <Button icon={<UploadOutlined />}>Click to Upload</Button>
  );

  const handleChange = async (e) => {
    const fileList = e?.target?.files;

    const imageFile = fileList?.[0]?.type.startsWith("image/");
    const pdfFile = fileList?.[0]?.type === "application/pdf";

    if (imageFile) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        const image = new Image();
        image.src = imageDataUrl;
        image.onload = async () => {
          setbannerImage(fileList);
          setLoading(true);
          let response = await dispatch(addSingleGlobalEcomImage(fileList[0]));
          if (response && response.status == 200) {
            setImageUrl(response.data.filePath);
            setLoading(false);
          } else {
            setLoading(false);
          }
        };
      };
      reader.readAsDataURL(file);
    }

    if (pdfFile) {
      const file = fileList[0];
      const reader = new FileReader();
      reader.onload = () => {
        const pdfArrayBuffer = reader.result;
        // Upload PDF to API
        dispatch(addSingleGlobalEcomImage(fileList[0]))
          .then((response) => {
            if (response && response.status == 200) {
              setImageUrl(response.data.filePath);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleRemove = async () => {
    setLoading(true);
    let response = await dispatch(
      deleteSingleGlobalImage({ bannerImages: imageUrl })
    );
    setbannerImage([]);
    setImageUrl("");
    setErrorMsg(null);
    if (response && response.status == 200) {
      message.success(response.data);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (imageUrl) {
      setLoading(true);
      let requestObj = {
        lrNumber: lrNo,
        lrDate: lrDate,
        builtyNumber: biltyNo,
        invoiceId: invoiceId,
        pdfUrl: imageUrl,
        builType: billTypeLR.typeSales,
        salesBillsId: salesBillId,
      };
      await dispatch(uploadLr(requestObj))
        .then((respo) => {
          if (respo.status == 200) {
            message.success(respo?.data?.message);
            showDataOnList();
          }
        })
        .finally(() => {
          setLoading(false);
          setImageUrl("");
          setbannerImage([]);
          setVisible(false);
        });
    } else {
      message.warn("Please Upload Image!");
    }
  };

  const onChange = (e) => {
    if (e.target.name === "lrNo") {
      setlrNo(e.target.value);
    } else if (e.target.name === "biltyNo") {
      setbiltyNo(e.target.value);
    }
  };

  const onlrDateChange = (date, dateString) => {
    setlrDate(dateString);
  };

  const onInvoiceChange = (key, value) => {
    let invoiceId = value?.map((obj) => obj.value);
    let saleBillId = value?.map((obj) => obj.name);
    setinvoiceId(invoiceId);
    setsalesBillId(saleBillId);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Button
            className="ok-modal"
            style={{ color: "#ffff" }}
            onClick={() => showModal()}
            loading={visibleLoading}
          >
            <CloudUploadOutlined /> Upload
          </Button>
          <Modal
            visible={visible}
            onCancel={closeModal}
            title="Upload LR & Bilty No"
            style={{ top: 20 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >
            <>
              <Form
                form={form}
                name="add-Style"
                className="add-Style"
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                  remember: true,
                }}
                onValuesChange={handleFormChange}
              >
                <Form.Item
                  name="customers"
                  label="Select Customer"
                  rules={[
                    {
                      required: true,
                      message: "Please Select a customer",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Customer"
                    value={form.getFieldValue("customers")}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(value) => {
                      form.setFieldsValue({ customers: value });
                      setCustomer(value);
                    }}
                  >
                    {activeAndInactiveCustomers?.data?.map((invoiceData) => (
                      <Option name={invoiceData.name} value={invoiceData._id}>
                        {invoiceData.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row gutter={[16, 0]}>
                  <Col xs={24} sm={7} md={7} lg={4} xl={12}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          value={form.getFieldValue("startDate")}
                          onChange={(date, dateString) => {
                            form.setFieldValue({ startDate: dateString });
                          }}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={7} md={7} lg={4} xl={12}>
                    <Tooltip title="Select End Date">
                      <Form.Item
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select End Date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          value={form.getFieldValue("endDate")}
                          onChange={(date, dateString) => {
                            form.setFieldValue({ endDate: dateString });
                          }}
                          placeholder="Select end Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={7} md={7} lg={4} xl={12}>
                    <Form.Item>
                      <Button
                        // type="primary"
                        onClick={getMeInvoiceIds}
                        className="close-modal"
                        block
                        disabled={!isSearchEnabled}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={7} md={7} lg={4} xl={12}>
                    <Form.Item>
                      <Button
                        // type="primary"
                        block
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="lrNo"
                  label="LR No"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter LR No!",
                    },
                  ]}
                >
                  {/* <Tooltip title="LR No"> */}
                  <Input
                    disabled={!isTurnedOff}
                    autoFocus
                    placeholder="LR No"
                    name="lrNo"
                    value={lrNo}
                    type="number"
                    onChange={onChange}
                  />
                  {/* </Tooltip> */}
                </Form.Item>
                {/* <Form.Item
                  name="biltyNo"
                  label="Bilty No"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Bilty No !",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Bilty No"
                    name="biltyNo"
                    type="number"
                    value={biltyNo}
                    onChange={onChange}
                  />
                </Form.Item> */}
                <Form.Item name="lrDate" label="LR Date">
                  {console.log("")}
                  <Tooltip title="LR Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      onChange={onlrDateChange}
                      placeholder="Select LR Date"
                      value={moment(lrDate, dateFormat)}
                      format={dateFormat}
                      showClose={false}
                      disabled={!isTurnedOff}
                    />
                  </Tooltip>
                </Form.Item>
                {console.log("check mohit hello invoice id", invoiceId)}
                <Form.Item
                  name="invoiceId"
                  label="Invoice Ids"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Invoice Id!",
                    },
                  ]}
                >
                  <Select
                    disabled={!isTurnedOff}
                    name="invoiceId"
                    placeholder="Select a Invoice Id"
                    id="Invoice Id"
                    onChange={onInvoiceChange}
                    value={invoiceId}
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    showSearch
                  >
                    {InvoiceListCustomer.map((invoceData) => (
                      <Option
                        value={invoceData.invoiceId}
                        name={invoceData._id}
                      >
                        {invoceData.invoiceId}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="uploadLr"
                  label="Upload LR"
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: "Please Upload LR!",
                  //     },
                  //   ]}
                >
                  <p style={{ color: "red" }}>{errorMsg}</p>
                  {/* {console.log("checkloooooo", bannerImage.length, loading )} */}

                  {/* <Upload {...props}>
                    {bannerImage.length == 0 && !loading ? uploadButton : null}
                  </Upload> */}
                  <input
                    disabled={!isTurnedOff}
                    type="file"
                    onChange={handleChange}
                  />
                </Form.Item>

                <Row gutter={12} className="mt-2">
                  <Col span={24} align="center">
                    {loading ? (
                      <Spin />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="ok-modal"
                        loading={finalloading}
                        // onClick={() => handleSubmit()}
                        disabled={!isTurnedOff}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default UploadLR;
