import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Popconfirm, Skeleton, Table, Tooltip } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { deleteRack, getRacks } from '../../../../redux/actions/rackManagementAction';
import UpdateRack from './UpdateRack';

function RackList({ loading }) {
    const dispatch = useDispatch()
    const rackList = useSelector((e) => e.rackData?.rackList?.data)

    const rackDelete = (id) => {
        dispatch(deleteRack({ id: id }))
            .finally(() => {
                dispatch(getRacks())
            })
    }

    return (
        <>
            {
                loading
                    ?
                    <Skeleton />
                    :
                    <div className='my-3'>
                        <Table
                            dataSource={rackList || []}
                            bordered
                            size="middle"
                            pagination={false}
                            rowKey="id"
                        >
                            <Table.Column
                                title="SRN"
                                key="srn"
                                render={(_, __, index) => index + 1}
                            />

                            <Table.Column
                                title="Rack Name"
                                dataIndex="rack"
                                key="rack"
                            />

                            <Table.ColumnGroup title="Columns" key="columns-group">
                                <Table.Column
                                    title="Name"
                                    key="name"
                                    className="my-1"
                                    render={(record) =>
                                        Array.isArray(record?.columns) ? (
                                            <table style={{ width: "100%", borderSpacing: "5px" }}>
                                                <tbody>
                                                    {record.columns.map((item) => (
                                                        <tr key={item.id}>
                                                            <td style={{ border: "1px solid #ddd", padding: "4px" }}>
                                                                {item.column}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : null
                                    }
                                />
                                <Table.Column
                                    title="Capacity"
                                    key="capacity"
                                    className="my-1"
                                    render={(record) =>
                                        Array.isArray(record?.columns) ? (
                                            <table style={{ width: "100%", borderSpacing: "5px" }}>
                                                <tbody>
                                                    {record.columns.map((item) => (
                                                        <tr key={item.id}>
                                                            <td style={{ border: "1px solid #ddd", padding: "4px", textAlign: "center" }}>
                                                                {item.capacity}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : null
                                    }
                                />
                            </Table.ColumnGroup>


                            <Table.Column
                                title="Action"
                                key="action"
                                render={(_, record) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            gap: '8px',
                                        }}
                                    >
                                        <a>
                                            <UpdateRack id={record?._id} />
                                        </a>

                                        <Popconfirm
                                            title="Are you sure you want to delete this rack?"
                                            onConfirm={() => rackDelete(record._id)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Tooltip title="Delete Rack">
                                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
                                            </Tooltip>
                                        </Popconfirm>
                                    </div>
                                )}
                            />
                        </Table>

                    </div>
            }
        </>
    )
}

export default RackList