import { Button, Col, Modal, Row, Form, Input, Radio, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  updatePackagingMethod,
  getPackagingMethods,
} from "../../../redux/actions/packageMethodAction";
import { EditOutlined } from "@ant-design/icons";
import { set } from "lodash";

const UpdatePackagingMethod = ({ record }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [data, setData] = useState({
    packagingType: "",
    packagingValue: "",
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("Form values:", values);
    const reqObj = {
        id: record._id,
        ...values
    }
    const response = await dispatch(updatePackagingMethod(reqObj));
    if (response.status === 200) {
      await dispatch(getPackagingMethods());
    }
    setLoading(false);
    handleModalCancel();
  };

  const handleModalOpen = () => {
    setData({
        packagingType: record.packagingType,
        packagingValue: record.packagingValue,
      });
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
    setData({
      packagingType: "",
      packagingValue: "",
    });
  };

  return (
    <>
      <button
        className="btn"
        type="button"
        data-original-title=""
        onClick={handleModalOpen}
      >
        <Tooltip title="Update User">
          <EditOutlined />
        </Tooltip>
      </button>
      <Modal
        open={visible}
        onCancel={handleModalCancel}
        title="Update Packaging Method"
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="update-packaging-method"
          initialValues={data}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Packaging Type"
            name="packagingType"
            rules={[
              {
                required: true,
                message: "Please enter packaging type!",
              },
            ]}
          >
            <Input autoFocus placeholder="Enter packaging type" />
          </Form.Item>

          <Form.Item
            label="Packaging Value"
            name="packagingValue"
            rules={[
              {
                required: true,
                message: "Please select packaging value!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="Collective">Collective</Radio>
              <Radio value="Individual">Individual</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item className="float-right">
            <Button className="close-modal mr-3" onClick={handleModalCancel}>
              Close
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="ok-modal"
              loading={loading}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatePackagingMethod;
