import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import {
  DeleteOutlined,
  FileDoneOutlined,
  SearchOutlined,
  EditOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSalesReturns, getSalesReturnsBydate } from "../../../../redux/actions/saleAction.js";
import Highlighter from "react-highlight-words";
import moment from "moment";
import DateWiseSearch from "../../../../util/DateWiseSearch.js";
import TopBarProgress from "react-topbar-progress-indicator";
const { Column } = Table;

class SalesReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      startDate : moment(new Date()).format("YYYY-MM-DD"),
      endDate : moment(new Date()).format("YYYY-MM-DD")
    };
  }
  componentDidMount() {
    let startDate = moment(new Date()).format("YYYY-MM-DD")
    let endDate = moment(new Date()).format("YYYY-MM-DD")
    let reportObj = {
      startDate: startDate,
      endDate: endDate + " " + moment().format("HH:mm:ss"),
      limit:1
    };
    // this.props.getSalesReturns(1);
    this.props.getSalesReturnsBydate(reportObj)
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSetDates = (date) => {
    console.log("checkDate-----------------", date);
    this.setState({
      startDate: date.startDate,
      endDate: date.endDate,
    })
  }
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  listData=()=>{
    const { salesreturnBydate } = this.props
    const value  = salesreturnBydate && salesreturnBydate.length > 0 ? salesreturnBydate[0] : [] 
    if(value.message == "Empty" ){
      return([])
    }else if(salesreturnBydate.data){
      return(salesreturnBydate.data)
    }else{
      return(this.props.saleReturns.data)
    }
    
  }

  render() {
    console.log("#saleReturns", this.props.saleReturns);
    const { salesreturnBydate } = this.props
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Sales Returns</div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch
                keyId={"Sales Returns"}
                handleSetDates={this.handleSetDates}
              />
            </Col>
          </Row>
          {/* ======================================== */}
          <div className="card">
            <div className="card-body">
              <Table
                // dataSource={this.listData()}
                dataSource={salesreturnBydate.data || []}
                bordered="true"
                size="middle"
                pagination={{
                  pageSize: 40,
                  total:
                    salesreturnBydate && salesreturnBydate.count
                      ? salesreturnBydate.count
                      : "",
                  onChange: (page) => {
                    const obj = {
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      limit: page,
                    };
                    this.props.getSalesReturnsBydate(obj);
                  },
                }}
                summary={() => (
                  <Table.Summary fixed="bottom">
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        className="grand-Total-summary"
                        index={1}
                        colSpan={2}
                      >
                        Grand Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} colSpan={3}>
                        {}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} colSpan={1}>
                        {salesreturnBydate?.summary?.totalQuantity || 0}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} colSpan={1}>
                        {salesreturnBydate?.summary?.totalAmount || 0}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
                scroll={{ y: 400 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Bill Date"
                  dataIndex="date"
                  key="date"
                  width={90}
                  render={(value, item, index) =>{
                    console.log("item.date", item.created_at)
                    return moment(item.created_at).format("YYYY-MM-DD")
                  }
                }
                />
                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width={100}
                  {...this.getColumnSearchProps("barcode")}
                />
                <Column
                  title="Customer Name"
                  // {...this.getColumnSearchProps("dealerName")}
                  dataIndex="dealerName"
                  key="dealerName"
                  width={150}
                  render={(value, item, index) => `${item.userId.name}`}
                />
                <Column
                  title="Invoice No."
                  dataIndex="invoiceId"
                  {...this.getColumnSearchProps("invoiceId")}
                  key="invoiceId"
                  render={(value, item, index) => `S-${item.invoiceId}`}
                  width={115}
                />

                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={100}
                />

                <Column
                  title="Price"
                  dataIndex="price"
                  key="price"
                  width={120}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SalesReturns.propTypes = {
  getSalesReturns: PropTypes.func.isRequired,
  getSalesReturnsBydate : PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saleReturns: state.saleData.saleReturns,
  salesreturnBydate: state.saleData.salesreturnBydate,
  progressBarStatus : state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getSalesReturns,getSalesReturnsBydate
})(SalesReturns);
