import { Col, Popconfirm, Row, Table, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deletePackagingMethod, getPackagingMethods } from "../../../redux/actions/packageMethodAction";
import { DeleteOutlined } from "@ant-design/icons";
import UpdatePackagingMethod from "./UpdatePackagingMethod";

const {Column}  = Table;
const PackagingMethodList = () => {
  const dispatch = useDispatch();
  const packageMethodList = useSelector(
    (state) => state.packageMethodReducer.packageMethodList
  );

  useEffect(() => {
    dispatch(getPackagingMethods());
  }, []);

  const onDeleteClick = async (id) => {
    const reponse = await dispatch(deletePackagingMethod({ id:id }));
    if(reponse?.status === 200){
      await dispatch(getPackagingMethods());
    }
    console.log("reponse-------------------", reponse);
  }

  return (
    <div className="subCategory-dashboard">
      <div className="container-fluid category-list">
        <div className="card mt-4 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={packageMethodList || []}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Packaging Type"
                dataIndex="packagingType"
                key="packagingType"
                width={140}
              />
              <Column
                title="Packaging Value"
                dataIndex="packagingValue"
                key="packagingValue"
                width={140}
              />
              <Column
                  title="Action"
                  key="action"
                  width={150}
                  render={(text, record) => (
                    <div>
                      <Row gutter={[8, 0]} align="center">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <a>
                            <UpdatePackagingMethod record={text} />
                          </a>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Popconfirm
                            title="Are you sure to delete this Packaging Method?"
                            onConfirm={() => onDeleteClick(text._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <Tooltip title="Delete Method">
                                <DeleteOutlined />
                              </Tooltip>
                            </div>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </div>
                  )}
                />
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagingMethodList;
