import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    Row,
    Col,
    Upload,
    Button,
    Form,
    Input,
    Select,
    Modal,
    Tooltip,
    message,
    Card
} from "antd";
import moment from "moment";
import { EditOutlined, UploadOutlined} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getDealers } from '../../../../../redux/actions/dealerAction';
import { getBrandBySearch, getBrands } from '../../../../../redux/actions/brandAction';
import { getCategories } from '../../../../../redux/actions/categoryAction';
import { getStyles } from '../../../../../redux/actions/styleAction';
import { getGroups } from '../../../../../redux/actions/groupAction';
import { getSections } from '../../../../../redux/actions/sectionAction';
import { getLocations } from '../../../../../redux/actions/locationAction';
import { getSizes } from '../../../../../redux/actions/sizeAction';
import { getColors } from '../../../../../redux/actions/colorAction';
import { getAgeGroups } from '../../../../../redux/actions/ageGroupAction';
import { getFloors } from '../../../../../redux/actions/floorAction';
import { getLookupData } from '../../../../../redux/actions/purchaseAction';
import { getTexture } from '../../../../../redux/actions/textureAction';
import { getCustomers } from '../../../../../redux/actions/customerAction';
import { getSeasons } from '../../../../../redux/actions/seasonAction';
import { addSingleGlobalEcomImage } from "../../../../../redux/actions/purchaseAction";

const dateFormat = "DD/MM/YYYY";
const { Option } = Select;


function PurchaseOrderGenerateBillUpdate({ saveClicked, data, updatedData, clickOnSave }) {
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false)
    const [boxes, setBoxes] = useState(data.quantity / data.MOQ)
    const [moqValidate, setMoqValidate] = useState(false)
    const [quantityExceed, setQuantityExceed] = useState(false)
    const [formIsValid, setFormIsValid] = useState(false);
    const createFormObject = (data) => ({
        _id: data._id,
        dealerName: data.dealerName,
        dealerId: data.dealerId,
        billNo: data.billNo,
        billDate: data.billDate,
        gst: data.gst,
        designNumber: data.designNumber,
        brand: data.brand || undefined,
        brandId: data.brandId,
        markup: data.markup,
        category: data.category || undefined,
        categoryId: data.categoryId,
        group: data.group || undefined,
        groupId: data.groupId,
        purchasePersonId: data.purchasePersonId,
        purchasePersonName: data.purchasePersonName,
        style: data.style || undefined,
        styleId: data.styleId,
        season: data.season || undefined,
        seasonId: data.seasonId,
        section: data.section,
        sectionId: data.sectionId,
        location: data.location || "",
        locationId: data.locationId,
        floorId: data.floorId,
        floorName: data.floorName || undefined,
        ageGroupId: data.ageGroupId,
        ageGroupName: data.ageGroupName || undefined,
        HSNCode: data.HSNCode,
        MOQ: data.MOQ,
        size: data.size,
        purchasePrice: data.purchasePrice,
        color: data.color,
        quantity: data.quantity,
        wsp: data.wsp,
        others: data.others,
        discount: data.discount,
        totalBox: data.totalBox,
        barcode: data.barcode,
        poId: data.poId,
        textureId: data.textureId.map((value) => value),
        textureName: data.textureName.map((value) => value),
        isPurchaseCompleted: data.isPurchaseCompleted,
        created_at: data.created_at,
        isOncePurchased: data?.isOncePurchased,
        __v: data.__v,
        imagePath : data?.imagePath
        
    });

    const [form, setForm] = useState(createFormObject(data));

    useEffect(() => {
        setForm(createFormObject(data));
    }, [data, visible]);


    useEffect(() => {
        dispatch(getDealers())
        dispatch(getCategories())
        dispatch(getBrands())
        dispatch(getGroups())
        dispatch(getSeasons())
        dispatch(getSections())
        dispatch(getLocations())
        dispatch(getSizes())
        dispatch(getColors())
        dispatch(getAgeGroups())
        dispatch(getFloors())
        dispatch(getTexture())
        dispatch(getCustomers("PURCHASER"))
    }, []);

    useEffect(() => {
        const Quantity = form.quantity
        const MOQ = form.MOQ

        const value = Quantity / MOQ;
        setBoxes(value)
    }, [data, form.quantity, form.MOQ])


    useEffect(() => {
        const Other = form.others
        const MarkUp = form.markup
        // const Quantity = form.quantity
        const Price = form.purchasePrice


        const priceAfterMarkupCal = (Price * MarkUp) / 100;
        const calculateWSP = Price + priceAfterMarkupCal + Other;
        setForm((prevState) => ({
            ...prevState,
            wsp: calculateWSP
        }))
    }, [form.others, form.markup, form.purchasePrice])

    const brandsList = useSelector((state) => state.brandsData.brands)
    const categoryList = useSelector((state) => state.categoryData.categories)
    const styleList = useSelector((state) => state.styleData.styles)
    const groupsList = useSelector((state) => state.groupData.groups)
    const seasonsList = useSelector((state) => state.seasonData.seasons)
    const sectionsList = useSelector((state) => state.sectionData.sections)
    const locationList = useSelector((state) => state.locationData.locations)
    const sizesList = useSelector((state) => state.sizeData.sizes)
    const colorsList = useSelector((state) => state.colorData.colors)
    const ageGroupList = useSelector((state) => state.ageGroupData.ageGroups)
    const floorsList = useSelector((state) => state.floorData.floors)
    const textureList = useSelector((state) => state.textureData.textures?.data)
    const customerList = useSelector((state) => state.customerData.salesPerson)


    const showModal = () => {
        setVisible(true)
    }

    const handleModalCancel = () => {
        setVisible(false)
    }

    const handleDesignNumber = (e) => {
        const value = e.target.value;
        setForm((prevState) => ({
            ...prevState,
            designNumber: value
        }));
    }

    const handleBrand = (value) => {
        const selectedBrand = brandsList.find((item) => item._id === value);
        if (selectedBrand) {
            setForm((prevState) => ({
                ...prevState,
                brandId: selectedBrand._id,
                brand: selectedBrand.name,
                markup: selectedBrand.markup
            }));
        }
    }

    const handleMarkUpValue = (e) => {
        const value = e.target.value
        setForm((prevState) => ({
            ...prevState,
            markup: value
        }))
    }
    const handleCategory = async (value) => {
        await dispatch(getStyles(value))
        const selectedCategory = categoryList.find((item) => item._id === value);
        if (selectedCategory) {
            setForm((prevState) => ({
                ...prevState,
                categoryId: selectedCategory._id,
                category: selectedCategory.name,
                styleId: "",
                style: "",
            }));
        }
    }

    const handleStyle = (value) => {
        const selectedStyle = styleList.find((item) => item._id === value);
        if (selectedStyle) {
            setForm((prevState) => ({
                ...prevState,
                styleId: selectedStyle._id,
                style: selectedStyle.name,
            }));
        }
    }

    const handleGroup = (value) => {
        const selectedGroup = groupsList.find((item) => item._id === value);
        if (selectedGroup) {
            setForm((prevState) => ({
                ...prevState,
                groupId: selectedGroup._id,
                group: selectedGroup.name,
            }));
        }
    }

    const handleSeason = (value) => {
        const selectedSeason = seasonsList.find((item) => item._id === value);
        if (selectedSeason) {
            setForm((prevState) => ({
                ...prevState,
                seasonId: selectedSeason._id,
                season: selectedSeason.name,
            }));
        }
    }

    const handleSection = (value) => {
        const selectedSection = sectionsList.find((item) => item._id === value);
        if (selectedSection) {
            setForm((prevState) => ({
                ...prevState,
                sectionId: selectedSection._id,
                section: selectedSection.name,
            }));
        }
    }

    const handleHSN = (e) => {
        const value = e.target.value
        setForm((prevState) => ({
            ...prevState,
            HSNCode: value
        }))
    }

    const handleOthers = (e) => {
        const value = e.target.value
        setForm((prevState) => ({
            ...prevState,
            others: Number(value)
        }))
    }

    const handleLocation = (value) => {
        const selectedLocation = locationList.find((item) => item._id === value);
        if (selectedLocation) {
            setForm((prevState) => ({
                ...prevState,
                location: selectedLocation.name,
                locationId: selectedLocation._id,
            }));
        }
    }

    const handleDiscountChange = (e) => {
        const value = e.target.value;
        if (value === "") {
            setForm((prevState) => ({
                ...prevState,
                discount: 0
            }));
        } else {
            setForm((prevState) => ({
                ...prevState,
                discount: Number(value)
            }));
        }
    };

    const handleFloor = (value) => {
        const selectedFloor = floorsList.find((item) => item._id === value);
        if (selectedFloor) {
            setForm((prevState) => ({
                ...prevState,
                floorId: selectedFloor._id,
                floorName: selectedFloor.name,
            }));
        }
    }

    const getTextureNameById = (id) => {
        const texture = textureList?.find((option) => option._id === id);
        return texture ? texture.name : null;
    };

    const handleTexture = (values) => {
        const finalTextureNames = values.map(id => getTextureNameById(id)).filter(name => name);

        setForm((prevState) => ({
            ...prevState,
            textureId: values,
            textureName: finalTextureNames,
        }));
    };

    const handleAgeGroup = (value) => {
        const selectedAgeGroup = ageGroupList.find((item) => item._id === value);
        if (selectedAgeGroup) {
            setForm((prevState) => ({
                ...prevState,
                ageGroupId: selectedAgeGroup._id,
                ageGroupName: selectedAgeGroup.name,
            }));
        }
    }

    const handleSize = (value) => {
        const selectedSize = sizesList.find((item) => item._id === value);
        if (selectedSize) {
            setForm((prevState) =>
            ({
                ...prevState,
                size: selectedSize.name,
            })
            );
        }
    };

    const handlePrice = (e) => {
        const value = Number(e.target.value);
        if (value !== undefined && value !== null) {
            setForm((prevState) =>
            ({
                ...prevState,
                purchasePrice: value,
            })
            );
        }
        // calculateWSP(componentId);
    };

    const handleColor = (value) => {
        const selectedColor = colorsList.find((item) => item._id === value);
        if (selectedColor) {
            setForm((prevState) =>
            ({
                ...prevState,
                color: selectedColor.name,
            })
            );
        }
    };

    const handleMOQ = (e, componentId) => {
        const value = Number(e.target.value);
        if (value !== undefined && value !== null) {
            setForm((prevState) =>
            ({
                ...prevState,
                MOQ: value,
            })
            );
        }
    };

    const handleQuantity = (e) => {
        const value = Number(e.target.value);
        if (value > data?.quantity) {
            setQuantityExceed(true)
        } else {
            setQuantityExceed(false)
        }

        if (value !== undefined && value !== null) {
            setForm((prevState) => ({
                ...prevState,
                quantity: value,
            }));

            if (value % form.MOQ === 0) {
                setMoqValidate(true);
            } else {
                setMoqValidate(false);
            }
        }
    };


    const handleSubmit = () => {

        if (form.quantity < 0) {
            message.warning("Quantity should be greater than 0");
            return;
        }

        if (form.quantity % form.MOQ != 0) {
            message.warn("Quantity must be divisible of MOQ!")
            return;
        }

        if (!quantityExceed) {
            clickOnSave(form._id)
            updatedData(form);
            setVisible(false);
            setForm({
                _id: "",
                dealerName: "",
                dealerId: "",
                billNo: "",
                billDate: "",
                gst: "",
                designNumber: "",
                brand: "",
                brandId: "",
                markup: "",
                category: "",
                categoryId: "",
                group: "",
                groupId: "",
                purchasePersonId: "",
                purchasePersonName: "",
                style: "",
                styleId: "",
                season: "",
                seasonId: "",
                section: "",
                sectionId: "",
                location: "",
                locationId: "",
                floorId: "",
                floorName: "",
                ageGroupId: "",
                ageGroupName: "",
                HSNCode: "",
                MOQ: "",
                size: "",
                purchasePrice: "",
                color: "",
                quantity: "",
                wsp: "",
                others: "",
                discount: "",
                totalBox: "",
                barcode: "",
                poId: "",
                textureId: [],
                textureName: [],
                isPurchaseCompleted: "",
                created_at: "",
                __v: "",
                imagePath : ""
            });
        } else {
            message.warning(`You can not Purchase more than ${data.quantity} Quantity`)
        }
    };

    // Image Upload 
const handleUploadChange = async (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      await dispatch(addSingleGlobalEcomImage(file)).then((response) => {
        if (response.status == 200) {
          let imageUrl = response?.data?.filePath || "";
          setForm((prevState) =>
            ({
                ...prevState,
                imagePath: imageUrl,
            })
            );
        }
      });
    }
  };
  

    return (
        <>
            <Button
                className="btn d-flex align-items-center"
                type="button"
                data-original-title=""
                onClick={showModal}
                disabled={data.quantity === 0 || saveClicked}
            >
                <Tooltip title="Purchase Order Generate Bill">
                    <EditOutlined />
                </Tooltip>
            </Button>
            <Modal
                visible={visible}
                onCancel={handleModalCancel}
                title="Purchase Order Generate Bill"
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                width={1300}
            >
                <Card>
                <Form
  onFinish={handleSubmit}
  initialValues={{
    'upload Image': [
      {
        uid: '-1',
        name: 'image.jpg',
        status: 'done',
        url: form.imagePath,
      },
    ],
    ...form
  }}
>
                        <Row gutter={24}>
                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter GST Number">
                                    <Form.Item
                                        name="gst"
                                        rules={[{ required: true, message: 'GST Number is required' }]}
                                    >
                                        <Input
                                            value={form.gst}
                                            placeholder="Enter GST Number"
                                            disabled
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Design Number">
                                    <Form.Item
                                        name="designNumber"
                                        rules={[{ required: true, message: "Design Number is required" }]}
                                    >
                                        <Input
                                            value={form.designNumber}
                                            onChange={handleDesignNumber}
                                            placeholder="Enter Design Number"
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Brand">
                                    <Form.Item
                                        name="brand"
                                        rules={[{ required: true, message: 'Brand is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form?.brand ? form.brand : undefined}
                                            placeholder="Select Brand"
                                            onChange={(value) => handleBrand(value)}
                                            options={brandsList?.map(brand => ({
                                                value: brand._id,
                                                label: brand.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>



                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Mark Up Value">
                                    <Form.Item
                                        name="markup"
                                        rules={[{ required: true, message: 'Mark Up Value is required' }]}
                                    >
                                        <Input
                                            value={form.markup}
                                            onChange={handleMarkUpValue}
                                            placeholder="Enter Mark Up Value"
                                            type="number"
                                            min={0}
                                            max={100}
                                            disabled={data?.isOncePurchased}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Category">
                                    <Form.Item
                                        name="category"
                                        rules={[{ required: true, message: 'Category is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.category || undefined}
                                            placeholder="Select Category"
                                            onChange={(value) => handleCategory(value)}
                                            options={categoryList?.map(category => ({
                                                value: category._id,
                                                label: category.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Style">
                                    <Form.Item
                                        name="style"
                                        rules={[{ required: true, message: 'Style is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.style || undefined}
                                            placeholder="Select Style"
                                            onChange={(value) => handleStyle(value)}
                                            options={styleList?.map(style => ({
                                                value: style._id,
                                                label: style.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Group">
                                    <Form.Item
                                        name="group"
                                        rules={[{ required: true, message: 'Group is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.group || undefined}
                                            placeholder="Select Group"
                                            onChange={(value) => handleGroup(value)}
                                            options={groupsList?.map(group => ({
                                                value: group._id,
                                                label: group.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Season">
                                    <Form.Item
                                        name="season"
                                        rules={[{ required: true, message: 'Season is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.season || undefined}
                                            placeholder="Select Season"
                                            onChange={(value) => handleSeason(value)}
                                            options={seasonsList?.map(season => ({
                                                value: season._id,
                                                label: season.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
            
                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Section">
                                    <Form.Item
                                        name="section"
                                        rules={[{ required: true, message: 'Section is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.section || undefined}
                                            placeholder="Select Section"
                                            onChange={(value) => handleSection(value)}
                                            options={sectionsList?.map(section => ({
                                                value: section._id,
                                                label: section.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter HSN Code">
                                    <Form.Item
                                        name="HSNCode"
                                        rules={[{ required: true, message: 'HSN Code is required' }]}
                                    >
                                        <Input
                                            value={form.HSNCode}
                                            onChange={handleHSN}
                                            placeholder="Enter HSN Code"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Others">
                                    <Form.Item
                                        name="others"
                                        rules={[{ required: true, message: 'Other details are required' }]}
                                    >
                                        <Input
                                            min={0}
                                            value={form.others}
                                            onChange={handleOthers}
                                            placeholder="Enter Others"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Location">
                                    <Form.Item
                                        name="location"
                                        rules={[{ required: true, message: 'Location is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.location || undefined}
                                            placeholder="Select Location"
                                            onChange={(value) => handleLocation(value)}
                                            options={locationList?.map(location => ({
                                                value: location._id,
                                                label: location.name,
                                            }))}
                                            disabled
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Discount">
                                    <Form.Item
                                        name="discount"
                                        rules={[{ required: true, message: 'Discount is required' }]}
                                    >
                                        <Input
                                            min={0}
                                            max={100}
                                            value={form.discount}
                                            addonBefore="%"
                                            type="number"
                                            placeholder="Enter Discount (%)"
                                            onChange={(e) => handleDiscountChange(e)}
                                            disabled={data?.isOncePurchased}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Floor">
                                    <Form.Item
                                        name="floorName"
                                        rules={[{ required: true, message: 'Floor is required' }]}
                                    >
                                        <Select
                                            value={form.floorName || undefined}
                                            showSearch
                                            placeholder="Select Floor"
                                            onChange={(value) => handleFloor(value)}
                                            options={floorsList?.map(floor => ({
                                                value: floor._id,
                                                label: floor.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Texture">
                                    <Form.Item
                                        name="textureId"
                                        rules={[{ required: true, message: 'Texture is required' }]}
                                    >
                                        <Select
                                            value={form.textureId || []}
                                            showSearch
                                            mode="multiple"
                                            placeholder="Select Texture"
                                            onChange={handleTexture}
                                            options={textureList?.map(texture => ({
                                                value: texture._id,
                                                label: texture.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Age Group">
                                    <Form.Item
                                        name="ageGroupName"
                                        rules={[{ required: true, message: 'Age Group is required' }]}
                                    >
                                        <Select
                                            value={form.ageGroupName || undefined}
                                            showSearch
                                            placeholder="Select Age Group"
                                            onChange={(value) => handleAgeGroup(value)}
                                            options={ageGroupList?.map(ageGroup => ({
                                                value: ageGroup._id,
                                                label: ageGroup.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>


                            <Col sm={22} md={6} lg={5} className='w-100'>
                                <Tooltip title="Enter Size">
                                    <Form.Item
                                        name="size"
                                        rules={[{ required: true, message: 'Size is required' }]}
                                    >
                                        <Select
                                            showSearch
                                            value={form.size}
                                            placeholder="Select Size"
                                            onChange={(value) => handleSize(value)}
                                            options={sizesList?.map(size => ({
                                                value: size._id,
                                                label: size.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Price">
                                    <Form.Item
                                        name="purchasePrice"
                                        rules={[{ required: true, message: 'Please enter the price!' }]}
                                    >
                                        <Input
                                            min={0}
                                            value={form.purchasePrice}
                                            onChange={(e) => handlePrice(e)}
                                            placeholder="Enter Price"
                                            type="number"
                                            disabled={data?.isOncePurchased}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter MOQ">
                                    <Form.Item
                                        name="MOQ"
                                        rules={[{ required: true, message: 'Please enter the MOQ!' }]}
                                    >
                                        <Input
                                            min={1}
                                            max={data?.quantity}
                                            value={form.MOQ}
                                            onChange={handleMOQ}
                                            placeholder="Enter MOQ"
                                            type="number"
                                            disabled={data?.isOncePurchased}
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter WSP">
                                    <Form.Item
                                        name="wsp"
                                        rules={[{ required: true, message: 'Please enter the WSP!' }]}
                                    >
                                        <Input
                                            value={form.wsp}
                                            placeholder="Enter WSP"
                                            type="number"
                                            disabled
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col sm={22} md={6} lg={6} className='w-100'>
                                <Tooltip title="Enter Color">
                                    <Form.Item
                                        name="color"
                                        rules={[{ required: true, message: 'Please select a color!' }]}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Select Color"
                                            value={form.color}
                                            onChange={(value) => handleColor(value)}
                                            options={colorsList.map((color) => ({
                                                value: color._id,
                                                label: color.name,
                                            }))}
                                            disabled={data?.isOncePurchased}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Quantity">
                                    <Form.Item
                                        name="quantity"
                                        rules={[{ required: true, message: 'Please enter the quantity!' }]}
                                    >
                                        <Input
                                            type="number"
                                            value={form.quantity || ""}
                                            onChange={(e) => handleQuantity(e)}
                                            placeholder="Quantity"
                                            max={data?.quantity}
                                            onWheel={(e) => e.target.blur()}
                                            min={form.MOQ}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col className='w-100' sm={12} md={6} lg={6}>
                                <Tooltip title="Enter Boxes">
                                    <Form.Item
                                    // rules={[{ required: true, message: 'Please enter the number of boxes!' }]}
                                    >
                                        <Input
                                            value={boxes}
                                            disabled
                                            placeholder="Boxes"
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                        />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col className='w-100' sm={12} md={6} lg={6}>
                            <Tooltip title="Upload Image">
                        <Form.Item
                        name="upload Image"
                          valuePropName="fileList"
                          getValueFromEvent={(e) =>
                            Array.isArray(e) ? e : e?.fileList
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please upload an image!",
                            },
                          ]}
                        >
                          <Upload
                            // loading={imageLoading}
                            disabled={data?.isOncePurchased}
                            maxCount={1}
                            accept="image/*"
                            beforeUpload={() => false}
                            listType="picture"
                            defaultFileList={[
                                {
                                  uid: '-1',
                                  name: 'image.jpg',
                                  status: 'done',
                                  url: form.imagePath
                                },
                              ]}
                            onChange={(file) =>
                              handleUploadChange(file,)
                            }
                            // onRemove={(file)=>handleDeleteImage(file)}
                          >
                            <Button icon={<UploadOutlined />}>
                              Upload Image
                            </Button>
                          </Upload>
                          </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col className='w-100'>
                                <Button className="w-100 bg-primary text-white" htmlType="submit">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Modal>
        </>
    );
}
export default PurchaseOrderGenerateBillUpdate
