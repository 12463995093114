import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { WhatsAppOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  salesBillDetails,
  sendMessageOnWhatsapp,
  uploadImage,
} from "../../../redux/actions/saleAction";
import moment from "moment";
import logo from "../../../assets/images/Daga_Impex_Logo.png";
import QR from "../../../assets/images/QR.png";
import { Button, Popconfirm } from "antd";
import Swal from "sweetalert2";

const DownloadSalePDF = ({ invoiceId, record, disabled }) => {
  console.log("record", record);
  const dispatch = useDispatch();

  const handleDownload = async () => {
    const data = await dispatch(salesBillDetails({ invoiceId: invoiceId }));

    const doc = new jsPDF({
      compress: true,
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(14);
    doc.text("JAY SHREE KRISHNA", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Estimate", doc.internal.pageSize.getWidth() / 2, 33, {
      align: "center",
    });

    doc.addImage(logo, "PNG", 12, 10, 30, 15);
    doc.setFont("helvetica", "normal");
    doc.setLineWidth(0.5);
    doc.line(10, 38, doc.internal.pageSize.getWidth() - 10, 38);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`${record?.userId?.name || ""}`, 12, 45, {
      align: "left",
    });
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Date:", 12, 53, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${moment(record?.date || "").format("DD/MM/YY H:mm")}`, 25, 53, {
      align: "left",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Agent Name:", 12, 58, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${record?.userId?.agentId?.name || ""}`, 40, 58, {
      align: "left",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Invoice Number:", 12, 63, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${invoiceId || ""}`, 45, 63, {
      align: "left",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Remark:", 12, 68, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${record?.remark || ""}`, 30, 68, {
      align: "left",
    });

    let tQuant = 0;
    let tAmount = 0;

    const tableData = data?.data?.salesItems?.map((item, index) => {
      const quantity = item?.quantity || 0;
      const wsp = item?.wsp || 0;
      const amount = quantity * wsp;

      tQuant += quantity;
      tAmount += amount;

      return [
        index + 1,
        item?.brand || "",
        item?.designNumber || "",
        item?.color || "",
        Number(item?.quantity / item?.MOQ) || "",
        item?.MOQ || "",
        item?.size || "",
        quantity,
        wsp,
        amount,
      ];
    });

    const tableData2 = [
      ...tableData,
      ["", "Total:", "", "", "", "", "", tQuant, "", tAmount],
    ];

    doc.autoTable({
      head: [
        [
          "Sl No.",
          "Brand",
          "D-Number",
          "Color",
          "Set",
          "MOQ",
          "Size",
          "Quantity",
          "Rate",
          "Amount",
        ],
      ],
      body: tableData2,
      didParseCell: function (data) {
        if (data.row.index === tableData2.length - 1) {
          console.log(data.cell);
          data.cell.styles.fontStyle = "bold";
        }
      },
      theme: "grid",
      startY: 77,
      styles: {
        fontSize: 8,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 9,
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [0, 0, 0],
        fillColor: [200, 200, 200],
        halign: "center",
      },
      columnStyles: {
        0: { columnWidth: 10 },
        1: { columnWidth: 30 },
        2: { columnWidth: 19 },
        3: { columnWidth: 19 },
        4: { columnWidth: 15 },
        5: { columnWidth: 15 },
        6: { columnWidth: 19 },
        7: { columnWidth: 19 },
        8: { columnWidth: 19 },
        9: { columnWidth: 19 },
      },
    });

    const finalY = doc.autoTable.previous.finalY;
    console.log("data", data?.data?.billDetails);
    const grAmount = data?.data?.billDetails?.grAmountUsed;
    const discount = data?.data?.billDetails?.discount;
    const totalAmount = data?.data?.billDetails?.totalAmount;
    const discountedAmount = (totalAmount * discount) / 100;
    const tax = data?.data?.billDetails?.tax;
    const taxAmount = ((totalAmount - discountedAmount) * tax) / 100;
    const pAndF = data?.data?.billDetails?.pAndf;
    const frieght = data?.data?.billDetails?.frieght || 0;
    const labour = data?.data?.billDetails?.labour;
    const netAmount = data?.data?.billDetails?.netAmount;

    let summaryTableData =
      Math.ceil(discountedAmount) > 0
        ? [
            ["GR Amount", grAmount],
            ["Discount", Math.ceil(discountedAmount)],
            ["TAX", Math.ceil(taxAmount)],
            ["P&F", pAndF],
            ["Frieght", frieght],
            ["Labour", labour],
            ["Net Amount", netAmount],
          ]
        : [
            ["GR Amount", grAmount],
            ["TAX", Math.ceil(taxAmount)],
            ["P&F", pAndF],
            ["Frieght", frieght],
            ["Labour", labour],
            ["Net Amount", netAmount],
          ];

    doc.autoTable({
      body: summaryTableData,
      theme: "grid",
      startY: finalY,
      margin: { left: 160 },
      styles: {
        fontSize: 8,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        cellPadding: 1,
      },
      columnStyles: {
        0: { columnWidth: 19, fillColor: [200, 200, 200], fontStyle: "bold" },
        1: { columnWidth: 19 },
      },
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const halfPageWidth = pageWidth / 2 - 5;

    doc.setFontSize(12);
    doc.setTextColor(90, 90, 90);
    doc.text("* Terms and conditions", 12, finalY + 8, {
      maxWidth: halfPageWidth,
    });
    doc.setTextColor(128, 128, 128);
    doc.setFontSize(9);
    doc.text("1. Goods once sold will not be taken back", 12, finalY + 13, {
      maxWidth: halfPageWidth,
    });
    doc.text("2. No Claim No Guarantee", 12, finalY + 18, {
      maxWidth: halfPageWidth,
    });
    doc.text(
      "3. Any disputes arising from this transaction will be resolved through Jurisdiction in Indore.",
      12,
      finalY + 23,
      {
        maxWidth: halfPageWidth,
      }
    );
    doc.text(
      "4. A late payment fee of 10% will be applied to overdue balances after 30 days.",
      12,
      finalY + 32,
      {
        maxWidth: halfPageWidth,
      }
    );

    const qrX = halfPageWidth + 23;

    // Add the QR image
    doc.addImage(QR, "PNG", qrX, finalY + 3, 33, 33);

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "|| Thank You Visit Again ||",
      doc.internal.pageSize.getWidth() / 2,
      finalY + 80,
      {
        align: "center",
      }
    );

    const pdfBlob = doc.output("blob");
    const formData = new FormData();
    formData.append("image", pdfBlob, invoiceId);
    formData.append("invoiceId", invoiceId);
    const response = await dispatch(uploadImage(formData))
      .then(async (response) => {
        console.log("response", response);
        const res = await dispatch(
          sendMessageOnWhatsapp({
            pdfUrl: response?.data?.filePath,
            invoiceId: invoiceId,
          })
        );
        console.log("res", res);
        if (res.status == 200) {
          Swal.fire(
            "Success!",
            "Bill sent successfully on WhatsApp!",
            "success"
          );
        } else {
          Swal.fire("Something went Wrong!", "error");
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error!",
          "An error occurred while sending the bill.",
          "error"
        );
      });
  };

  return (
    <Popconfirm
      title="Send Bill On Whatsapp?"
      onConfirm={() => handleDownload()}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
    >
      <Button type="primary" disabled={disabled}>
        <WhatsAppOutlined style={{ marginBottom: "4px" }} /> Send Bill
      </Button>
    </Popconfirm>
  );
};

export default DownloadSalePDF;