import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import BrandsCloumn from "./BrandsCloumn";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getOnBrandEcom } from "../../redux/actions/brandAction";
import { Link, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Button, Result } from "antd";

class BlogStandard extends Component {
  constructor(props) {
    super(props);
    const Token = localStorage.getItem("token");
    let IsLoggedIn = true;
    if (Token === null) {
      IsLoggedIn = false;
    }
    this.state = { IsLoggedIn };
  }
  componentDidMount() {
    this.props.getOnBrandEcom();
  }
  render() {
    if (this.state.IsLoggedIn === false) {
      return <Redirect to="/login-register" />;
    }
    const storedToken = localStorage.getItem("token");
    let decToken = "";
    if (storedToken) {
      decToken = jwt_decode(storedToken);
    }
    if (
      decToken.role !== "622a47d2a0c03827d4f07294" &&
      decToken.role !== "622a47e017844801d2c839a4"
    ) {
      return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Link to="/dagaImpex">
              <Button type="primary">Back</Button>
            </Link>
          }
        />
      );
    }
    return (
      <Fragment>
        <MetaTags>
          <title>Daga Impex | Brands</title>
          <meta
            name="description"
            content="Blog of flone react minimalist eCommerce template."
          />
        </MetaTags>
        {/* <BreadcrumbsItem to={process?.env?.PUBLIC_URL + "/"}>
          Home
        </BreadcrumbsItem>
        <BreadcrumbsItem to={process?.env?.PUBLIC_URL + "/dagaBrands"}>
          Brands
        </BreadcrumbsItem> */}
        <LayoutOne headerTop="visible">
          {/* breadcrumb */}
          <Breadcrumb />
          <div className="blog-area my-5">
            <div className="BrandsPage">
              <h2 className="font-bold ml-3 " style={{ fontWeight: 500 }}>Brands</h2>
              <div className="borderBelowHeading  mb-5"></div>
              <div className="row flex-row-reverse">
                <div className="col-12">
                  <div >
                    <div className="row">
                      {/* blog posts */}
                      {this.props?.brands?.map((brand) => {
                        if (brand.status === "ON" && brand.brandStatus === true) {
                          return <BrandsCloumn brand={brand} />;
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LayoutOne>
      </Fragment>
    );
  }
}

BlogStandard.propTypes = {
  getOnBrandEcom: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brandsData.brands,
});

export default connect(mapStateToProps, {
  getOnBrandEcom,
})(BlogStandard);