import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import HeaderOne from "../../../wrappers/header/HeaderOne";
import BoyImage from "../../../assets/images/boy.png";
import Arrow from "../../../assets/images/Group 137.png";
import ArrowLeft from "../../../assets/images/Group 116.png";
import "./CategoriesPage.css";

import {
  fetchProductsByFiltereing,
  fetchAllProductsSidebarList,
  fetchFilterProductSidebarList,
} from "../../../redux/actions/productActions";
import { useDispatch } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

const CategoriesPage = (props) => {
  const categories = props.location.state?.categories || props.categStyles;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const getProductByCategory = async (id) => {
    try {
      const filterObj = {
        category: [id],
        style: [],
        brand: [],
        color: [],
        size: [],
      };
      const response = await dispatch(fetchAllProductsSidebarList());
      console.log("response", response);

      let categories;
      if (response?.data?.categoryDetails) {
        categories = response;
      }

      const params = new URLSearchParams(location.search);
      params.set("All", true);
      params.set("limit", 1);
      params.set("category", id);
      params.delete("brand");
      params.delete("style");
      params.delete("color");
      params.delete("size");
      history.push({
        pathname: `/shop-grid-standard`,
        search: params.toString(),
        state: {
          stateData: "Category",
          selectTypeData: categories,
          loading: true,
        },
      });

      await dispatch(fetchProductsByFiltereing(filterObj));
      await dispatch(fetchFilterProductSidebarList(filterObj));
    } catch (error) {
      console.error("Error fetching products by category:", error);
    }
  };

  return (
    <>
      <HeaderOne />

        <div className="breadcrumb-area shadow-sm mb-4">
          <div className="container p-0">
            <div className="breadcrumb-content mx-2 d-flex align-items-center justify-content-start">
              <Link to="/">
                <ArrowLeftOutlined />
              </Link>
              <div className="w-100 breadCrumb-Text ml-3 text-center">
                All Categories
              </div>
            </div>
          </div>
        </div>

      <div className="category-page">
        <div className="category-grid">
          {categories?.map((category) => (
            <div key={category._id}>
              <Link
                to={{
                  pathname: process.env.PUBLIC_URL + "/style-card",
                  state: { categories: category },
                }}
              >
                <img src={BoyImage} alt={category.name} />
              </Link>

              <div className="category-name ">
                <span className="">
                  {category.name}{" "}
                </span>
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/style-card",
                    state: { categories: category },
                  }}
                >
                  {" "}
                  <img
                    style={{ width: "15px", height: "15px" }}
                    src={Arrow}
                  />{" "}
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CategoriesPage;
