import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Button, Form, Input, Table } from "antd";

const ShowLRPdf = ({ data }) => {
  const [visible, setVisible] = useState(false);
  const [docType, setDocType] = useState("");

  const showModal = () => {
    setVisible(!visible);
    if (data && data?.pdfUrl.length > 0) {
      let isPDF = data?.pdfUrl?.[0].includes(".pdf");
      if (isPDF) {
        setDocType("pdf");
      } else {
        setDocType("image");
      }
    }
  };

  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={() => showModal()}
      >
        View PDF/Image
      </Button>
      <Row>
        <Col span={24}>
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={() => setVisible(false)}
              title="View PDF"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              {docType === "image" && (
                <img
                  alt="example"
                  style={{ width: "100%", height: "100%" }}
                  src={data?.pdfUrl?.[0]}
                />
              )}
              {docType === "pdf" && (
                <iframe
                  src={data?.pdfUrl?.[0]}
                  width="450px"
                  height="400px"
                  frameBorder="0"
                  scrolling="yes"
                />
              )}
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ShowLRPdf;
