/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
04-08-2023        Mohit                    View Bill for sales list



*/
/* /////////////////////////////////////// */


import { FileDoneOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { Modal, Table, Button, Typography, Col, Row, Tooltip, message } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { createSales, getSaleUsingInvoiceId } from "../../../redux/actions/saleAction"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Spin } from 'antd';

// import "../../../assets/css/generateBill.css";

class ViewGenerateBillNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      // billDate: "",
      // saleDate: moment(new Date()),
    };
  }

  handleModalCancel = () => {
    // window.location.reload()
    this.props.getSaleUsingInvoiceId("reset");
    this.setState({ visible: false });
  };

  showModal = () => {
    this.getDetails()
    this.setState({
      visible: true,
    });
  };

  getDetails = async () => {
    let invoiceId = this.props.text?.invoiceId ? this.props.text?.invoiceId : this.props.invoiceId
    await this.props.getSaleUsingInvoiceId(invoiceId);
  }

  handlePdfGeneration = () => {
    const element = document.getElementById("pdf-content");
    if (!element) {
      return;
    }

    element.style.width = "400px";
    element.style.margin = "0 auto";

    const momentDate = moment(this.props?.record?.date);
    const formattedDate = momentDate.format("DD/MM/YYYY");
    const formattedTime = momentDate.format("HH:mm:ss");

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `${this.props?.salesOrders?.[0]?.invoiceId}-${this.props?.text?.userId?.name}-${formattedDate}-${formattedTime}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // css: customPdfStyles
    };


    html2pdf().from(element).set(opt).save().then(() => {
      element.style.width = null;
      element.style.margin = null;
    });
  };

  handleOk = () => this.setState({ visible: false });
  handleModalCancel = () => this.setState({ visible: false });

  render() {
    const { visible } = this.state;
    const { salesOrders, text } = this.props
    const purchaseData = salesOrders && salesOrders.length > 0 && salesOrders?.map((obj) => {
      return { saleQty: obj.quantity, ...obj.details };
    });

    let track = {};
    let brands = [];
    let e = 0;
    let f = purchaseData ? purchaseData.length : 0;
    // let f = purchaseData.length;
    while (e < f) {
      if (purchaseData[e].brand in track) {
        track[purchaseData[e].brand].push(e);
      } else {
        track[purchaseData[e].brand] = [e];
        brands.push(purchaseData[e].brand);
      }
      ++e;
    }
    let data = brands?.sort().map((purchaseName) => {

      let indexes = track[purchaseName];
      let insideData = [];
      let ee = 0;
      let ff = indexes.length;
      while (ee < ff) {
        let purchase = purchaseData[indexes[ee]];
        insideData.push(
          <li>
            <div className="page-break">
              <Row style={{ width: '100%' }} className="mt-n2">
                <Col className="mr-1" style={{ width: "114px" }}>
                  <p style={{ fontWeight: 800 }}>
                    {indexes[ee] + 1} || {purchase.designNumber}
                  </p>
                </Col>
                <Col className="mr-1" style={{ width: "80px" }}>
                  <p style={{ fontWeight: 800 }}>{purchase.size}</p>
                </Col>
                <Col className="mr-2" style={{ width: "50px" }}>
                  <p className="mb-n2" style={{ fontWeight: 800 }}>
                    {purchase.saleQty}
                  </p>
                </Col>
                <Col className="mr-1" style={{ width: "50px" }}>
                  <p className="mb-n2" style={{ fontWeight: 800 }}>
                    {purchase.wsp}
                  </p>
                </Col>
                {/*<Col className="mr-1" style={{ width: "70px" }}>
                <p className="mb-n2" style={{ fontWeight: 800 }}>
                  {purchase.color}
                </p>
        </Col>*/}
                <Col className="mr-1" style={{ width: "60px" }}>
                  <p className="mb-n2" style={{ fontWeight: 800 }}>
                    {Math.round(purchase.saleQty * purchase.wsp)}
                  </p>
                </Col>
              </Row>
              <hr />
            </div>
          </li>
        );
        ++ee;
      }
      return (
        <li>
          <h5 >
            <u>
              <strong style={{ fontSize: "18px" }}>{purchaseName}</strong>
            </u>
          </h5>
          <ul>{insideData}</ul>
        </li>
      );
    });
    return (
      <>
        <div className="generate-bill">
          {
            window.location.pathname == "/dagaImpex/salesList" ? <>
              {
                <button
                  className="btn"
                  type="button"
                  data-original-title=""
                  onClick={this.showModal}
                  disabled={this.props.record?.remark === "Bill Deleted." ? true : false}
                >
                  <Tooltip title="View Bill">
                    <FileDoneOutlined />
                  </Tooltip>
                </button>
              }            </> : <>
              {this.props.buttonType === "Add" ? (<Button
                className='generate-button'
                size='large'
                onClick={this.showModal}
                style={{ color: "#fff", background: "#fa5d25", }}
                block
                disabled={this.props.record?.remark === "Bill Deleted." ? true : false}
              >
                Generate Bill
              </Button>) : (
                <Button
                  type="primary"
                  onClick={this.showModal}
                  className="close-modal"
                  block

                  disabled={this.props.record?.remark === "Bill Deleted." ? true : false}
                >
                  Generate Bill
                </Button>
              )
              }
            </>
          }

          <ReactToPrint
            content={() => this.componentRef}
          >
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Generate Bill"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
              width={1500}
            >
              <div
                className="card generate-bill-card"
                style={{ overflow: "scroll" }}
                id="pdf-content"
              >
                <div
                  className="card-body generate-bill-font"
                  style={{ padding: "0.6rem" }}
                  ref={(el) => (this.componentRef = el)}
                >
                  {
                    console.log("SalesOrder----------------------------------------->", salesOrders)
                  }
                  {salesOrders && salesOrders.length > 0 && data && data.length > 0 ? (
                    <div className="generate-bill-font-test7">
                      <Row>
                        <Col span={8} style={{ width: '100%', textAlign: "center" }}>
                          <div>
                            <strong>
                              <p
                                className="mb-n2 font-weight-bold"
                                style={{ width: "380px" }}
                              >
                                || Jai Shree Krishna ||
                              </p>
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: "center" }}>
                          <h4 className="mb-n1 mt-2" style={{ width: "380px" }}>
                            Estimate
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="mb-n1" style={{ width: "350px" }}><strong>Invoice Id:</strong> {salesOrders[0].invoiceId}</p>
                          <p style={{ width: "350px" }}>
                            <strong>Customer:</strong>{" "}
                            {salesOrders[0]?.userId?.name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{ width: "350px" }}>
                            <strong>Agent:</strong>{" "}
                            {salesOrders[0]?.userId?.agentId?.name}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mr-5">
                          <p className="mb-n2" style={{ width: "350px" }}>
                            <strong>Date:</strong>{" "}
                            {moment(salesOrders[0]?.salesBilldetails?.created_at).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <strong>
                            <p className="mb-n2">Note: {salesOrders[0]?.salesBilldetails?.remark}</p>
                          </strong>
                        </Col>
                      </Row>
                      <div style={{ width: "380px", display: "block", }}>
                        <Row className="mt-3">
                          <Col className="mr-1" style={{ width: "114px" }}><strong> <p className="font-weight-bold">Particulars</p> </strong></Col>
                          <Col className="mr-1" style={{ width: "80px" }}><strong> <p className="font-weight-bold">Size</p> </strong></Col>
                          <Col className="mr-1" style={{ width: "50px" }}><strong> <p className="font-weight-bold">Qty</p> </strong></Col>
                          <Col className="mr-1" style={{ width: "50px" }}><strong> <p className="font-weight-bold">Rate</p> </strong></Col>
                          <Col className="mr-1" style={{ width: "60px" }}><strong> <p className="font-weight-bold">Amt</p> </strong></Col>
                        </Row>
                        <hr />
                        <ul>{data}</ul>
                        <hr />

                        <Row className="">
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Total Qty:</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "center", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.totalQuantity}
                            </p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.totalAmount}
                            </p>
                          </Col>
                        </Row>

                        {/* Row for GR Amount */}
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>GR Amount</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "120px" }} />
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {Number(salesOrders[0]?.salesBilldetails?.grAmountUsed).toFixed()}
                            </p>
                          </Col>
                        </Row>

                        {/* Row for Discount */}
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Discount</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "center", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.discount}%
                            </p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {(
                                (Number(salesOrders[0]?.salesBilldetails?.totalAmount) -
                                  Number(salesOrders[0]?.salesBilldetails?.grAmountUsed)) *
                                salesOrders[0]?.salesBilldetails?.discount /
                                100
                              ).toFixed()}
                            </p>
                          </Col>
                        </Row>

                        {/* Row for VAT/CST */}
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>VAT/CST</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "center", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.tax}%
                            </p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {Number(
                                (
                                  (Number(salesOrders[0]?.salesBilldetails?.totalAmount) -
                                    Number(salesOrders[0]?.salesBilldetails?.grAmountUsed)) -
                                  ((Number(salesOrders[0]?.salesBilldetails?.totalAmount) -
                                    Number(salesOrders[0]?.salesBilldetails?.grAmountUsed)) *
                                    salesOrders[0]?.salesBilldetails?.discount /
                                    100)
                                ) *
                                salesOrders[0]?.salesBilldetails?.tax /
                                100
                              ).toFixed()}
                            </p>
                          </Col>
                        </Row>

                        {/* Row for P.&F. */}
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>P. & F.</p>
                          </Col>
                          <Col style={{ textAlign: "center", width: "120px" }} />
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.pAndf}
                            </p>
                          </Col>
                        </Row>

                        {/* Row for Freight */}
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Freight</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "120px" }} />
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>0.00</p>
                          </Col>
                        </Row>

                        {/* Row for Labour */}
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Labour</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "120px" }} />
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.labour}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mr-2" style={{ width: "130px", textAlign: "left" }}>
                            <p className="text-justify">Net Amount:</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "110px" }} />
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {salesOrders[0]?.salesBilldetails?.netAmount}
                            </p>
                          </Col>
                        </Row>
                        <div className="">
                          {/* Row for E.D.E. and Authorised Signatory */}
                          <Row>
                            <Col style={{ width: "175px", textAlign: "left" }}>
                              <p className="mb-n2">E.D.E.</p>
                            </Col>
                            <Col style={{ textAlign: "end", width: "175px" }}>
                              <p className="mb-n2">Authorised Signatory</p>
                            </Col>
                          </Row>

                          {/* Terms & Conditions */}
                          <p className="mb-n2">Terms & Cond.</p>
                          <p className="mb-n2">1. Goods once sold will not be taken back</p>
                          <p>2. No Claim No Guarantee</p>

                          {/* Thank You Note */}
                          <Row>
                            <Col span={8} style={{ textAlign: "center" }}>
                              <p className="font-weight-bold text-center" style={{ width: "350px" }}>
                                || Thank You Visit Again ||
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      <Spin />
                    </div>
                  )}
                </div>
              </div>
              <Row span={24} className="justify-content-center mt-4 gap-3">
                <Col>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        type="primary"
                        onClick={() => handlePrint()}
                      >
                        Print Bill
                      </Button>
                    )}
                  </PrintContextConsumer>
                </Col>
                <Col >
                  <Button
                    type="primary"
                    className="ml-2"
                    onClick={this.handlePdfGeneration}
                  >
                    Download PDF
                  </Button>
                </Col>
              </Row>
            </Modal>
          </ReactToPrint>

        </div>
      </>
    );
  }
}


ViewGenerateBillNew.propTypes = {
  createSales: PropTypes.func.isRequired,
  getSaleUsingInvoiceId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: state.saleData.sale.invoiceId,
  salesOrders: state.saleData.salesOrders,
});

export default connect(mapStateToProps, {
  createSales, getSaleUsingInvoiceId
})(ViewGenerateBillNew);