import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { ArrowLeftOutlined } from "@ant-design/icons";

const Breadcrumb = () => {
  return (
    <div className="breadcrumb-area shadow-sm">
      <div className="p-0">
        <div className="breadcrumb-content mx-2 d-flex align-items-center">
          <Link to="">
            <ArrowLeftOutlined className="text-white" />
          </Link>
          <div className="text-center w-100 breadCrumb-Text"
            style={{
              fontWeight: 500
            }}
          >
            SINCE 1971
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;