import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Input, Select, message } from 'antd';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFloors } from '../../../../redux/actions/floorAction';
import { getLocations } from '../../../../redux/actions/locationAction';
import { getRackById, getRacks, updateRack } from '../../../../redux/actions/rackManagementAction';
import { getSections } from '../../../../redux/actions/sectionAction';

function UpdateRack({ id }) {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const [location, setLocation] = useState()
    const [floor, setFloor] = useState()
    const [section, setSection] = useState()
    const [rack, setRack] = useState()
    const [columns, setColumns] = useState([{ colId: Date.now(), column: '', capacity: '' }]);

    const locationList = useSelector((state) => state.locationData?.locations);
    const floorList = useSelector((state) => state.floorData?.floors);
    const sectionList = useSelector((state) => state.sectionData?.sections);

    const rackByIdList = useSelector((e) => e.rackData?.racksByID?.data)

    console.log("rackByIdList------------------>", rackByIdList)
    useEffect(() => {
        if (rackByIdList) {
            const selectedRack = rackByIdList[0];
            setLocation(selectedRack?.location);
            setFloor(selectedRack?.floor);
            setSection(selectedRack?.section);
            setRack(selectedRack?.rack);
            setColumns(selectedRack?.columns);

            form.setFieldsValue({
                location: selectedRack?.location,
                floor: selectedRack?.floor,
                section: selectedRack?.section,
                rack: selectedRack?.rack,
                columns: selectedRack?.columns,
            });
        }
    }, [rackByIdList, form]);


    const showModal = () => {
        setIsModalOpen(true);
        if (id) {
            dispatch(getRackById({ id: id }))
                .finally(() => {
                    if (!locationList?.length) dispatch(getLocations());
                    if (!floorList?.length) dispatch(getFloors());
                    if (!sectionList?.length) dispatch(getSections());
                })
        }
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                const invalidColumns = columns.filter(
                    (column) => column.capacity < column?.availability
                );

                if (invalidColumns.length > 0) {
                    message.warning({
                        title: 'Validation Error',
                        content: `Capacity cannot be smaller than availability for the following columns : ${invalidColumns
                            .map((col) => col.column || 'Unnamed Column')
                            .join(', ')}`,
                    });
                    return;
                }

                const columnsCount = columns?.length;
                const updatedColumns = columns.map(({ availability, name, ...rest }) => rest);
                const finalValues = {
                    ...values,
                    columns: updatedColumns,
                    columnsCount,
                    id: id,
                };

                console.log('Form Values ------------------>', finalValues);

                dispatch(updateRack(finalValues)).finally(() => {
                    setTimeout(() => {
                        dispatch(getRacks());
                        form.resetFields();
                        setColumns([{}]);
                        setIsModalOpen(false);
                    }, 1000);
                });
            })
            .catch((info) => {
                console.log('Validation Failed:', info);
            });
    };


    const handleCancel = () => {
        form.resetFields();
        setColumns([{}]);
        setIsModalOpen(false);
    };

    const addColumn = () => {
        setColumns([...columns, { colId: Date.now(), name: '', capacity: '' }]);
    };

    const removeColumn = (id) => {
        if (columns.length > 1) {
            setColumns(columns.filter((column) => (column?._id || column?.colId) !== id));
        }
    };

    const updateColumn = (id, field, value) => {
        setColumns(
            columns.map((column) =>
                (column._id || column?.colId) === id
                    ? { ...column, [field]: field === "capacity" ? Number(value) : value }
                    : column
            )
        );
    };

    return (
        <>
            <Button className='border-0 bg-transparent' onClick={showModal}>
                <EditOutlined />
            </Button>
            <Modal
                title="Edit Rack"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div className='d-flex justify-content-center mb-4' key="footer">
                        <Button key="cancel" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button key="submit" type="primary" onClick={handleOk}>
                            Submit
                        </Button>
                    </div>
                ]}
            >
                <Form form={form} layout="vertical" name="addRackForm"
                    initialValues={{
                        location: location,
                        floor: floor,
                        section: section,
                        rack: rack,
                        columns: columns
                    }}
                >
                    <Form.Item
                        name="location"
                        label="Location"
                        rules={[{ required: true, message: 'Please select a location!' }]}
                    >
                        <Select placeholder="Select a location" allowClear>
                            {locationList?.map((location) => (
                                <Select.Option key={location._id} value={location._id}>
                                    {location.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="floor"
                        label="Floor"
                        rules={[{ required: true, message: 'Please select a floor!' }]}
                    >
                        <Select placeholder="Select a floor" allowClear>
                            {floorList?.map((floor) => (
                                <Select.Option key={floor._id} value={floor._id}>
                                    {floor.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="section"
                        label="Section"
                        rules={[{ required: true, message: 'Please select a section!' }]}
                    >
                        <Select placeholder="Select a section" allowClear>
                            {sectionList?.map((section) => (
                                <Select.Option key={section._id} value={section._id}>
                                    {section.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="rack"
                        label="Rack Name"
                        rules={[{ required: true, message: 'Please enter a rack name!' }]}
                    >
                        <Input placeholder="Enter rack name" />
                    </Form.Item>

                    <Form.Item label="Column Details">
                        {columns.map((column, index) => (
                            <div key={column._id || column?.colId} style={{ display: 'flex', gap: '16px', marginBottom: '8px', alignItems: 'center' }}>
                                <Input
                                    placeholder="Enter column name"
                                    value={column.column}
                                    onChange={(e) => updateColumn((column._id || column?.colId), 'column', e.target.value)}
                                    style={{ flex: 2 }}
                                />
                                {
                                    console.log("columns?.availability------------->", column?.availability)
                                }
                                <Input
                                    placeholder="Capacity"
                                    type="number"
                                    min={column?.availability}
                                    value={column.capacity}
                                    onChange={(e) => updateColumn((column._id || column?.colId), 'capacity', e.target.value)}
                                    style={{ flex: 1 }}
                                />
                                <Button type="link" danger onClick={() => removeColumn(column?.colId || column._id)} disabled={columns.length === 1}>
                                    Delete
                                </Button>
                            </div>
                        ))}
                        <Button type="dashed" onClick={addColumn} style={{ width: '100%' }}>
                            Add Column
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default UpdateRack;
