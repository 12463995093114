import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFullCart } from "../../redux/actions/cartActions";
import {
  getStyleByCategory,
  getProductsByStyle,
} from "../../redux/actions/styleAction";
import { getProductByCategory } from "../../redux/actions/categoryAction";
import { Link as MyLink } from "react-scroll";
import { Tooltip } from "antd";
import { useLocation, useHistory } from 'react-router-dom';
import { fetchProductsByFiltereing, fetchFilterProductSidebarList, fetchAllProductsSidebarList } from "../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";

const NavMenu = ({
  categStyles,
  getStyleByCategory,
  getProductByCategory,
  getProductsByStyle,
  getFullCart,
}) => {
  const locationHook = useLocation()
  const history = useHistory()
  const dispatch = useDispatch();

  useEffect(() => {
    getFullCart();
    getStyleByCategory();
  }, [getFullCart, getStyleByCategory]);

  const handleProductByCategory = async (id) => {
    let obj = {
      category: [id],
      style: [],
      brand: [],
      color: [],
      size: []
    }

    const params = new URLSearchParams(locationHook.search);
    params.set('All', true);
    params.set('limit', 1);
    params.set('category', id);
    // Remove all other parameters
    params.delete('brand');
    params.delete('style');
    params.delete('color');
    params.delete('size');
    history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        // stateData: "Category",
        // selectTypeData : categories,
        loading: true
      }
    });
    let response = await dispatch(fetchAllProductsSidebarList())
    console.log("response", response);
    let categories;
    if (response && response?.data && response?.data?.categoryDetails) {
      categories = response;
    }
    console.log("categories", categories);
    history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Category",
        selectTypeData: categories,
        loading: true
      }
    });

    await dispatch(fetchProductsByFiltereing(obj))
    await dispatch(fetchFilterProductSidebarList(obj))
    history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Category",
        selectTypeData: categories,
        loading: false
      }
    });
  };

  const handleProductsByStyle = async (id, categoryId) => {

    let obj = {
      category: [categoryId],
      style: [id],
      brand: [],
      color: [],
      size: []
    }

    let response = await dispatch(fetchFilterProductSidebarList({ category: obj.category }))

    console.log("response", response);
    let styles;
    if (response && response?.data && response?.data?.data?.styleDetails) {
      styles = response?.data;
    }

    const params = new URLSearchParams(locationHook.search);
    params.set('All', true);
    params.set('limit', 1);
    params.set('category', categoryId);
    params.set('style', id);
    // Remove all other parameters
    params.delete('brand');

    params.delete('color');
    params.delete('size');
    history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Style",
        selectTypeData: styles,
        loading: true
      }
    });
    await dispatch(fetchProductsByFiltereing(obj))
    await dispatch(fetchFilterProductSidebarList(obj))
    history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Style",
        selectTypeData: styles,
        loading: false
      }
    });
  };

  return (
    <div className="main-menu">
      <nav>
        <ul className="navLinks">
          {/* <li>
            <Link to="/">{"Home"}</Link>
          </li> */}
          {categStyles.map((category) => {
            if (category.status === "ON")
              return (
                <li key={category._id}>
                  <Link
                    to={process?.env?.PUBLIC_URL + "/shop-grid-standard"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleProductByCategory(category._id);
                    }}
                  >
                    {category.name}
                    {/* <i className="fa fa-angle-down" /> */}
                  </Link>
                  <ul className="mega-menu mega-menu-padding menu-style-fixedHeight">
                    <h5 className="mb-3 text-primary ml-3 border-bottom border-grey">{category.name}</h5>
                    {category.styles?.map((style) => (
                      <li key={style._id}>
                        <ul>
                          <li className="mega-menu-title">
                            <Link
                              to={process?.env?.PUBLIC_URL + "/shop-grid-standard"}
                              onClick={(e) => {
                                e.preventDefault();
                                handleProductsByStyle(style._id, category._id);
                              }}
                            >
                              <Tooltip title={style.name}>
                                {style.name}
                              </Tooltip>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              );
          })}
          <li>
            <Link to="/Top-Brands" spy={true} smooth={true}>
              {"BRANDS"}
            </Link>
          </li>
          {/* <li>
            <MyLink to="contact" spy={true} smooth={true}>
              {"Contact Us"}
            </MyLink>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  getStyleByCategory: PropTypes.func.isRequired,
  getProductByCategory: PropTypes.func.isRequired,
  getProductsByStyle: PropTypes.func.isRequired,
  getFullCart: PropTypes.func.isRequired,
  categStyles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  categStyles: state.styleData.categorystyles,
});

export default connect(mapStateToProps, {
  getStyleByCategory,
  getProductByCategory,
  getProductsByStyle,
  getFullCart,
})(NavMenu);