import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import { SearchOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getAlldefectedProductBill,
  getSalesReturnDefectedUsingsaleReturnId,
  getAlldefectedProductBillBydate,
} from "../../../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ShowBilldefectedProduct from "./showBilldefectedProduct";
import { withRouter } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import ViewDefectedBill from "./ViewDefectedBill";
import Search from "../../../../../util/Search";
import DateWiseSearch from "../../../../../util/DateWiseSearch";
const { Column } = Table;
const searchOptions = ["Barcode", "SaleReturnId", "CustomerName"];
class DefectedProductBillList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
  }

  componentDidMount() {
    if (this.props.history.location.state) {
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      if (localStartDate && localEnddate) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        if (today == localEnddate) {
          let reportObj = {
            startDate: localStartDate,
            endDate: localEnddate + " " + moment().format("HH:mm:ss"),
          };
          this.props.getAlldefectedProductBillBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        } else {
          const dateMoment = moment(localEnddate, "YYYY-MM-DD");
          const endTime = dateMoment.endOf("day").format("HH:mm:ss");
          const endDateWithEndTime = `${localEnddate} ${endTime}`;
          let reportObj = {
            startDate: localStartDate,
            endDate: endDateWithEndTime,
          };
          this.props.getAlldefectedProductBillBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        }
      } else {
        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getAlldefectedProductBillBydate(reportObj);
      }
    } else {
      localStorage.setItem(
        "startDate",
        moment(new Date()).format("YYYY-MM-DD")
      );
      localStorage.setItem("endDate", moment(new Date()).format("YYYY-MM-DD"));
      this.setState({
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getAlldefectedProductBillBydate(reportObj);
    }

    // let startDate = moment(new Date()).format("YYYY-MM-DD")
    // let endDate = moment(new Date()).format("YYYY-MM-DD")
    // let reportObj = {
    //   startDate: startDate,
    //   endDate: endDate + " " + moment().format("HH:mm:ss"),
    // };
    // this.props.getAlldefectedProductBillBydate(reportObj)
    // this.props.getAlldefectedProductBill(1);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  editSaleReturnDefected = async (text) => {
    await this.props.getSalesReturnDefectedUsingsaleReturnId(text.saleReturnId);
    this.props.history.push({
      pathname: "/dagaImpex/updateSalesReturn",
      state: {
        id: text._id,
        saleReturnDefectedObj: [text, this.props.salesReturnDefectedOrders],
        type: "Defective",
      },
      from: "/dagaImpex/defectedProductBill",
      // editSaleReturn: true,
    });
  };

  listData = () => {
    const { alldefectedproductbillBydate, searchData } = this.props;
    if (this.state.searchValue) {
      const sortedArray = searchData?.data?.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      return sortedArray;
    } else {
      return alldefectedproductbillBydate.data;
    }
  };

  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };

  render() {
    const { alldefectedproductbillBydate } = this.props;
    const Id = localStorage.getItem("roleName");

    return (
      <div>
        {this.props.progressBarStatus && <TopBarProgress />}
        {/* ==============Search Bar and Date Filter================= */}
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">Defected Product Bill</div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Search
            title="Defective Return Bill"
            placeholder="Customer Name, Sale Return Id, Barcode"
            searchopt={searchOptions}
            changeFunction={this.onChange}
            cancelState={this.cancelState}
            location={this.props.location}
          />
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <DateWiseSearch
              propsHistory={this.props.history}
              keyId={"Defected Product Bill"}
            />
          </Col>
        </Row>
        {/* ======================================== */}

        <div className="card final-saleReturns-list">
          <div className="card-body">
            <Table
              dataSource={this.listData()}
              // dataSource={alldefectedproductbillBydate.data ? alldefectedproductbillBydate.data :  this.props.AlldefectedProductBill && this.props.AlldefectedProductBill.data ? this.props.AlldefectedProductBill.data:[] }
              bordered="true"
              size="middle"
              pagination={{
                pageSize: 10,
                total:
                  this.props.AlldefectedProductBill &&
                  this.props.AlldefectedProductBill.count
                    ? this.props.AlldefectedProductBill.count
                    : "",
                onChange: (page) => {
                  this.props.getAlldefectedProductBill(page);
                },
              }}
              scroll={{ y: 440 }}
              summary={() => (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      className="grand-Total-summary"
                      index={1}
                      colSpan={2}
                    >
                      Grand Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {alldefectedproductbillBydate &&
                      alldefectedproductbillBydate.count
                        ? alldefectedproductbillBydate.count
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {" "}
                      {alldefectedproductbillBydate &&
                      alldefectedproductbillBydate.allQuantity
                        ? alldefectedproductbillBydate.allQuantity
                        : ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {alldefectedproductbillBydate &&
                      alldefectedproductbillBydate.totalNetAmount
                        ? alldefectedproductbillBydate.totalNetAmount
                        : ""}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Name"
                dataIndex="userId"
                key="name"
                width={110}
                //render={ (userId) => userId.map(userId => userId.name).join()}
                // render={ item => Object.keys(item)[0]}
                render={(value, item, index) => `${item.userId.name}`}
                //{...this.getColumnSearchProps("name")}
              />
              <Column
                title="Sale Return Id"
                dataIndex="saleReturnId"
                key="saleReturnId"
                width={150}
                {...this.getColumnSearchProps("saleReturnId")}
              />

              <Column
                title="Created Date"
                dataIndex="date"
                key="created_at"
                width={130}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />

              <Column
                title="Total Qnty"
                dataIndex="totalQuantity"
                key="totalQuantity"
                width={90}
              />
              <Column
                title="Total Amount"
                dataIndex="totalAmount"
                key="totalAmount"
                width={130}
              />
              <Column
                title="Customer Remark"
                dataIndex="remark"
                key="remark"
                width={160}
              />
              <Column
                title="Store Remark"
                dataIndex="remark2"
                key="remark2"
                width={140}
              />
              <Column
                title="Action"
                key="action"
                width={120}
                fixed="right"
                render={(text, record) => {
                  if (Id === "DME" || Id === "CRM" ) {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          align="center"
                        >
                          <ShowBilldefectedProduct
                            saleReturnId={record.saleReturnId}
                            data={record}
                          />
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Popconfirm
                            title="Are you sure to Edit this bill?"
                            onConfirm={() => this.editSaleReturnDefected(text)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <Tooltip title=" Edit Bill">
                                <EditOutlined />
                              </Tooltip>
                            </div>
                          </Popconfirm>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          {/* <ShowBilldefectedProduct saleReturnId={record.saleReturnId} data={record} /> */}
                          <ViewDefectedBill
                            saleReturnId={record.saleReturnId}
                          />
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <ShowBilldefectedProduct
                            saleReturnId={record.saleReturnId}
                            data={record}
                          />
                        </Col>
                      </Row>
                    );
                  }
                }}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

DefectedProductBillList.propTypes = {
  getAlldefectedProductBill: PropTypes.func.isRequired,
  getSalesReturnDefectedUsingsaleReturnId: PropTypes.func.isRequired,
  getAlldefectedProductBillBydate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    AlldefectedProductBill: state.saleData.AlldefectedProductBill,
    salesReturnDefectedOrders: state.saleData.salesReturnDefectedOrders,
    alldefectedproductbillBydate: state.saleData.alldefectedproductbillBydate,
    searchData: state.saleData.defectiveReturnSearchByValue,
    progressBarStatus: state.progressBarReducer.showProgressBar,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAlldefectedProductBill,
    getSalesReturnDefectedUsingsaleReturnId,
    getAlldefectedProductBillBydate,
  })(DefectedProductBillList)
);
